import { useState, useEffect } from "react";
import {
  BrowserRouter as Router,
  Routes,
  Route,
  Navigate,
  useNavigate,
  useLocation,
} from "react-router-dom";
import "./index.scss"; // This imports Bootstrap CSS
import "./App.scss";
import "./theming/theme.scss";
import Header from "./components/sections/Header/Header";
import Login from "./views/Login/Login";
import { LoginContext } from "./contexts/LoginContext";
import Container from "react-bootstrap/Container";
import { AlertMessage } from "./components/atoms/AlertMessage/AlertMessage";
import ApiMessage from "./components/sections/ApiMessage/ApiMessage";

import { endpoints } from "./api/endpoints";
import { useSelector } from "react-redux";
import CustomerOrders from "./apps/CustomerOrders";
import CustomerCredits from "./apps/CustomerCredits";
import PurchaseOrders from "./apps/Purchasing";
import "./App.scss";
import Home from "./apps/Home";
import MyAccount from "./views/MyAccount/MyAccount";
import AccountsReceivable from "./apps/AccountsReceivable";

function App() {
  const ORDER_ROUTE = endpoints.ORDER_ENTRY;
  const CREDIT_ROUTE = endpoints.CREDIT_ENTRY;
  const [isAuthenticated, setAuthenticated] = useState(false);
  const [username, setUsername] = useState("");
  const [groups, setGroups] = useState([]);
  const [loading, setLoading] = useState(false);

  const ORDER_GROUP = "CSA-ORDERS";
  const CREDIT_GROUP = "CSA-CREDIT";
  const PURCHASE_GROUP = "CSA-PURCHASE";
  const ACCOUNTS_RECEIVABLE_GROUP = "CSA-ACCOUNTS-RECEIVABLE";
  const handleShowOrders = () => {
    window.location.href = endpoints.ORDER_ENTRY;
  };

  const handleShowCredits = () => {
    window.location.href = endpoints.CREDIT_ENTRY;
  };

  const handleShowPurchasing = () => {
    window.location.href = endpoints.PURCHASE_ENTRY;
  };

  const handleShowAccountsReceivable = () => {
    window.location.href = endpoints.ACCOUNTS_RECEIVABLE_ENTRY;
  };

  useEffect(() => {
    if (groups.length === 0) {
      setLoading(true);
    } else {
      setLoading(false);
    }

    if (groups.length === 1) {
      if (
        groups[0] == ORDER_GROUP &&
        !window.location.href.includes(endpoints.ORDER_ENTRY)
      ) {
        window.location.href = endpoints.ORDER_ENTRY;
      } else if (
        groups[0] === CREDIT_GROUP &&
        !window.location.href.includes(endpoints.CREDIT_ENTRY)
      ) {
        window.location.href = endpoints.CREDIT_ENTRY;
      } else if (
        groups[0] === PURCHASE_GROUP &&
        !window.location.href.includes(endpoints.PURCHASE_ENTRY)
      ) {
        window.location.href = endpoints.PURCHASE_ENTRY;
      } else if (
        groups[0] === ACCOUNTS_RECEIVABLE_GROUP &&
        !window.location.href.includes(endpoints.ACCOUNTS_RECEIVABLE_ENTRY)
      ) {
        window.location.href = endpoints.ACCOUNTS_RECEIVABLE_ENTRY;
      }
    }
  }, [groups]);

  return (
    <div className="App">
      <AlertMessage>
        <LoginContext.Provider
          value={{
            isAuthenticated,
            setAuthenticated,
            username,
            setUsername,
            groups,
            setGroups,
          }}
        >
          {isAuthenticated ? (
            <>
              <Header
                groups={groups}
                orderGroup={ORDER_GROUP}
                creditGroup={CREDIT_GROUP}
                accountsReceivableGroup={ACCOUNTS_RECEIVABLE_GROUP}
              />
              <ApiMessage />
              <Routes>
                <Route
                  path={"/*"}
                  element={
                    <Home
                      loading={loading}
                      groups={groups}
                      orderGroup={ORDER_GROUP}
                      creditGroup={CREDIT_GROUP}
                      purchaseGroup={PURCHASE_GROUP}
                      accountsReceivableGroup={ACCOUNTS_RECEIVABLE_GROUP}
                      handleShowCredits={() => handleShowCredits()}
                      handleShowOrders={() => handleShowOrders()}
                      handleShowPurchasing={() => handleShowPurchasing()}
                      handleShowAccountsReceivable={() =>
                        handleShowAccountsReceivable()
                      }
                    />
                  }
                />
                <Route
                  path={ORDER_ROUTE + "/*"}
                  element={
                    <CustomerOrders groups={groups} orderGroup={ORDER_GROUP} />
                  }
                />
                <Route
                  path={CREDIT_ROUTE + "/*"}
                  element={
                    <CustomerCredits
                      groups={groups}
                      creditGroup={CREDIT_GROUP}
                    />
                  }
                />

                <Route
                  path={endpoints.PURCHASE_ENTRY + "/*"}
                  element={
                    <PurchaseOrders
                      groups={groups}
                      purchaseGroup={PURCHASE_GROUP}
                    />
                  }
                />

                <Route
                  path={endpoints.ACCOUNTS_RECEIVABLE_ENTRY + "/*"}
                  element={
                    <AccountsReceivable
                      groups={groups}
                      accountsReceivableGroup={ACCOUNTS_RECEIVABLE_GROUP}
                    />
                  }
                />

                <Route path={"/my-account"} element={<MyAccount />} />
              </Routes>
            </>
          ) : (
            <Container fluid={"md"}>
              <Login
                groups={[
                  ORDER_GROUP,
                  CREDIT_GROUP,
                  PURCHASE_GROUP,
                  ACCOUNTS_RECEIVABLE_GROUP,
                ]}
              />
            </Container>
          )}
        </LoginContext.Provider>
      </AlertMessage>
    </div>
  );
}

export default App;
