import { memo } from "react";
import { Modal, Button } from "react-bootstrap";

function WarningDeleteClaimPopup({
  show,
  handleCancelEvent,
  handleConfirmEvent,
}) {
  return (
    <Modal show={show} centered aria-labelledby="contained-modal-title-vcenter">
      <Modal.Header>
        <div>
          <b>Delete Claim</b>
        </div>
      </Modal.Header>
      <Modal.Body>
        This will delete this claim. <b>You will NOT be able to undo this!</b>
        <br />
        <br /> Are you sure you want to continue?
      </Modal.Body>
      <Modal.Footer>
        <Button variant={"danger"} onClick={handleCancelEvent}>
          Cancel
        </Button>
        <Button variant={"primary"} onClick={handleConfirmEvent}>
          Continue
        </Button>
      </Modal.Footer>
    </Modal>
  );
}

export default memo(WarningDeleteClaimPopup);
