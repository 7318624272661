import { memo, useState } from "react";
import { Modal, Button, Row, Col } from "react-bootstrap";
import "./SubHeading.scss";
import { TextField } from "@mui/material";
import { AiOutlineVerticalAlignMiddle } from "react-icons/ai";

function PrintItemPopup({
  type,
  handleRadioClick,
  handleCancelEvent,
  handleConfirmEvent,
  handleSendInvoice,
  hasInvoice,
  show,
}) {
  const [showConfirmInvoice, setShowConfirmInvoice] = useState(false);
  const [invoiceRecipient, setInvoiceRecipient] = useState(false);

  const content = ["Email", "Email and Print", "Print"];
  return (
    <Modal show={show} centered aria-labelledby="contained-modal-title-vcenter">
      <Modal.Header>
        <div>Print or Send Invoice for {type}</div>
        {hasInvoice && (
          <div>
            <Button
              variant={"primary"}
              onClick={() => setShowConfirmInvoice(!showConfirmInvoice)}
            >
              {showConfirmInvoice ? "Back" : "Send Invoice"}
            </Button>
          </div>
        )}
      </Modal.Header>

      <Modal.Footer></Modal.Footer>
      <Modal.Body>
        {!showConfirmInvoice && (
          <table className="flex-print-order">
            {content.map((item, index) => (
              <tr key={index}>
                <td>
                  <input
                    type="radio"
                    name="prints"
                    id={item}
                    onChange={handleRadioClick}
                  ></input>
                </td>
                <td> {item}</td>
              </tr>
            ))}
          </table>
        )}
        {showConfirmInvoice && (
          <Row>
            <Col className="mt-4" style={{}}>
              <b>Send invoice to:</b>
            </Col>
            <Col>
              <TextField
                className="w-100"
                variant="outlined"
                label="Recipient Email"
                onChange={(e) => setInvoiceRecipient(e.target.value)}
              ></TextField>
            </Col>
          </Row>
        )}
      </Modal.Body>
      <Modal.Footer>
        <Button variant={"danger"} onClick={handleCancelEvent}>
          Cancel
        </Button>
        {!showConfirmInvoice ? (
          <Button variant={"primary"} onClick={handleConfirmEvent}>
            {`Print ${type}`}
          </Button>
        ) : (
          <Button
            variant={"primary"}
            onClick={() => handleSendInvoice(invoiceRecipient)}
          >
            {`Send Invoice`}
          </Button>
        )}
      </Modal.Footer>
    </Modal>
  );
}

export default memo(PrintItemPopup);
