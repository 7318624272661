import React, { useEffect, useState, useContext } from "react";
import { useDispatch, useSelector } from "react-redux";
import { NavLink, useLocation, useNavigate } from "react-router-dom";
import {
  IconButton,
  Button,
  FormControlLabel,
  Switch,
  Tooltip,
  Menu,
  MenuItem,
  Typography,
  Avatar,
  Divider,
} from "@mui/material";
import Container from "react-bootstrap/Container";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";

import mitcoLogo from "../../../assets/images/MITCOLogo.svg";
import defaultUser from "../../../assets/images/profile-default-svgrepo-com.svg";
import { LoginContext } from "../../../contexts/LoginContext";
import { apiGet } from "../../../api/apiGet";
import { AlertMessageContext } from "../../atoms/AlertMessage/AlertMessage";
import { setShouldAutosave } from "../../../store/slices/eventHandlerSlice";
import "./Navigation.scss";
import CreditNavigation from "./creditNavigation/creditNav";
import { endpoints } from "../../../api/endpoints";

const Navigation = (props) => {
  const orderRoute = props.orderRoute;
  const creditRoute = props.creditRoute;
  const invoiceRoute = endpoints.ACCOUNTS_RECEIVABLE_ENTRY;
  const location = useLocation();
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const alertNotification = useContext(AlertMessageContext);
  const [anchorElUser, setAnchorElUser] = useState(null);

  const {
    autoSaveOrder: { shouldAutosave },
  } = useSelector((state) => state.eventHandler);

  const [menuLinks, setMenuLinks] = useState([
    { link: orderRoute, label: "New Order", cssClass: "innactive-button" },
    {
      link: orderRoute + "/order-search/",
      label: "Orders",
      cssClass: "innactive-button",
    },
    {
      link: orderRoute + "/quotes-search/",
      label: "Quotes",
      cssClass: "innactive-button",
    },
    {
      link: orderRoute + "/customers",
      label: "Customers",
      cssClass: "innactive-button",
    },
    { link: orderRoute + "/bol", label: "BOL", cssClass: "innactive-button" },
  ]);

  const [creditLinks, setCreditLinks] = useState([
    {
      link: creditRoute + "/?new",
      label: "Credit Entry",
      cssClass: "innactive-button",
    },
    {
      link: creditRoute + "/credit-search/",
      label: "Search Credits",
      cssClass: "innactive-button",
    },
    {
      link: creditRoute + "/warranty-claim/?new",
      label: "Claim Entry",
      cssClass: "innactive-button",
    },
    {
      link: creditRoute + "/claim-search",
      label: "Search Claims",
      cssClass: "innactive-button",
    },
  ]);

  const [invoiceLinks, setInvoiceLinks] = useState([
    {
      link: invoiceRoute,
      label: "Send Invoices",
      cssClass: "innactive-button",
    },
    {
      link: invoiceRoute + "/invoice-search/",
      label: "Search Invoice",
      cssClass: "innactive-button",
    },
  ]);

  const { isAuthenticated, setAuthenticated } = useContext(LoginContext);

  const logOut = async () => {
    try {
      await apiGet.logOut();
      setAuthenticated(false);
    } catch (error) {
      alertNotification.handleOpen(
        "error-logging-out",
        "danger",
        `Error logging out. ${error}`,
        10000
      );
    }
  };

  // this sets the active button in the navbar based on the current pathname
  useEffect(() => {
    setMenuLinks((menuLinks) =>
      menuLinks.map((menuLink) => {
        if (location.pathname === menuLink.link) {
          menuLink.cssClass = "nav-link active";
        } else {
          menuLink.cssClass = "nav-link innactive";
        }
        return menuLink;
      })
    );
  }, [location.pathname, isAuthenticated]);

  const handleOpenUserMenu = (e) => {
    setAnchorElUser(e.currentTarget);
  };

  const handleCloseUserMenu = () => {
    setAnchorElUser(null);
  };

  const handleOpenAccount = () => {
    navigate("/my-account");
  };

  return (
    <div
      className="Navigation pt-4 pb-2"
      style={{ borderBottom: "1px solid rgba(210, 212, 219, 0.5)" }}
    >
      <Container fluid="xxl" className="navigationContainer">
        <Row className="no-pad">
          <Col sm={2} className="d-flex align-items-center">
            <IconButton
              onClick={() => (window.location.href = "/")}
              className="icon-button "
            >
              <img alt="Mitco" width={90} src={mitcoLogo}></img>
            </IconButton>
          </Col>
          {props.showOrderNav && props.groups.includes(props.orderGroup) && (
            <Col sm={6} className="d-flex align-items-center no-pad subhw">
              <nav className="nav no-pad">
                {menuLinks.map((menuLink, index) => {
                  if (index === 0) {
                    return (
                      <span className="test" key={index}>
                        <span className="nav-divider-empty"></span>
                        <Button
                          key={index}
                          color="inherit"
                          className={menuLink.cssClass}
                        >
                          <NavLink to={menuLink.link}>{menuLink.label}</NavLink>
                        </Button>
                      </span>
                    );
                  } else {
                    return (
                      <span className="test " key={index}>
                        <span className="nav-divider">|</span>
                        <Button
                          key={index}
                          color="inherit"
                          className={menuLink.cssClass}
                        >
                          <NavLink to={menuLink.link}>{menuLink.label}</NavLink>
                        </Button>
                      </span>
                    );
                  }
                })}
              </nav>
            </Col>
          )}
          {props.showInvoiceNav &&
            props.groups.includes(props.accountsReceivableGroup) && (
              <Col sm={6} className="d-flex align-items-center no-pad subhw">
                <nav className="nav no-pad">
                  {invoiceLinks.map((menuLink, index) => {
                    if (index === 0) {
                      return (
                        <span className="test" key={index}>
                          <span className="nav-divider-empty"></span>
                          <Button
                            key={index}
                            color="inherit"
                            className={menuLink.cssClass}
                          >
                            <NavLink to={menuLink.link}>
                              {menuLink.label}
                            </NavLink>
                          </Button>
                        </span>
                      );
                    } else {
                      return (
                        <span className="test " key={index}>
                          <span className="nav-divider">|</span>
                          <Button
                            key={index}
                            color="inherit"
                            className={menuLink.cssClass}
                          >
                            <NavLink to={menuLink.link}>
                              {menuLink.label}
                            </NavLink>
                          </Button>
                        </span>
                      );
                    }
                  })}
                </nav>
              </Col>
            )}
          {props.showCreditNav && props.groups.includes(props.creditGroup) && (
            <Col sm={6} className="d-flex align-items-center no-pad subhw">
              <nav className="nav no-pad">
                {creditLinks.map((menuLink, index) => {
                  if (index === 0) {
                    return (
                      <span className="test" key={index}>
                        <span className="nav-divider-empty"></span>
                        <Button
                          key={index}
                          color="inherit"
                          className={menuLink.cssClass}
                        >
                          <NavLink to={menuLink.link}>{menuLink.label}</NavLink>
                        </Button>
                      </span>
                    );
                  } else {
                    return (
                      <span className="test " key={index}>
                        <span className="nav-divider">|</span>
                        <Button
                          key={index}
                          color="inherit"
                          className={menuLink.cssClass}
                        >
                          <NavLink to={menuLink.link}>{menuLink.label}</NavLink>
                        </Button>
                      </span>
                    );
                  }
                })}
              </nav>
            </Col>
          )}

          <Col
            sm={
              (props.showOrderNav && props.groups.includes(props.orderGroup)) ||
              (props.showCreditNav &&
                props.groups.includes(props.creditGroup)) ||
              (props.showInvoiceNav &&
                props.groups.includes(props.accountsReceivableGroup))
                ? 4
                : 10
            }
          >
            <nav className="nav justify-content-end">
              <Tooltip title="Open settings">
                <IconButton onClick={handleOpenUserMenu}>
                  <Avatar alt="User" src={defaultUser} />
                </IconButton>
              </Tooltip>
              <Menu
                id="menu-appbar"
                anchorEl={anchorElUser}
                keepMounted
                open={Boolean(anchorElUser)}
                onClose={handleCloseUserMenu}
              >
                <MenuItem>
                  <FormControlLabel
                    sx={{ margin: 0 }}
                    control={
                      <Switch
                        name="autosave"
                        color="primary"
                        checked={shouldAutosave}
                        onChange={(e) =>
                          dispatch(setShouldAutosave(e.target.checked))
                        }
                      />
                    }
                    label="Autosave"
                    labelPlacement="start"
                  />
                </MenuItem>

                <Divider sx={{ borderTop: 1 }} />

                <MenuItem onClick={handleOpenAccount}>
                  <Typography textAlign="center">My Account</Typography>
                </MenuItem>

                <MenuItem onClick={logOut}>Log Out</MenuItem>
              </Menu>
            </nav>
          </Col>
        </Row>
      </Container>
    </div>
  );
};

Navigation.propTypes = {};

export default Navigation;
