import { Card } from "react-bootstrap";
import { useSelector } from "react-redux";
import NormalClaimLine from "./LineModules/NormalClaimLine";

const ClaimLineItems = ({ show, disabled }) => {
  const { possible_items } = useSelector((state) => state.claims);
  return (
    <>
      <Card hidden={!show}>
        {possible_items.map((item) => {
          return (
            <NormalClaimLine
              key={item.item_key}
              item={item}
              disabled={disabled}
            />
          );
        })}
      </Card>
    </>
  );
};

export default ClaimLineItems;
