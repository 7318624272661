import { Autocomplete, TextField } from "@mui/material";
import { Col, Row } from "react-bootstrap";
import { updateClaimItem } from "../../../../store/slices/Claims/ClaimSlice";
import { useDispatch, useSelector } from "react-redux";
import { useEffect, useState } from "react";
import {
  ClaimUseLevel,
  FREIGHT_CLAIM,
  LOW_USE_LEVEL,
  TIRE_CLAIM,
  TRACK_CLAIM,
} from "../../../../helpers/const";
import AddIcon from "@mui/icons-material/Add";
import { defaultOperationItem } from "../../../../api/models/ClaimModel";
import OperationLine from "./OperationLine";
import UploadClaimImage from "./UploadClaimImage";

const CASES = {
  QTY: 0,
  SIZE: 1,
  SERIAL: 2,
  INSTALL_DATE: 3,
  FAIL_DATE: 4,
  HOURS_USED: 5,
  METER_INSTALL: 6,
  METER_FAILURE: 7,
  MAKE: 8,
  MODEL: 9,
  APP_USE: 10,
  USE_LEVEL: 11,
  AVG_SPEED: 12,
  AVG_WEIGHT: 13,
  NOTES: 14,
  PROBLEM: 15,
  EQ_SERIAL: 16,
};

const ClaimLine = ({ item, totalRemaining, disabled }) => {
  const dispatch = useDispatch();

  const { problem_list, claim_type } = useSelector((state) => state.claims);
  const [showSelf, setShowSelf] = useState(true);

  const updateItem = (value, use_case) => {
    let current_item = { ...item };
    let val = value;

    switch (use_case) {
      case CASES.QTY:
        val = parseInt(val);

        if (!value) val = 0;
        if (val > item.quantity && item.quantity + val > totalRemaining)
          val = item.quantity + totalRemaining;
        current_item = {
          ...current_item,
          quantity: val,
          claim_amount: val * item.customer_price,
          total_weight: val * item.unit_weight,
        };
        break;
      case CASES.SIZE:
        current_item = {
          ...current_item,
          size: val,
        };
        break;
      case CASES.SERIAL:
        current_item = {
          ...current_item,
          serial_num: val,
        };
        break;
      case CASES.INSTALL_DATE:
        val = formatDate(val);
        current_item = {
          ...current_item,
          install_date: val,
        };
        break;
      case CASES.FAIL_DATE:
        val = formatDate(val);
        current_item = {
          ...current_item,
          date_of_failure: val,
        };
        break;

      case CASES.HOURS_USED:
        current_item = {
          ...current_item,
          hours_in_use: val,
        };
        break;
      case CASES.METER_INSTALL:
        current_item = {
          ...current_item,
          hour_meter_install: val,
        };
        break;
      case CASES.METER_FAILURE:
        current_item = {
          ...current_item,
          hour_meter_failure: val,
        };
        break;
      case CASES.MAKE:
        current_item = {
          ...current_item,
          equipment_make: val,
        };
        break;
      case CASES.MODEL:
        current_item = {
          ...current_item,
          equipment_model: val,
        };
        break;

      case CASES.APP_USE:
        current_item = {
          ...current_item,
          application_use: val,
        };
        break;
      case CASES.USE_LEVEL:
        current_item = {
          ...current_item,
          use_level: ClaimUseLevel.filter((option) => option.label === val)[0]
            .id,
        };
        break;
      case CASES.AVG_SPEED:
        current_item = {
          ...current_item,
          average_load_speed: val,
        };
        break;
      case CASES.AVG_WEIGHT:
        current_item = {
          ...current_item,
          average_load_weight: val,
        };
        break;
      case CASES.NOTES:
        current_item = {
          ...current_item,
          notes: val,
        };
        break;
      case CASES.PROBLEM:
        current_item = {
          ...current_item,
          problem_num: parseInt(val),
        };
        break;
      case CASES.EQ_SERIAL:
        current_item = {
          ...current_item,
          equipment_serial_num: val,
        };
    }
    dispatch(updateClaimItem(current_item));
  };

  function formatDate(val) {
    val = val.replace(/\D/g, "");
    const year = val.slice(0, 4);
    const month = val.slice(4, 6);
    const day = val.slice(6, 8);

    let formatted_date = "";
    if (val.length > 6) formatted_date = `${year}-${month}-${day}`;
    else if (val.length > 4) formatted_date = `${year}-${month}`;
    else if (val.length > 0) formatted_date = `${year}`;

    return formatted_date;
  }

  const addOperationItem = () => {
    const current_item = {
      ...item,
      operation_items: [
        ...item.operation_items,
        {
          ...defaultOperationItem,
          claim_item: item.item_key,
          sequence_num: Math.random(),
        },
      ],
    };
    dispatch(updateClaimItem(current_item));
  };

  const hideItem = () => {
    setShowSelf(!showSelf);
  };

  const problemProps = {
    options: problem_list,
    getOptionLabel: (option) => option.problem_num + " - " + option.description,
  };

  const useProps = {
    options: ClaimUseLevel,
    getOptionLabel: (option) => option.label,
  };

  return (
    <div className="credit-item-dropdown">
      {!showSelf && (
        <>
          <Row>
            <Col>
              <b>Qty Claimed: </b> {item.quantity}
            </Col>
            <Col>
              <b>Total Weight: </b> {item.total_weight}
            </Col>
            <Col>
              <b>Claim Total: </b>
              {item.claim_amount}
            </Col>
            <Col className="collapse-item" onClick={hideItem}>
              show
            </Col>
          </Row>
        </>
      )}
      {showSelf && (
        <>
          <UploadClaimImage parentKey={item.item_key} disabled={disabled} />
          <Row>
            <Col>
              <b>Total Claim: </b> {parseFloat(item.claim_amount).toFixed(2)}
            </Col>
            <Col className="collapse-item" onClick={hideItem}>
              Collapse
            </Col>
          </Row>
          <Row className="mb-5">
            <Col>
              <b>Total Weight: </b> {parseFloat(item.total_weight).toFixed(2)}
            </Col>
          </Row>
          <Row>
            <Col>
              <div className="info-header">Quantity</div>
              <TextField
                disabled={disabled}
                className="w-100"
                value={item.quantity}
                helperText={"Additional Allowed: " + totalRemaining}
                onChange={(e) => updateItem(e.target.value, CASES.QTY)}
              />
            </Col>
            <Col hidden={claim_type === FREIGHT_CLAIM.id}>
              <div className="info-header">Size</div>
              <TextField
                disabled={disabled}
                className="w-100"
                value={item.size}
                onChange={(e) => updateItem(e.target.value, CASES.SIZE)}
              />
            </Col>
            <Col hidden={claim_type === FREIGHT_CLAIM.id}>
              <div className="info-header">Serial Number</div>
              <TextField
                disabled={disabled}
                className="w-100"
                value={item.serial_num}
                onChange={(e) => updateItem(e.target.value, CASES.SERIAL)}
              />
            </Col>
            <Col hidden={claim_type === FREIGHT_CLAIM.id}>
              <div className="info-header">Install Date</div>
              <TextField
                disabled={disabled}
                className="w-100"
                value={item.install_date}
                onChange={(e) => updateItem(e.target.value, CASES.INSTALL_DATE)}
              />
            </Col>
            <Col hidden={claim_type === FREIGHT_CLAIM.id}>
              <div className="info-header">Date Of Failure</div>
              <TextField
                disabled={disabled}
                className="w-100"
                value={item.date_of_failure}
                onChange={(e) => updateItem(e.target.value, CASES.FAIL_DATE)}
              />
            </Col>
          </Row>
          <Row>
            <Col hidden={claim_type === FREIGHT_CLAIM.id}>
              <div className="info-header">Hours In Use</div>
              <TextField
                disabled={disabled}
                className="w-100"
                value={item.hours_in_use}
                onChange={(e) => updateItem(e.target.value, CASES.HOURS_USED)}
                type="number"
              />
            </Col>

            <Col hidden={claim_type !== TRACK_CLAIM.id}>
              <div className="info-header">Hour Meter Install</div>
              <TextField
                disabled={disabled}
                className="w-100"
                value={item.hour_meter_install}
                onChange={(e) =>
                  updateItem(e.target.value, CASES.METER_INSTALL)
                }
                type="number"
              />
            </Col>
            <Col hidden={claim_type !== TRACK_CLAIM.id}>
              <div className="info-header">Hour Meter Failure</div>
              <TextField
                disabled={disabled}
                className="w-100"
                value={item.hour_meter_failure}
                onChange={(e) =>
                  updateItem(e.target.value, CASES.METER_FAILURE)
                }
                type="number"
              />
            </Col>
            <Col hidden={claim_type === FREIGHT_CLAIM.id}>
              <div className="info-header">Equipment Make</div>
              <TextField
                disabled={disabled}
                className="w-100"
                value={item.equipment_make}
                onChange={(e) => updateItem(e.target.value, CASES.MAKE)}
              />
            </Col>
            <Col hidden={claim_type === FREIGHT_CLAIM.id}>
              <div className="info-header">Equipment Model</div>
              <TextField
                disabled={disabled}
                className="w-100"
                value={item.equipment_model}
                onChange={(e) => updateItem(e.target.value, CASES.MODEL)}
              />
            </Col>
            <Col hidden={claim_type !== TRACK_CLAIM.id}>
              <div className="info-header">Equipment Serial #</div>
              <TextField
                disabled={disabled}
                className="w-100"
                value={item.equipment_serial_num}
                onChange={(e) => updateItem(e.target.value, CASES.EQ_SERIAL)}
              />
            </Col>
          </Row>
          <Row>
            <Col hidden={claim_type !== TIRE_CLAIM.id}>
              <div className="info-header">Application Use</div>
              <TextField
                disabled={disabled}
                className="w-100"
                value={item.application_use}
                onChange={(e) => updateItem(e.target.value, CASES.APP_USE)}
              />
            </Col>
            <Col hidden={claim_type !== TIRE_CLAIM.id}>
              <div className="info-header">Use Level</div>
              <Autocomplete
                disabled={disabled}
                disableClearable
                {...useProps}
                className="autocomplete-input-credit"
                onChange={(e) =>
                  updateItem(e.target.textContent, CASES.USE_LEVEL)
                }
                value={
                  ClaimUseLevel.filter((prob) => prob.id === item.use_level)[0]
                }
                isOptionEqualToValue={(option, value) =>
                  option.id === value ? value.id : value
                }
                renderInput={(params) => (
                  <TextField
                    className="autocomplete-problems"
                    {...params}
                    variant="standard"
                  />
                )}
              />
            </Col>
            <Col hidden={claim_type !== TIRE_CLAIM.id}>
              <div className="info-header">Average Load Speed</div>
              <TextField
                disabled={disabled}
                className="w-100"
                value={item.average_load_speed}
                onChange={(e) => updateItem(e.target.value, CASES.AVG_SPEED)}
                type="number"
              />
            </Col>
            <Col hidden={claim_type !== TIRE_CLAIM.id}>
              <div className="info-header">Average Load Weight</div>
              <TextField
                disabled={disabled}
                className="w-100"
                value={item.average_load_weight}
                onChange={(e) => updateItem(e.target.value, CASES.AVG_WEIGHT)}
                type="number"
              />
            </Col>
          </Row>
          <Row>
            <Col>
              <div className="info-header">Notes</div>
              <TextField
                disabled={disabled}
                className="w-100"
                value={item.notes}
                onChange={(e) => updateItem(e.target.value, CASES.NOTES)}
                helperText={
                  "Remaining Characters: " + (80 - item.notes.length) + " / 80"
                }
              />
            </Col>
            <Col>
              <div className="info-header">Problems</div>
              <Autocomplete
                disabled={disabled}
                disableClearable
                {...problemProps}
                className="autocomplete-input-credit"
                onChange={(e) =>
                  updateItem(e.target.textContent.split(" ")[0], CASES.PROBLEM)
                }
                value={
                  problem_list.filter(
                    (prob) => prob.problem_num === item.problem_num
                  )[0]
                }
                isOptionEqualToValue={(option, value) =>
                  option.problem_num === value ? value.problem_num : value
                }
                renderInput={(params) => (
                  <TextField
                    className="autocomplete-problems"
                    {...params}
                    variant="standard"
                  />
                )}
              />
            </Col>
          </Row>

          {item.operation_items.map((item) => {
            return (
              <div key={item.sequence_num}>
                <OperationLine
                  hidden={claim_type === FREIGHT_CLAIM.id}
                  key={item.claim_item}
                  item={item}
                  disabled={disabled}
                />
              </div>
            );
          })}
          <Row hidden={claim_type === FREIGHT_CLAIM.id || disabled}>
            <Col>
              <div className="txt-btn" onClick={addOperationItem}>
                <AddIcon /> Add Operating Condition
              </div>
            </Col>
          </Row>
        </>
      )}
    </div>
  );
};

export default ClaimLine;
