import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { apiGet } from "../../../api/apiGet";

export const getSuggestedOrderReport = createAsyncThunk(
  "items/Delete",
  async (
    { vendCat, mthNet, mthOrder, warehouse, dropShip, database },
    thunkAPI,
  ) => {
    try {
      const response = await apiGet.SuggestedOrderReport(
        vendCat,
        mthNet,
        mthOrder,
        warehouse,
        dropShip,
        database,
      );

      const url = window.URL.createObjectURL(new Blob([response.data]));
      const link = document.createElement("a");

      const today = new Date();

      // Get the date components
      const year = today.getFullYear();
      const month = today.getMonth() + 1; // JavaScript months are 0-indexed
      const day = today.getDate();

      // Format the date string
      const formattedDate = `${year}-${month.toString().padStart(2, "0")}-${day.toString().padStart(2, "0")}`;

      link.href = url;
      link.setAttribute(
        "download",
        "Suggested Order Report " + formattedDate + "_" + vendCat + ".xlsx",
      ); // Set the desired filename
      document.body.appendChild(link);
      link.click();
      document.body.removeChild(link);
    } catch (err) {
      if (err.response.status === 400) {
        return thunkAPI.rejectWithValue({
          axiosError: err,
          customMsg:
            "Error generating the suggested order report. " +
            err.response.data.split("\n")[1],
        });
      } else {
        return thunkAPI.rejectWithValue({
          axiosError: err,
          customMsg: "Error generating the suggested order report.",
        });
      }
    }
  },
);

export const purchaseSlice = createSlice({
  name: "purchaseSlice",
  initialState: {
    purchaseLoading: false,
  },
  reducers: {
    setLoading: (state, action) => {
      state.purchaseLoading = action.payload;
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(getSuggestedOrderReport.pending, (state, action) => {
        state.purchaseLoading = true;
      })
      .addCase(getSuggestedOrderReport.fulfilled, (state, action) => {
        state.purchaseLoading = false;
      })
      .addCase(getSuggestedOrderReport.rejected, (state, action) => {
        state.purchaseLoading = false;
      });
  },
});

export default purchaseSlice.reducer;
