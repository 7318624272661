import { Button, Card, Col, Row } from "react-bootstrap";
import ImageSlider from "../../../Credits/CreditInputModule.js/ImageSlider";
import { useDispatch, useSelector } from "react-redux";
import { useState } from "react";
import {
  removeClaimImage,
  setClaimImages,
  updateClaimImage,
} from "../../../../store/slices/Claims/ClaimSlice";
import { defaultImage } from "../../../../api/models/ClaimModel";

const MAX_Images = 10;

const UploadClaimImage = ({ parentKey, disabled }) => {
  const { claim_images } = useSelector((state) => state.claims);

  const dispatch = useDispatch();

  const [showSlideShow, setShowSlideShow] = useState(false);

  const addImage = () => {
    dispatch(
      setClaimImages([
        ...claim_images,
        { ...defaultImage, parent_key: parentKey, id: Math.random() },
      ])
    );
  };

  const deleteAction = (key) => {
    dispatch(removeClaimImage(key));
  };

  const updateImage = (key, image) => {
    dispatch(updateClaimImage({ key, image: URL.createObjectURL(image) }));
  };
  return (
    <>
      <Card>
        <Row>
          <Col
            className="collapse-item"
            style={{ display: "flex", alignItems: "center" }}
            onClick={() => setShowSlideShow(!showSlideShow)}
            hidden={claim_images.length === 0}
          >
            {!showSlideShow ? "Show" : "Hide"} Slideshow
          </Col>
          <Col className=" w-25">
            <Button className="f-right" onClick={addImage} disabled={disabled}>
              Add Image
            </Button>
          </Col>
        </Row>
        <Row>
          {showSlideShow && (
            <ImageSlider
              images={claim_images.filter((image) => image.image !== null)}
              deleteAction={deleteAction}
              setShowSlideShow={setShowSlideShow}
            />
          )}
        </Row>
        <Row>
          <Col>
            {/* This code only manages null images, once it is no longer null, the Image slider takes over */}
            {claim_images
              .filter(
                (image) =>
                  image.parent_key === parentKey && image.image === null
              )
              .map((image, index) => (
                <Row className="mt-5" key={index}>
                  <Col key={image.id} className="mt-2">
                    {image.image === null && (
                      <input
                        type="file"
                        name="someImage"
                        onChange={(e) =>
                          updateImage(image.id, e.target.files[0])
                        }
                      />
                    )}
                  </Col>
                  <Col
                    className="collapse-item"
                    style={{ textAlign: "right", marginRight: "5%" }}
                    onClick={() => deleteAction(image.id)}
                  >
                    Remove Image
                  </Col>
                </Row>
              ))}
          </Col>
        </Row>
      </Card>
    </>
  );
};

export default UploadClaimImage;
