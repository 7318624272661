import ClaimEntry from "./ClaimEntry";

const Claims = () => {
  return (
    <>
      <ClaimEntry />
    </>
  );
};

export default Claims;
