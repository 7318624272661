import React from "react";
import { NavLink } from "react-router-dom";
import Card from "react-bootstrap/Card";
import { endpoints } from "../../../api/endpoints";

function AfterMarket({ id, name, billToDealer }) {
  // Redux

  return (
    <div className="bg-light-danger p-4 rounded h-100">
      <Card.Title className="mb-1">Aftermarket Information</Card.Title>
      <div>
        <span>Aftermarket: </span>
        <NavLink to={endpoints.ORDER_ENTRY + `/customers/${id}`}>
          {name}
        </NavLink>
      </div>
      <div>
        <span>Dealer#: {billToDealer}</span>
      </div>
    </div>
  );
}

export default AfterMarket;
