import { Button, ButtonGroup, Col, Row } from "react-bootstrap";
import { useDispatch, useSelector } from "react-redux";

import "../CreditInput.scss";
import { useEffect, useState } from "react";
import {
  addCreditItem,
  addDescriptionItem,
  removeCreditItem,
} from "../../../../store/slices/Credits/CreditSlice";

import KeyboardArrowRightIcon from "@mui/icons-material/KeyboardArrowRight";
import KeyboardArrowDownIcon from "@mui/icons-material/KeyboardArrowDown";
import CreditLine from "./CreditLine";

import AddIcon from "@mui/icons-material/Add";
import RemoveIcon from "@mui/icons-material/Remove";
import { ADJUSTMENT_CREDIT, CREDIT_TYPE } from "../../../../helpers/const";

/**
 *
 * @param  description this is stored in the original order and is not editable
 * @param  qtyShipped this is stored in the original order and is not editable
 * @param  lineNum this is stored in the original order and is not editable
 * @param  itemPrice this is stored in the original order and is not editable
 * @param  weight this is stored in the original order and is not editable
 * @param  price this is stored in the original order and is not editable
 * @param  productCode this is stored in the original order and is not editable
 * @param id this is a relation id to connect credit items with display items
 *
 * @param disabled this is determined by order entry whether or not this order should be editable
 *
 * @returns
 */
const LineItem = ({
  productCode,
  qtyShipped,
  lineNum,
  itemPrice,
  weight,
  description,
  price,
  disabled,
  itemType,
}) => {
  const { creditItems, creditType } = useSelector((state) => state.credits);

  const [credited, setCredited] = useState(false);
  const [showInput, setShowInput] = useState(false);

  //These 3 fields are used primarily for managing the CreditLines component to ensure totals and maximums are maintained accross all line items
  const [totalReturn, setTotalReturn] = useState(0);
  const [totalCredit, setTotalCredit] = useState(0);
  const [totalWeight, setTotalWeight] = useState(0);

  const [creditLinesChanged, setCreditLinesChanged] = useState(false);

  const dispatch = useDispatch();

  //These two work together, after changes to either, totalCount === creditLines.length

  // totalCount contains the number of credit items to each order item and creditLines contains the actual objects that are displayed
  const [totalCount, setTotalCount] = useState(0);
  const [creditLines, setCreditLines] = useState([]);

  const findMaxForProductCode = () => {
    let max = 0;
    for (let item of creditItems) {
      if (item.key) {
        const key_parts = item.key.split("-");
        if (key_parts[0] === productCode && parseInt(key_parts[1]) > max) {
          max = parseInt(key_parts[1]);
        }
      }
    }

    return max + 1;
  };

  /**
   * On initial mount, if we already have credit items, check if there are any that belong to this item and if so, add them to creditLines
   */
  useEffect(() => {
    if (creditItems) {
      let items = creditItems.filter(
        (item) => item.key && item.key.split("-")[0] === productCode,
      );
      items = items.filter((item) => item.type === itemType);
      let total = items.length;
      changeCount(total);
      let tempArr = [];
      for (let i = 0; i < items.length; i++) {
        tempArr.push({
          key: items[i].key,
          sequence_num: items[i].sequence_num,
        });
      }
      setCreditLines(tempArr);
    }
  }, []);

  /**
   * These three fields are updated any time a creditItem is added
   * totalReturn is used to manage maximum qty among children
   * All three fields are used to display a summary when a lineitem is hidden
   */
  useEffect(() => {
    let tempReturn = 0;
    let total = 0;
    let weight = 0;
    for (let i = 0; i < creditLines.length; i++) {
      const curr_item = creditLines[i];
      let item = creditItems.filter((item) => item.key === curr_item.key)[0];
      if (item) {
        tempReturn += item.return;
        total += itemPrice * item.return * (item.percent_allowed / 100);
        weight += item.return * item.weight;
      }
    }

    setTotalCredit(total);
    setTotalReturn(tempReturn);
    setTotalWeight(weight);
    setCreditLinesChanged(false);
  }, [creditLinesChanged]);

  /**
   * This method takes in a new count and finds the item that should be removed or creates an item that should be added.
   * It then updates both creditLines and the redux creditItems
   *
   * @param val reprents the new totalCount (creditLines.length)
   * @returns
   */
  const changeCount = (val) => {
    let tempArr = [];

    /**
     * @warning
     *
     * if there is an issue with credit lines adding, it is likely here.
     *
     * If we are trying to increase the total qty to be great that then total allowed amount
     *  or
     * if we are trying to make total qty less than 0
     *  then
     * dont do an update
     */
    const maxAllowed = totalReturn + (qtyShipped - totalReturn);
    let actual = val > maxAllowed || val < 0 ? totalCount : val;
    const rand = Math.random(); //this is a random key used to identify a creditItem initially before it receives a unique sequence num from the API
    setTotalCount(actual);

    // if the logic above results in actual === totalCount, that means the conditions in @warning were violated and we should not do an update
    if (actual === totalCount) {
      return;
    }

    /**
     * If the new count is going to be less than the old count, remove the item
     *
     * Even though val is the decremented count, totalCount is 1 based and arrays are 0 based
     * Therefore, using val as the key is the same as accessing the last item in creditLines always
     *
     * Ex:
     *  val (newCount) === 1
     *  totalCount (oldCount) === 2
     *
     *  the nth element (where n === totalCount) === arr[val]
     */
    if (val < totalCount) {
      const toRemove = creditLines.pop();
      dispatch(removeCreditItem(toRemove.key));
      const index = creditLines.indexOf(toRemove);
      tempArr = val === 0 ? [] : [...creditLines].splice(index, 1);
    } else {
      /**
       * If val(newCount) is going to be greater than totalCount (oldCount)  we need to add an item to both creditItems and creditLines
       *
       * This is where the definition of the item is and any changes should be made from here and in getCredit.fulfilled in CreditSlice.js
       */
      const max = findMaxForProductCode();
      dispatch(
        addCreditItem({
          key: productCode + `-${max}`,
          line_num: lineNum, // This should not change
          percent_allowed: 100.0,
          comments: "",
          problem_num: 8,
          type: itemType,
          return: creditType === ADJUSTMENT_CREDIT.id ? qtyShipped : 0,
          credit_amount: 0,
          manufacturer_defect: "N",
          added_to_stock: "N",
          item_price: itemPrice,
          in_house: "N",
          parentId: -1, //this never changes
          unique: -1, // this never changes
          weight: weight,
          serial_num: "",
          product_code: productCode,
          sequence_num: rand,
        }),
      );
      tempArr = [
        ...creditLines,
        {
          key: productCode + `-${max}`,
          sequence_num: rand,
          changed: false,
        },
      ];
      setShowInput(true);
    }
    setCreditLines([...tempArr]);
    setCredited(val > 0);
  };

  const appendDescriptionItem = () => {
    dispatch(addDescriptionItem({ line_num: lineNum }));
  };

  return (
    <div className="mb-5 bottom-border-seperator">
      <Row className="label-row">
        <Col className="g-row">
          <div className="info-header">Product Code</div>
          <div className="info-credit">{productCode}</div>
        </Col>
        <Col className="g-row">
          <div className="info-header">Description</div>
          <div className="info-credit">{description}</div>
        </Col>
        <Col className="g-row">
          <div className="info-header">Qty Shipped</div>
          <div className="info-credit">{qtyShipped}</div>
        </Col>
        <Col className="g-row">
          <div className="info-header">Item Price</div>
          <div className="info-credit">{itemPrice}</div>
        </Col>
        <Col className="g-row">
          <div className="info-header">Total Price</div>
          <div className="info-credit">{price}</div>
        </Col>
        {/* This is the counter that tracks how many credit lines per line item on the original order */}
        <Col className="counter-row">
          <div
            onClick={(e) =>
              !disabled ? changeCount(totalCount - 1) : undefined
            }
            className={"counter-btn" + (disabled ? " disabled" : "")}
          >
            <RemoveIcon />
          </div>
          <div className={disabled ? "disabled" : ""}>{totalCount}</div>
          <div
            onClick={(e) =>
              !disabled ? changeCount(totalCount + 1) : undefined
            }
            className={"counter-btn" + (disabled ? " disabled" : "")}
          >
            <AddIcon />{" "}
          </div>
        </Col>

        <Col className="g-line">
          <Button
            disabled={!credited}
            onClick={() => setShowInput(!showInput)}
            className="bg-dull mt-4"
          >
            {showInput ? "Hide " : "Show"}{" "}
            {showInput && <KeyboardArrowDownIcon />}
            {!showInput && <KeyboardArrowRightIcon />}
          </Button>
        </Col>
        <Col className="g-row">
          <Button
            onClick={appendDescriptionItem}
            className="bg-dull mt-4"
            disabled={disabled || !credited}
          >
            Add Description
          </Button>
        </Col>
      </Row>
      {/* Summary of credit items shown when a line item is hidden */}
      {credited && (
        <Row>
          {!showInput && (
            <Col>
              <i>
                <b>Item Total Qty:</b> {totalReturn} <b>Item Total Credit:</b>{" "}
                {totalCredit.toFixed(2)} <b>Item Total Weight:</b> {totalWeight}
              </i>
            </Col>
          )}
        </Row>
      )}

      {/* These are the input fields for the actual credit line items */}
      {credited &&
        showInput &&
        creditLines.map((item) => {
          return (
            <div key={item.key}>
              <CreditLine
                key={item.key ? item.key : 0}
                itemKey={item.key ? item.key : 0}
                qtyShipped={qtyShipped}
                disabled={disabled}
                totalReturn={totalReturn}
                setChanged={setCreditLinesChanged}
              />
            </div>
          );
        })}
    </div>
  );
};

export default LineItem;
