import { memo, useState } from "react";
import { Modal, Button, Row, Col, Card, Dropdown } from "react-bootstrap";
import { TextField } from "@mui/material";

function SendClaimSummary({ show, handleCancelEvent, handleConfirmEvent }) {
  const [currentMethod, setCurrentMethod] = useState("print");
  const [email, setCurrentEmail] = useState("");
  return (
    <Modal show={show} centered aria-labelledby="contained-modal-title-vcenter">
      <Modal.Header>
        <div>
          <b>Send Claim Summary</b>
        </div>
      </Modal.Header>

      <Modal.Body>
        <Card>
          <Card.Title className="mb-4">
            <Row>
              <Col style={{ display: "grid", alignItems: "center" }}>
                <div>Delivery Method</div>
              </Col>
              <Col>
                <Dropdown className="f-right">
                  <Dropdown.Toggle className="drop-status-button f-right">
                    {currentMethod}
                  </Dropdown.Toggle>
                  <Dropdown.Menu>
                    <Dropdown.Item
                      onClick={(e) =>
                        setCurrentMethod(e.target.textContent.toLowerCase())
                      }
                    >
                      Print
                    </Dropdown.Item>
                    <Dropdown.Item
                      onClick={(e) =>
                        setCurrentMethod(e.target.textContent.toLowerCase())
                      }
                    >
                      Email
                    </Dropdown.Item>
                  </Dropdown.Menu>
                </Dropdown>
              </Col>
            </Row>
          </Card.Title>
          <Card.Body>
            {currentMethod === "email" && (
              <Row>
                <Col style={{ display: "grid", alignItems: "center" }}>
                  Email Address:
                </Col>
                <Col style={{ display: "grid", alignItems: "center" }}>
                  <TextField
                    value={email}
                    onChange={(e) => setCurrentEmail(e.target.value)}
                    inputProps={{
                      maxLength: 40,
                    }}
                  />
                </Col>
              </Row>
            )}
          </Card.Body>
        </Card>
      </Modal.Body>
      <Modal.Footer>
        <Button variant={"danger"} onClick={handleCancelEvent}>
          Cancel
        </Button>
        <Button
          variant={"primary"}
          onClick={() => handleConfirmEvent(currentMethod, email)}
        >
          {currentMethod === "email" ? "Send" : "Print"}
        </Button>
      </Modal.Footer>
    </Modal>
  );
}

export default memo(SendClaimSummary);
