import { Container } from "react-bootstrap";
import {
  BrowserRouter as Router,
  Routes,
  Route,
  Navigate,
} from "react-router-dom";
import Purchasing from "../views/Purchasing/Purchasing";
import { Alert } from "@mui/material";

function PurchaseOrders(props) {
  return (
    <>
      {props.groups.includes(props.purchaseGroup) ? (
        <Container className="">
          <Routes>
            <Route path={""} element={<Purchasing />} />
          </Routes>
        </Container>
      ) : (
        <Container>
          {props.groups.length > 0 && (
            <span>
              <Alert severity="error" variant="outlined" className="mt-5">
                You are not allowed to view this page. If you believe you are
                seeing this in error, please contact a system administrator
              </Alert>
              <a href="/">Back to home</a>
            </span>
          )}
        </Container>
      )}
    </>
  );
}

export default PurchaseOrders;
