import { useEffect, useState } from "react";
import ClaimStart from "./ClaimModules/ClaimStart";
import ClaimInfoModule from "./ClaimModules/ClaimInfoModule";
import ClaimHeader from "./ClaimModules/ClaimHeader";
import CreditInputToggle from "../Credits/CreditInputModule.js/CreditInputToggle";
import { useSelector } from "react-redux";
import ClaimLineItems from "./ClaimModules/ClaimLineItems";
import ClaimLogs from "./ClaimModules/ClaimLogs";
import { CLAIM_CLOSED } from "../../helpers/const";

const ClaimEntry = () => {
  const [claimStarted, setClaimStarted] = useState(false);
  const [showClaimInfo, setShowClaimInfo] = useState(false);
  const [showLineItems, setShowLineItems] = useState(false);
  const [showClaimLogs, setShowClaimLogs] = useState(false);
  const [disabled, setDisabled] = useState(false);

  const { initial_order, claim } = useSelector((state) => state.claims);

  useEffect(() => {
    setClaimStarted(claim && claim.claim_num > 0 ? true : false);
    if (claim && claim.deleted === "Y") setDisabled(true);
    if (claim && claim.claim_status === CLAIM_CLOSED.id) setDisabled(true);
  }, [claim]);

  return (
    <>
      {claimStarted ? (
        <>
          {/* claim header */}
          <ClaimHeader setClaimStarted={setClaimStarted} disabled={disabled} />
          {/* Header of information that will not change ever */}
          <CreditInputToggle
            toggled={showClaimInfo}
            toggleFunction={() => setShowClaimInfo(!showClaimInfo)}
            label={
              "Claim Information - Invoice (" +
              initial_order.customer_invoice_num +
              ")"
            }
          />
          <ClaimInfoModule show={showClaimInfo} disabled={disabled} />
          {/* Line Items */}
          <CreditInputToggle
            toggled={showLineItems}
            toggleFunction={() => setShowLineItems(!showLineItems)}
            label={"Claim Line Items"}
          />
          <ClaimLineItems show={showLineItems} disabled={disabled} />

          {/* Claim Logs */}
          <CreditInputToggle
            toggled={showClaimLogs}
            toggleFunction={() => setShowClaimLogs(!showClaimLogs)}
            label={"Claim Logs"}
          />
          <ClaimLogs show={showClaimLogs} disabled={disabled} />
        </>
      ) : (
        <ClaimStart setClaimStarted={setClaimStarted} />
      )}
    </>
  );
};

export default ClaimEntry;
