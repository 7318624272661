import { createSlice } from "@reduxjs/toolkit";
import {
  createSubOrder,
  updateSubOrder,
  saveAsOrder,
  deleteSubOrder,
  getSubOrder,
  getOrderListByParameters,
} from "./subOrderSlice";

import { createQuote, getQuote, getQuoteListByParameters } from "./quoteSlice";

import { getAllCustomers, getCustomersList } from "./customer/customerSlice";
import {
  checkBackordersProcess,
  productChangeEvent,
  whChangeEvent,
  getProductPriceById,
  dChangeEvent,
  priceOverride,
  deleteItems,
  customerIdChangeHandler,
  mountOnEvent,
  getManufacturedItems,
} from "./item/itemSlice";

import {
  getRateLTLOptions,
  getFreightLinesByScacCode,
  getPackageRaterOptions,
} from "./freight/freightSlice";

import { sendToClient, getSentToParams } from "./faxEmail/faxEmailSlice";
import { doInvoiceAction } from "./AccountsReceivable/InvoiceAccessSlice";
import {
  checkDuplicateClaims,
  convertToCredit,
  deleteClaim,
  getClaimById,
  getClaimsBYFilter,
  getInitialOrder,
  saveClaim,
  undoDeleteClaim,
  updateClaim,
} from "./Claims/ClaimSlice";
import { getCreditsByFilter } from "./Credits/CreditSlice";

export const loaderSpinnerSlice = createSlice({
  name: "loaderSpinnerSlice",
  initialState: {
    loading: false,
  },
  extraReducers: (builder) => {
    builder
      .addCase(createSubOrder.pending, (state) => {
        state.loading = true;
      })
      .addCase(createSubOrder.fulfilled, (state) => {
        state.loading = false;
      })
      .addCase(createSubOrder.rejected, (state) => {
        state.loading = false;
      })
      .addCase(doInvoiceAction.pending, (state, action) => {
        state.loading = true;
      })
      .addCase(doInvoiceAction.fulfilled, (state, action) => {
        state.loading = false;
      })
      .addCase(doInvoiceAction.rejected, (state, action) => {
        state.loading = false;
      })

      .addCase(updateSubOrder.pending, (state) => {
        state.loading = true;
      })
      .addCase(updateSubOrder.fulfilled, (state) => {
        state.loading = false;
      })
      .addCase(updateSubOrder.rejected, (state) => {
        state.loading = false;
      })

      .addCase(saveAsOrder.pending, (state) => {
        state.loading = true;
      })
      .addCase(saveAsOrder.fulfilled, (state) => {
        state.loading = false;
      })
      .addCase(saveAsOrder.rejected, (state) => {
        state.loading = false;
      })

      .addCase(deleteSubOrder.pending, (state) => {
        state.loading = true;
      })
      .addCase(deleteSubOrder.fulfilled, (state) => {
        state.loading = false;
      })
      .addCase(deleteSubOrder.rejected, (state) => {
        state.loading = false;
      })

      .addCase(getSubOrder.pending, (state) => {
        state.loading = true;
      })
      .addCase(getSubOrder.fulfilled, (state) => {
        state.loading = false;
      })
      .addCase(getSubOrder.rejected, (state) => {
        state.loading = false;
      })

      .addCase(createQuote.pending, (state) => {
        state.loading = true;
      })
      .addCase(createQuote.fulfilled, (state) => {
        state.loading = false;
      })
      .addCase(createQuote.rejected, (state) => {
        state.loading = false;
      })

      .addCase(getQuote.pending, (state) => {
        state.loading = true;
      })
      .addCase(getQuote.fulfilled, (state) => {
        state.loading = false;
      })
      .addCase(getQuote.rejected, (state) => {
        state.loading = false;
      })
      .addCase(getAllCustomers.pending, (state) => {
        state.loading = true;
      })
      .addCase(getAllCustomers.fulfilled, (state) => {
        state.loading = false;
      })
      .addCase(getAllCustomers.rejected, (state) => {
        state.loading = false;
      })
      .addCase(getCustomersList.pending, (state) => {
        state.loading = true;
      })
      .addCase(getCustomersList.fulfilled, (state) => {
        state.loading = false;
      })
      .addCase(getCustomersList.rejected, (state) => {
        state.loading = false;
      })
      .addCase(checkBackordersProcess.fulfilled, (state) => {
        state.loading = false;
      })
      .addCase(checkBackordersProcess.rejected, (state) => {
        state.loading = false;
      })
      .addCase(getRateLTLOptions.pending, (state) => {
        state.loading = true;
      })
      .addCase(getRateLTLOptions.fulfilled, (state) => {
        state.loading = false;
      })
      .addCase(getRateLTLOptions.rejected, (state) => {
        state.loading = false;
      })
      .addCase(getPackageRaterOptions.pending, (state) => {
        state.loading = true;
      })
      .addCase(getPackageRaterOptions.fulfilled, (state) => {
        state.loading = false;
      })
      .addCase(getPackageRaterOptions.rejected, (state) => {
        state.loading = false;
      })
      .addCase(getFreightLinesByScacCode.pending, (state) => {
        state.loading = true;
      })
      .addCase(getFreightLinesByScacCode.fulfilled, (state) => {
        state.loading = false;
      })
      .addCase(getFreightLinesByScacCode.rejected, (state) => {
        state.loading = false;
      })

      .addCase(sendToClient.pending, (state) => {
        state.loading = true;
      })
      .addCase(sendToClient.fulfilled, (state) => {
        state.loading = false;
      })
      .addCase(sendToClient.rejected, (state) => {
        state.loading = false;
      })

      .addCase(getQuoteListByParameters.pending, (state) => {
        state.loading = true;
      })
      .addCase(getQuoteListByParameters.fulfilled, (state) => {
        state.loading = false;
      })
      .addCase(getQuoteListByParameters.rejected, (state) => {
        state.loading = false;
      })
      .addCase(getOrderListByParameters.pending, (state) => {
        state.loading = true;
      })
      .addCase(getOrderListByParameters.fulfilled, (state) => {
        state.loading = false;
      })
      .addCase(getOrderListByParameters.rejected, (state) => {
        state.loading = false;
      })
      .addCase(productChangeEvent.pending, (state) => {
        state.loading = true;
      })
      .addCase(productChangeEvent.fulfilled, (state) => {
        state.loading = false;
      })
      .addCase(productChangeEvent.rejected, (state) => {
        state.loading = false;
      })

      .addCase(dChangeEvent.pending, (state) => {
        state.loading = true;
      })
      .addCase(dChangeEvent.fulfilled, (state) => {
        state.loading = false;
      })
      .addCase(dChangeEvent.rejected, (state) => {
        state.loading = false;
      })

      .addCase(whChangeEvent.pending, (state) => {
        state.loading = true;
      })
      .addCase(whChangeEvent.fulfilled, (state) => {
        state.loading = false;
      })
      .addCase(whChangeEvent.rejected, (state) => {
        state.loading = false;
      })

      .addCase(getProductPriceById.pending, (state) => {
        state.loading = true;
      })
      .addCase(getProductPriceById.fulfilled, (state) => {
        state.loading = false;
      })
      .addCase(getProductPriceById.rejected, (state) => {
        state.loading = false;
      })

      .addCase(priceOverride.pending, (state) => {
        state.loading = true;
      })
      .addCase(priceOverride.fulfilled, (state) => {
        state.loading = false;
      })
      .addCase(priceOverride.rejected, (state) => {
        state.loading = false;
      })

      .addCase(deleteItems.pending, (state) => {
        state.loading = true;
      })
      .addCase(deleteItems.fulfilled, (state) => {
        state.loading = false;
      })
      .addCase(deleteItems.rejected, (state) => {
        state.loading = false;
      })

      .addCase(customerIdChangeHandler.pending, (state) => {
        state.loading = true;
      })
      .addCase(customerIdChangeHandler.fulfilled, (state) => {
        state.loading = false;
      })
      .addCase(customerIdChangeHandler.rejected, (state) => {
        state.loading = false;
      })

      .addCase(mountOnEvent.pending, (state) => {
        state.loading = true;
      })

      .addCase(mountOnEvent.fulfilled, (state) => {
        state.loading = false;
      })
      .addCase(mountOnEvent.rejected, (state) => {
        state.loading = false;
      })

      .addCase(getManufacturedItems.pending, (state) => {
        state.loading = true;
      })

      .addCase(getManufacturedItems.fulfilled, (state) => {
        state.loading = false;
      })
      .addCase(getManufacturedItems.rejected, (state) => {
        state.loading = false;
      })

      .addCase(getSentToParams.pending, (state) => {
        state.loading = true;
      })
      .addCase(getSentToParams.fulfilled, (state) => {
        state.loading = false;
      })
      .addCase(getSentToParams.rejected, (state) => {
        state.loading = false;
      })
      .addCase(getInitialOrder.pending, (state) => {
        state.loading = true;
      })
      .addCase(getInitialOrder.fulfilled, (state) => {
        state.loading = false;
      })
      .addCase(getInitialOrder.rejected, (state) => {
        state.loading = false;
      })
      .addCase(checkDuplicateClaims.pending, (state) => {
        state.loading = true;
      })
      .addCase(checkDuplicateClaims.fulfilled, (state) => {
        state.loading = false;
      })
      .addCase(checkDuplicateClaims.rejected, (state) => {
        state.loading = false;
      })
      .addCase(saveClaim.pending, (state) => {
        state.loading = true;
      })
      .addCase(saveClaim.fulfilled, (state) => {
        state.loading = false;
      })
      .addCase(saveClaim.rejected, (state) => {
        state.loading = false;
      })
      .addCase(getClaimById.pending, (state) => {
        state.loading = true;
      })
      .addCase(getClaimById.fulfilled, (state) => {
        state.loading = false;
      })
      .addCase(getClaimById.rejected, (state) => {
        state.loading = false;
      })
      .addCase(updateClaim.pending, (state) => {
        state.loading = true;
      })
      .addCase(updateClaim.fulfilled, (state) => {
        state.loading = false;
      })
      .addCase(updateClaim.rejected, (state) => {
        state.loading = false;
      })
      .addCase(deleteClaim.pending, (state) => {
        state.loading = true;
      })
      .addCase(deleteClaim.fulfilled, (state) => {
        state.loading = false;
      })
      .addCase(deleteClaim.rejected, (state) => {
        state.loading = false;
      })
      .addCase(convertToCredit.pending, (state) => {
        state.loading = true;
      })
      .addCase(convertToCredit.fulfilled, (state) => {
        state.loading = false;
      })
      .addCase(convertToCredit.rejected, (state) => {
        state.loading = false;
      })
      .addCase(getCreditsByFilter.pending, (state) => {
        state.loading = true;
      })
      .addCase(getCreditsByFilter.fulfilled, (state) => {
        state.loading = false;
      })
      .addCase(getCreditsByFilter.rejected, (state) => {
        state.loading = false;
      })
      .addCase(getClaimsBYFilter.pending, (state) => {
        state.loading = true;
      })
      .addCase(getClaimsBYFilter.fulfilled, (state) => {
        state.loading = false;
      })
      .addCase(getClaimsBYFilter.rejected, (state) => {
        state.loading = false;
      })
      .addCase(undoDeleteClaim.pending, (state) => {
        state.loading = true;
      })
      .addCase(undoDeleteClaim.fulfilled, (state) => {
        state.loading = false;
      })
      .addCase(undoDeleteClaim.rejected, (state) => {
        state.loading = false;
      });
  },
});

export default loaderSpinnerSlice.reducer;
