import {
  Checkbox,
  Chip,
  Badge,
  FormControlLabel,
  TextField,
  RadioGroup,
  Radio,
  FormGroup,
} from "@mui/material";
import { formStyle } from "../../../theming/formStyle";
import { Button, Col, FormControl, Row } from "react-bootstrap";
import { useDispatch, useSelector } from "react-redux";
import { AiFillDelete } from "react-icons/ai";
import { useCallback, useContext, useEffect, useState } from "react";
import {
  addNewPackage,
  removePackage,
  setModPrice,
  setPackageOptions,
  setPrepaid,
  setSelectedRateGeneric,
  setTempRate,
  updatePack,
} from "../../../store/slices/FreightRater/FreightRaterSlice";
import Table from "../../../components/atoms/Table/Table";
import { PREPAID_OPTIONS } from "../../../helpers/const";
import InputNumber from "../../../components/atoms/InputNumber";
import { AlertMessageContext } from "../../../components/atoms/AlertMessage/AlertMessage";
import { setFreight } from "../../../store/slices/freight/freightSlice";
import { setFreightMin } from "../../../store/slices/Credits/CreditSlice";

const FreightRaterPackage = (props) => {
  const {
    packageOptions,
    upsStatus,
    fedexStatus,
    packageResults,
    tempRate,
    modPrice,
    selectedRate,
    prepaid,
  } = useSelector((state) => state.freightRater);
  const { freightMinimum } = useSelector((state) => state.credits);

  const dispatch = useDispatch();
  const alertNotification = useContext(AlertMessageContext);

  const [handling, setHandling] = useState(0.0);
  const [handlingChecked, setHandlingChecked] = useState(false);
  const [freightAmount, setFreightAmount] = useState(0);
  const [prepaidChanged, setPrepaidChanged] = useState(false);
  const [modPriceHolder, setmodPriceHolder] = useState(modPrice);

  const originalFreight = parseFloat(props.order.freight.freight_amount);
  const [minFreight, setMinFreight] = useState(
    freightMinimum !== 0 ? freightMinimum : originalFreight * -1,
  );

  useEffect(() => {
    setFreightAmount(
      parseFloat(modPrice) + parseFloat(handling ? handling : 0),
    );
  }, [modPrice, handling, handlingChecked]);

  useEffect(() => {
    dispatch(setPackageOptions(props.items));
  }, [props.items]);

  useEffect(() => {
    if (tempRate) {
      const min =
        originalFreight * -1 +
        (tempRate && tempRate.rate ? parseFloat(tempRate.rate) : 0) * -2;
      setMinFreight(min);
      dispatch(setFreightMin(min));
    }
  }, [tempRate]);

  const updatePackages = (index, toUpdate, value) => {
    dispatch(updatePack({ index, toUpdate, value }));
  };

  const deletePackage = (index) => {
    dispatch(removePackage(index));
  };

  const addNewPackageDispatch = () => {
    dispatch(addNewPackage());
  };

  const selectRow = useCallback((id, data) => {
    dispatch(setTempRate(data));
    dispatch(setModPrice(-1 * data.rate));
  }, []);

  const handleSelect = () => {
    if (
      !selectedRate ||
      tempRate.service !== selectedRate.quote ||
      freightAmount !== selectedRate.customPrice ||
      prepaidChanged
    ) {
      setPrepaidChanged(false);
      dispatch(
        setSelectedRateGeneric({ quote: tempRate, customPrice: freightAmount }),
      );

      alertNotification.handleOpen(
        "success-savign-as-sub-order",
        "success",
        "Freight updated succesfully!",
        2000,
      );
    } else {
      alertNotification.handleOpen(
        "success-savign-as-sub-order",
        "warning",
        "Freight did not change, please make a different selection to perform this action.",
        2000,
      );
    }
  };

  const handleFreightChange = (val) => {
    let value = val;

    if (value > originalFreight) {
      value = originalFreight;
    } else if (value < minFreight) {
      value = minFreight;
    }

    dispatch(setModPrice(value));
  };

  return (
    <>
      <Row>
        <Col className="mt-5 pt-3">
          {packageOptions.map((item, index) => (
            <Row key={index} className="mb-4">
              <Col md="2" className="m-auto mb-4">
                PKG {index}
              </Col>
              <Col md="3">
                <TextField
                  label="Weight"
                  style={formStyle.inputStyle}
                  InputLabelProps={formStyle.inputLabelProps}
                  value={item.weight}
                  onChange={(e) =>
                    updatePackages(index, "weight", +e.target.value)
                  }
                  error={item.weight >= 150}
                  helperText={item.weight >= 150 ? "Cannot exceed 149" : ""}
                />
              </Col>
              <Col md="3">
                <TextField
                  label="D.V"
                  style={formStyle.inputStyle}
                  InputLabelProps={formStyle.inputLabelProps}
                  value={item.dv}
                  onChange={(e) => updatePackages(index, "dv", +e.target.value)}
                />
              </Col>
              <Col md="2">
                <FormControlLabel
                  control={
                    <Checkbox
                      sx={formStyle.checkboxStyle}
                      onChange={(e) =>
                        updatePackages(index, "boxed", e.target.checked)
                      }
                    />
                  }
                  label="Boxed"
                />
              </Col>
              <Col md="2" className="mt-3">
                <button
                  type="button"
                  onClick={() => deletePackage(index)}
                  className="package-delete-icon"
                >
                  <AiFillDelete />
                </button>
              </Col>
            </Row>
          ))}
          <div className="d-grid gap-2">
            <Button
              variant="outline-primary"
              size="sm"
              className="add-new-package-button"
              onClick={addNewPackageDispatch}
            >
              Add New Package
            </Button>
          </div>
        </Col>

        <Col>
          <div className="d-flex gap-4 mt-5 mb-2">
            <Badge color={upsStatus ? "success" : "error"} variant="dot">
              <Chip label="UPS" variant="outlined" />
            </Badge>
            <Badge color={fedexStatus ? "success" : "error"} variant="dot">
              <Chip label="FedEx" variant="outlined" />
            </Badge>
          </div>
          <div className="package-rater-options-popup__table">
            <Table
              selector="radio"
              headerAttributes={[
                {
                  id: "select",
                  label: "Select",
                },
                {
                  id: "postalService",
                  label: "PS",
                },
                {
                  id: "service",
                  label: "Service",
                },
                {
                  id: "rate",
                  label: "Rate",
                  sortable: true,
                  type: "number",
                },
                {
                  id: "days",
                  label: "Days",
                  sortable: true,
                  type: "number",
                },
                {
                  id: "goalDeliveryTarget",
                  label: "G D T",
                },
              ]}
              dataList={packageResults}
              hideId={true}
              selectedItem={tempRate?.id}
              onSelectRow={selectRow}
            />
          </div>
        </Col>

        {tempRate && (
          <Col md="12">
            <Row className="mt-4">
              <Col md="5">
                <RadioGroup
                  aria-labelledby="radio-buttons-group-label"
                  className="mt-1"
                  name="prepaidOption"
                >
                  <Row>
                    <Col>
                      <FormControlLabel
                        value={PREPAID_OPTIONS.PREPAID}
                        control={
                          <Checkbox
                            checked={prepaid === "Y"}
                            onChange={(e) => {
                              dispatch(setPrepaid(prepaid === "Y" ? "N" : "Y"));
                              setPrepaidChanged(true);
                            }}
                          />
                        }
                        label="Customer Pay Freight"
                      />
                    </Col>
                  </Row>
                </RadioGroup>
                <Row>
                  <Col>
                    <TextField
                      className="mt-3"
                      label="Freight Amount"
                      style={formStyle.inputStyle}
                      InputLabelProps={formStyle.inputLabelProps}
                      value={modPrice}
                      type="number"
                      helperText={`Min: ${
                        minFreight ? minFreight.toFixed(2) : 0
                      } Max: ${originalFreight} `}
                      onChange={(e) => handleFreightChange(e.target.value)}
                    />
                  </Col>
                </Row>
              </Col>

              <Col md="2" className="hand-rate">
                <Col>
                  <InputNumber
                    className="mt-4 hand-input"
                    label="Handling"
                    style={formStyle.inputStyle}
                    InputLabelProps={formStyle.inputLabelProps}
                    value={handling}
                    type="number"
                    onChange={(e) => setHandling(e.target.value)}
                  />
                </Col>

                <FormGroup>
                  <FormControlLabel
                    control={<Checkbox sx={formStyle.checkboxStyle} />}
                    label="Add Handling"
                    onChange={(e) => setHandlingChecked(e.target.checked)}
                  />
                </FormGroup>
              </Col>

              <Col md="2">
                <InputNumber
                  className="mt-4"
                  disabled
                  label="Total Shipping Handling"
                  style={formStyle.inputStyle}
                  InputLabelProps={formStyle.inputLabelProps}
                  value={freightAmount}
                />
              </Col>

              <Col md="2" className="d-flex justify-content-end">
                <Button
                  variant="secondary"
                  className="mt-3"
                  style={{ maxHeight: 48 }}
                  onClick={handleSelect}
                >
                  Apply
                </Button>
              </Col>
            </Row>
          </Col>
        )}
      </Row>
    </>
  );
};

export default FreightRaterPackage;
