import {
  EMAIL_REGEX_EXP,
  ORDER_ITEM_ADDON_TYPE,
  ORDER_ITEM_DESCRIPTION_TYPE,
} from "./const";

export function setCookie(cname, cvalue, exdays) {
  const d = new Date();
  d.setTime(d.getTime() + exdays * 24 * 60 * 60 * 1000);
  let expires = "expires=" + d.toUTCString();
  document.cookie = cname + "=" + cvalue + ";" + expires + ";path=/";
}

export function deleteCookie(name) {
  if (getCookie(name)) {
    document.cookie = name + "= " + ";expires=Thu, 01 Jan 1970 00:00:01 GMT";
  }
}
export function getCookie(cname) {
  let name = cname + "=";
  let decodedCookie = decodeURIComponent(document.cookie);
  let ca = decodedCookie.split(";");
  for (let i = 0; i < ca.length; i++) {
    let c = ca[i];
    while (c.charAt(0) == " ") {
      c = c.substring(1);
    }
    if (c.indexOf(name) == 0) {
      return c.substring(name.length, c.length);
    }
  }
  return "";
}

export function getOrderStatusLabel(status) {
  switch (status) {
    case -1:
      return "Quote";
    case 0:
      return "Pending";
    case 1:
      return "Approved";
    case 2:
      return "In Progress";
    case 3:
      return "Completed";
    case 4:
      return "Invoiced";
    case 5:
      return "Posted";
    default:
      return "Open";
  }
}

// used to create query urls from a query of objects might not be needed due to axios params functionality
export function urlFromObject(queryArray) {
  var s = "";
  queryArray.map((data) => {
    if (s != "") {
      s += "&";
    } else {
      s += "?";
    }
    s += data.key + "=" + data.value;
  });
  return s;
}

export const formatDateForDatePicker = (dateObj) => {
  const day = dateObj.$D < 10 ? "0" + dateObj.$D : dateObj.$D;
  const month = dateObj.$M + 1 < 10 ? "0" + (dateObj.$M + 1) : dateObj.$M + 1;
  return dateObj.$y + "-" + month + "-" + day;
};

export const isValidDate = (dateString) => {
  var regEx = /^\d{4}-\d{2}-\d{2}$/;
  if (!dateString.match(regEx)) return false; // Invalid format
  var d = new Date(dateString);
  var dNum = d.getTime();
  if (!dNum && dNum !== 0) return false; // NaN value, Invalid date
  return d.toISOString().slice(0, 10) === dateString;
};

/**
 * Function to cut a long username and set it to a username of 10 characters
 * @param {string} longUserName - long username
 * @returns {string}
 */
export const getShortUserName = (longUserName) => {
  if (longUserName) {
    if (longUserName.match(EMAIL_REGEX_EXP)) {
      const username = longUserName.split("@");
      return username[0].substring(0, 10).toUpperCase();
    }
    return longUserName.substring(0, 10);
  }
  return "";
};

/**
 * Function to validate if the value is a number, validate if it is a positive number
 * and validate if has a certain number of decimals
 * @param {string} value - number with string format
 * @param {number} decimals - amount o decimals to validate the value
 * @returns {boolean}
 */
export const validateNumber = (value, decimals) => {
  const number = Number(value);
  if (!isNaN(number)) {
    if (number > -1) {
      const decimalArray = number.toString().split(".");
      const isDecimal = decimalArray.length > 1 ? true : false;

      if (isDecimal) {
        if (decimalArray[1].length <= decimals) {
          return true;
        } else {
          return false;
        }
      }
      return true;
    }
    return false;
  }

  return false;
};

/**
 * Function to format a data object to XML format
 */
export const formatDataToXML = (data, indent = "\t") => {
  let xml = "";
  for (let key in data) {
    if (typeof data[key] === "object") {
      if (parseInt(key) >= 0) {
        xml += `${formatDataToXML(data[key], indent + "\t")}${indent}\n`;
      } else {
        xml += `${indent}<${key}>\n${formatDataToXML(
          data[key],
          indent + "\t",
        )}${indent}</${key}>\n`;
      }
    } else {
      xml += `${indent}<${key}>${data[key]}</${key}>\n`;
    }
  }
  return xml;
};

export const formatObjectToXML = (data, wrapper) => {
  let xml = `<?xml version="1.0" encoding="UTF-8"?>\n<${wrapper}>\n`;
  xml += formatDataToXML(data);
  xml += `</${wrapper}>`;
  return xml;
};

export const getDaysLabel = (num) => {
  if (num == 1) {
    return "Day";
  }

  return `Days`;
};

/**
 * Function to validate if the string consists only of digits
 * @param {string} str - string to be checked
 * @returns {boolean}
 */
export const isDigitsString = (str) => {
  return /^\d+$/.test(str);
};

export const makeQueryParams = (paramsObj) => {
  let params = "?";

  if (paramsObj) {
    Object.keys(paramsObj).forEach((key) => {
      if (Array.isArray(paramsObj[key])) {
        params += paramsObj[key].reduce(
          (prev, cur) =>
            prev + `&${encodeURIComponent(key)}=${encodeURIComponent(cur)}`,
          "",
        );
      } else if (paramsObj[key] !== null && paramsObj[key] !== undefined) {
        params += `&${encodeURIComponent(key)}=${encodeURIComponent(
          paramsObj[key],
        )}`;
      }
    });
  }

  return params === "?" ? "" : params;
};

export const validateItem = (item) => {
  const itemErrors = item.errors.map((i) => {
    if (
      !item.productCode &&
      item.itemType !== ORDER_ITEM_ADDON_TYPE &&
      item.itemType !== ORDER_ITEM_DESCRIPTION_TYPE &&
      i.name === "product"
    ) {
      return {
        ...i,
        error: true,
        message: "This field is required 7",
      };
    }

    if ((item.d === "none" || !item.d) && i.name === "d") {
      return {
        ...i,
        error: true,
        message: "This field is required 6",
      };
    }

    if (!item.tq && item.tq !== 0 && i.name === "tq") {
      return {
        ...i,
        error: true,
        message: "This field is required 5",
      };
    } else if ((item.tq || item.tq === 0) && i.name === "tq") {
      if (typeof item.tq === "number" && item.tq > 99) {
        return {
          ...i,
          error: true,
          message: "The value range should be between 0 and 99",
        };
      }
    }

    if (
      !item.qrd &&
      i.name === "qrd" &&
      ![ORDER_ITEM_DESCRIPTION_TYPE, ORDER_ITEM_ADDON_TYPE].includes(
        item.itemType,
      )
    ) {
      return {
        ...i,
        error: true,
        message: "This field is required or it has to be more than zero",
      };
    }

    if (!item.bo && item.bo !== 0 && i.name === "bo") {
      return {
        ...i,
        error: true,
        message: "This field is required 4",
      };
    }

    if (
      item.d === "S" &&
      !item.item_price &&
      item.item_price !== 0 &&
      i.name === "itemPrice"
    ) {
      return {
        ...i,
        error: true,
        message: "This field is required 3",
      };
    }

    if (
      item.itemType === ORDER_ITEM_DESCRIPTION_TYPE &&
      !item.description &&
      i.name === "description"
    ) {
      return {
        ...i,
        error: true,
        message: "This field is required 2",
      };
    }

    if (
      item.itemType === ORDER_ITEM_ADDON_TYPE &&
      !item.description &&
      i.name === "description"
    ) {
      return {
        ...i,
        error: true,
        message: "This field is required 1",
      };
    }

    return i;
  });

  return {
    ln: item.ln,
    wh: item.wh,
    errors: itemErrors,
  };
};

export const getItemsWithErrors = (itemsErrors) =>
  itemsErrors.filter((item) => !!item.errors.filter((i) => i.error).length);

export const validateWarehouse = (items, warehouseId) => {
  const warehouseDiffItem = items.find(
    (item) =>
      item.wh.id !== warehouseId &&
      ![ORDER_ITEM_ADDON_TYPE, ORDER_ITEM_DESCRIPTION_TYPE].includes(
        item.itemType,
      ),
  );

  return !!warehouseDiffItem;
};

/**
 * Recursively checks if two objects or arrays are deeply equal.
 *
 * @param {Object|Array} obj1 - The first object or array to compare.
 * @param {Object|Array} obj2 - The second object or array to compare.
 * @returns {boolean} - Returns true if both objects or arrays are deeply equal, otherwise false.
 */
export const deepEqual = (obj1, obj2) => {
  if (obj1 === obj2) return true;

  if (
    typeof obj1 !== "object" ||
    obj1 === null ||
    typeof obj2 !== "object" ||
    obj2 === null
  ) {
    return false;
  }

  if (Array.isArray(obj1) !== Array.isArray(obj2)) return false;

  const keys1 = Object.keys(obj1);
  const keys2 = Object.keys(obj2);

  if (keys1.length !== keys2.length) return false;

  for (let key of keys1) {
    if (!keys2.includes(key)) return false;
    if (!deepEqual(obj1[key], obj2[key])) return false;
  }

  return true;
};

/**
 * Recursively convert the error object to string.
 *
 * @param {Object} errors - The object of error message to be formatted
 */
export const formatErrorMessage = (errors, parentKey = "") => {
  const elements = [];

  for (const key in errors) {
    if (errors.hasOwnProperty(key)) {
      const errorMessages = errors[key];
      const fullKey = parentKey ? `${parentKey} -> ${key}` : key;

      if (Array.isArray(errorMessages)) {
        errorMessages.forEach((message) => {
          elements.push(
            <p key={`${fullKey}-${message}`}>{`${fullKey}: ${message}`}</p>,
          );
        });
      } else if (typeof errorMessages === "object" && errorMessages !== null) {
        elements.push(...formatErrorMessage(errorMessages, fullKey));
      }
    }
  }

  return elements;
};

export const extractIdFromRefUrl = (refUrl) => {
  // Use a regular expression to match the numeric ID at the end of the URL
  const match = refUrl.match(/\/(\d+)\/?$/);

  // If a match is found, return the ID as a number, otherwise return null
  return match ? Number(match[1]) : null;
};
