export const defaultClaim = {
  claim_num: 0, //
  customer: undefined, //
  claim_status: 0,
  ref_invoice: 0, //
  claim_amount: 0, // calc
  total_pieces: 0, // calc
  claim_reason: 0, //
  freight_claim_reason: 0, //F
  reported_issue: 0, //F
  driver_aware: "N", //F
  replacement_order_num: undefined, //
  credit_memo_num: undefined, //
  reported_by: "", //
  reported_by_phone: "", //
  reported_by_email: "", //
  received_credit: "N",
};

export const defaultClaimItem = {
  item_key: 0, //
  sequence_num: 0, //
  product_code: "", //
  description: "", //
  quantity: 0, //
  unit_cost: 0.0, //
  customer_price: 0.0, //
  unit_weight: 0, //
  total_weight: 0, //calc
  claim_amount: 0.0, //calc
  size: "", //
  serial_num: "", //
  date_of_failure: "2000-01-01", //
  install_date: "2000-01-01", //
  hours_in_use: 0, //
  equipment_make: "", //
  equipment_model: "", //
  hour_meter_install: 0, //
  hour_meter_failure: 0.0, //
  application_use: "", //
  use_level: 0, //
  average_load_weight: 0.0, //
  average_load_speed: 0.0, //
  notes: "", //
  original_quantity: 0, //
  problem_num: 8,
  operation_items: [],
};

export const defaultOperationItem = {
  claim_item: 0,
  sequence_num: 0,
  operation_type: 0,
  operation_comments: "",
};

export const defaultImage = {
  id: 0,
  parent_key: 0,
  image: null,
};
