import {
  Alert,
  FormGroup,
  InputLabel,
  MenuItem,
  Select,
  Switch,
  TextField,
} from "@mui/material";
import { useContext, useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Button, Card, Col, FormControl, Row, Spinner } from "react-bootstrap";
import "./SuggestedOrderReports.scss";
import { getSuggestedOrderReport } from "../../store/slices/Purchasing/PurchaseSlice";
import LoaderSpinner from "../../components/atoms/LoaderSpinner";
import { AlertMessageContext } from "../../components/atoms/AlertMessage/AlertMessage";

const SuggestedOrderReport = () => {
  const [vendorCategory, setVendorCategory] = useState();
  const [monthsNetSales, setMonthsNetSales] = useState(3); // Default 3, available values 1-24
  const [monthsToStock, setMonthsToStock] = useState(3); // Default 3, available values 1-12
  const [warehouseId, setWarehouseId] = useState(0); // Default 0, all active warehouses are available
  const [useDropShip, setUseDropShip] = useState(true); //default false, will exclude all drop ship addresses
  const [database, setDatabase] = useState("mitco-admin");

  const [showVendorCatError, setShowVendorCatError] = useState(true);
  const [showWarehouseError, setShowWarehouseError] = useState(false);
  const [showCalculateError, setShowCalculateError] = useState(false);
  const [showMonthsToOrder, setShowMonthsToOrderError] = useState(false);

  const alertNotification = useContext(AlertMessageContext);

  //redux
  const { purchaseLoading } = useSelector((state) => state.purchasing);

  const [modified, setModified] = useState(false);

  const [first, setFirst] = useState(false);

  const dispatch = useDispatch();

  const handleSubmit = () => {
    if (!vendorCategory || vendorCategory.length !== 2) {
      setShowVendorCatError(true);
    } else {
      setShowVendorCatError(false);
    }

    if (!monthsNetSales || monthsNetSales < 1) {
      setShowCalculateError(true);
    } else {
      setShowCalculateError(false);
    }

    if (!monthsToStock || monthsToStock < 1) {
      setShowMonthsToOrderError(true);
    } else {
      setShowMonthsToOrderError(false);
    }

    if (warehouseId === "") {
      setShowWarehouseError(true);
    } else {
      setShowWarehouseError(false);
    }

    setModified(!modified);
  };

  useEffect(() => {
    if (
      !showWarehouseError &&
      !showMonthsToOrder &&
      !showCalculateError &&
      !showVendorCatError &&
      first
    ) {
      dispatch(
        getSuggestedOrderReport({
          vendCat: vendorCategory,
          mthNet: monthsNetSales,
          mthOrder: monthsToStock,
          warehouse: warehouseId,
          dropShip: useDropShip ? "Y" : "N",
          database: database,
        }),
      ).then((response) => {
        if (!response?.error) {
          alertNotification.handleOpen(
            "success-savign-as-sub-order",
            "success",
            "Suggested Order Report downloaded succesfully!",
            5000,
          );
        }
      });
    } else {
      setFirst(true);
    }
  }, [modified]);
  const handleWarehouseChange = (value) => {
    const result = value.replace(/[^0-9]/g, "");
    setWarehouseId(result.slice(0, 2));
  };

  return (
    <>
      <LoaderSpinner loading={purchaseLoading} />
      <Card className="mt-5 sugg-card">
        <h2>Generate Suggested Order Report</h2>
        <Row className="sugg-alerts">
          {showVendorCatError && (
            <Alert severity="error" className="sugg-alert-body">
              There is an error with the Vendor Category.
            </Alert>
          )}
          {showWarehouseError && (
            <Alert severity="error" className="sugg-alert-body">
              There is an error with the Warehouse Id.
            </Alert>
          )}
          {showCalculateError && (
            <Alert severity="error" className="sugg-alert-body">
              There is an error with the Months to Calculate.
            </Alert>
          )}
          {showMonthsToOrder && (
            <Alert severity="error" className="sugg-alert-body">
              There is an error with the Months to Order
            </Alert>
          )}
        </Row>
        <Row>
          <Col className="sugg-forms">
            <div>
              <InputLabel id="sugg-vend-cat">Vendor Category</InputLabel>
              <TextField
                labelid="sugg-vend-cat"
                id="vendor_cat"
                variant="filled"
                onChange={(e) => setVendorCategory(e.target.value)}
                error={showVendorCatError}
              />
            </div>
            <div>
              <InputLabel id="sugg-vend-cat">Warehouse Number</InputLabel>
              <TextField
                labelid="sugg-vend-cat"
                id="warehouse_id"
                value={warehouseId}
                variant="filled"
                onChange={(e) => handleWarehouseChange(e.target.value)}
                error={showWarehouseError}
              />
            </div>
            <div>
              <InputLabel id="sugg-vend-cat"># of Months to Process</InputLabel>
              <TextField
                labelid="sugg-vend-cat"
                id="months_net_sales"
                variant="filled"
                value={monthsNetSales}
                onChange={(e) => setMonthsNetSales(e.target.value)}
                error={showCalculateError}
              />
            </div>
            <div>
              <InputLabel id="sugg-vend-cat"># of Months to Order</InputLabel>
              <TextField
                labelid="sugg-vend-cat"
                id="months_to_stock"
                variant="filled"
                value={monthsToStock}
                onChange={(e) => setMonthsToStock(e.target.value)}
                error={showMonthsToOrder}
              />
            </div>

            <div>
              <InputLabel id="sugg-select-label">Company</InputLabel>
              <Select
                labelid="sugg-select-label"
                label="Company"
                value={database}
                onChange={(e) => setDatabase(e.target.value)}
              >
                <MenuItem value="mitco-admin">Mitco</MenuItem>
                <MenuItem value="pro-tire">Protire</MenuItem>
              </Select>
            </div>
            <div>
              <InputLabel id="drop-select-label">Include Drop Ship</InputLabel>
              <Switch
                lableid="drop-select-label"
                label={"Include Drop Ship"}
                checked={useDropShip}
                onChange={(e) => setUseDropShip(e.target.checked)}
              />
            </div>
          </Col>
        </Row>
        <Row className="mt-5">
          <Col className="sugg-btn">
            <Button onClick={handleSubmit}>Generate Suggested Order</Button>
          </Col>
        </Row>
      </Card>
    </>
  );
};

export default SuggestedOrderReport;
