import { Card, Row, Col } from "react-bootstrap";
import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import FreightRater from "../../FreightRater/FreightRater";
import { RATER_TYPE } from "../../../helpers/const";
import {
  setModPrice,
  setPrepaid,
  setSelectedRateGeneric,
} from "../../../store/slices/FreightRater/FreightRaterSlice";

const CreditInputFreight = (props) => {
  const {
    selectedWarehouse,
    creditOrder,
    totalWeight,
    creditItems,
    dropShip,
    freightAmount,
    loadedCredit,
  } = useSelector((state) => state.credits);

  const dispatch = useDispatch();

  useEffect(() => {
    if (loadedCredit) {
      dispatch(setModPrice(freightAmount));
      dispatch(setPrepaid(loadedCredit.return_prepaid));
      dispatch(
        setSelectedRateGeneric({
          quote: {
            name: loadedCredit.freight_line.name,
          },
          customPrice: freightAmount,
        }),
      );
    }
  }, []);

  return (
    <>
      <Card hidden={!props.show}>
        <Card.Title className="bottom-border-seperator mb-0">
          Saved Freight Information
        </Card.Title>
        <Card.Body className="mb-2">
          {loadedCredit && (
            <Row className="mb-4">
              <Col>
                <b>Freight Amount:</b> {freightAmount}
              </Col>
              <Col>
                <b>Prepaid:</b>{" "}
                {loadedCredit.return_prepaid === "Y" ? "Yes" : "No"}
              </Col>
              <Col>
                <b>Freight Line:</b>{" "}
                {`${loadedCredit.freight_line.name} - (${loadedCredit.freight_line.freight_line_num}) `}
              </Col>
            </Row>
          )}
          <FreightRater
            disabled={props.disabled}
            show={!props.show}
            warehouse={selectedWarehouse}
            order={creditOrder}
            totalWeight={totalWeight}
            type={RATER_TYPE.CREDIT_RATER}
            items={creditItems}
            dropShip={dropShip}
            freightAmount={freightAmount}
          />
        </Card.Body>
      </Card>
    </>
  );
};

export default CreditInputFreight;
