import { Button, Card, Col, Row } from "react-bootstrap";
import { ClaimType, KEY_CODES } from "../../../helpers/const";
import { InputLabel, MenuItem, Select, TextField } from "@mui/material";
import { useDispatch, useSelector } from "react-redux";
import { useEffect, useState } from "react";
import LoaderSpinner from "../../../components/atoms/LoaderSpinner";
import {
  checkDuplicateClaims,
  getClaimById,
  getClaimImages,
  getInitialOrder,
  getProblemList,
  setClaimType,
  setRefInvoice,
} from "../../../store/slices/Claims/ClaimSlice";
import DuplicateClaimPopup from "../../../components/atoms/ClaimPopups/DuplicateClaimPopup";
import ConfirmStartCreditPopup from "../../../components/atoms/CreditPopups/ConfirmStartCreditPopup";
import { useParams } from "react-router";

const ClaimStart = ({ setClaimStarted }) => {
  const [showDuplicatePopup, setShowDuplicatePopup] = useState(false);
  const [showInitialOrderPopup, setShowInitialOrderPopup] = useState(false);

  //This could maybe be updated from redux, but this should be the only component we need this in so for now lets keep it here
  const [duplicateClaims, setDuplicateClaims] = useState([]);

  const { claim_type, initial_order, refInvoice } = useSelector(
    (state) => state.claims
  );
  const { loading } = useSelector((state) => state.loaderSpinner);
  const dispatch = useDispatch();

  const { id } = useParams();

  useEffect(() => {
    if (id) {
      dispatch(getClaimById(id)).then((response) => {
        dispatch(getClaimImages());
      });
    }
  }, [id]);

  // hooks
  useEffect(() => {
    // if duplciate claims.length > 1, this means we found a duplicate
    if (duplicateClaims.length > 0) {
      setShowDuplicatePopup(true);
    } else {
      handleGetInitialOrder();
    }
  }, [duplicateClaims]);

  useEffect(() => {
    if (initial_order) {
      setShowInitialOrderPopup(true);
    }
  }, [initial_order]);

  const onStartClick = () => {
    if (refInvoice.length >= 4) {
      dispatch(checkDuplicateClaims({ ref_invoice: refInvoice })).then(
        (response) => {
          if (!response?.error) {
            setDuplicateClaims(response.payload);
          }
        }
      );
    }
  };

  const beginClaim = () => {
    setShowInitialOrderPopup(false);
    setClaimStarted(true);
  };

  const handleGetInitialOrder = () => {
    if (refInvoice) {
      setShowDuplicatePopup(false);
      dispatch(getInitialOrder(refInvoice));
      dispatch(getProblemList());
    }
  };

  return (
    <>
      <LoaderSpinner loading={loading} />
      <DuplicateClaimPopup
        show={showDuplicatePopup}
        content={duplicateClaims}
        handleCancelEvent={() => setShowDuplicatePopup(false)}
        handleConfirmEvent={handleGetInitialOrder}
      />
      {initial_order && (
        <ConfirmStartCreditPopup
          doingClaim={true}
          show={showInitialOrderPopup}
          content={initial_order}
          handleCancelEvent={() => setShowInitialOrderPopup(false)}
          handleConfirmEvent={beginClaim}
        />
      )}
      <Card className="mt-5">
        <Card.Title className="bottom-border-seperator mb-5">
          Start Claim
        </Card.Title>
        <Card.Body className="pt-0">
          <Row className="entry-row-credit">
            <Col className="credit-row">
              <div>
                <InputLabel id="credit-field">Claim Type</InputLabel>
                <Select
                  labelid="credit-field"
                  onChange={(e) => dispatch(setClaimType(e.target.value))}
                  className="w-200"
                  size="small"
                  value={claim_type}
                >
                  {/* There are only three, but I used a map anyway so that the only places this has to be added is the const file */}
                  {ClaimType.map((type) => {
                    return (
                      <MenuItem key={type.id} value={type.id}>
                        {type.label}
                      </MenuItem>
                    );
                  })}
                </Select>
              </div>
              <div>
                {/* 
                    There is no validation done here, we only know if the invoice number is valid if the API responds without errors.
                    We do limit to only numeric digits, but this could technically have a decimal or negative, if this is the case, the API will reject it.
                */}
                <InputLabel id="inv-field">Invoice Number</InputLabel>
                <TextField
                  labelid="inv-field"
                  error={refInvoice.length < 4}
                  className="w-200"
                  size="small"
                  onKeyDown={(e) => {
                    if (e.keyCode === KEY_CODES.ENTER) {
                      onStartClick();
                    }
                  }}
                  onChange={(e) =>
                    dispatch(setRefInvoice(e.target.value.slice(0, 8)))
                  }
                  value={refInvoice}
                  type="number"
                />
              </div>
            </Col>
            <Col>
              <Button
                className="start-credit-btn"
                onClick={onStartClick}
                disabled={refInvoice.length < 4}
              >
                Start Claim
              </Button>
            </Col>
          </Row>
        </Card.Body>
      </Card>
    </>
  );
};

export default ClaimStart;
