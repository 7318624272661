import { useState } from "react";

import KeyboardDoubleArrowLeftIcon from "@mui/icons-material/KeyboardDoubleArrowLeft";
import KeyboardDoubleArrowRightIcon from "@mui/icons-material/KeyboardDoubleArrowRight";
import { AiFillDelete } from "react-icons/ai";

/**
 *
 * @param images all possible images that are not null
 * @param deleteAction this is defined in the parent, deletes image and decrements the counter
 * @param setShowSlideShow this is ued to update the slide show visibility, I do it here because null values are already screened out here.
 *                          This prevents a second filter from taking place in the parent.
 *
 * @returns
 */
const ImageSlider = ({ images, deleteAction, setShowSlideShow }) => {
  const [currImage, setCurrImage] = useState(images[0]);
  //state to manage a image showing full screen when a user clicks on it
  const [showLarge, setShowLarge] = useState(false);

  const nextImage = () => {
    let index = images.indexOf(currImage);
    if (index === images.length - 1) {
      index = 0;
    } else {
      index += 1;
    }
    setCurrImage(images[index]);
  };

  const prevImage = () => {
    let index = images.indexOf(currImage);
    if (index === 0) {
      index = images.length - 1;
    } else {
      index -= 1;
    }
    setCurrImage(images[index]);
  };

  const handleDelete = () => {
    const delImage = currImage;
    if (images.length - 1 !== 0) {
      nextImage();
    } else {
      setShowSlideShow(false);
    }

    deleteAction(delImage.id);
  };

  return (
    /**
     * This is one of the few places in the React App that does not use Mui as the primary style.
     * This is because there was a lot of custom CSS needing to be implemented and MUI can be difficult to override
     */
    <>
      <div style={{ width: "100%" }}>
        {currImage && currImage.image !== null && (
          <div className="image-slider">
            {/* Decrements your image location to the next */}
            <div className="arrow l-arrow" onClick={prevImage}>
              <KeyboardDoubleArrowLeftIcon />
            </div>
            {/* background for when a user clicks on an image to make it full screen */}
            {showLarge && (
              <div
                className="fixed-top bg-dark"
                style={{
                  "--bs-bg-opacity": ".5",
                  height: "100vh",
                  zIndex: 5000,
                }}
                onClick={() => setShowLarge(false)}
              />
            )}
            {/* This is the actual image display for both the slideshow and the large images */}
            <div
              className={
                "credit-image-container" +
                (showLarge
                  ? " large-image-container fixed-top"
                  : " not-large-image-container")
              }
              style={showLarge ? { height: "100vh" } : {}}
            >
              {/* image */}

              <img
                onClick={() => setShowLarge(!showLarge)}
                src={currImage.image}
                className={"credit-image"}
              />
              {/* delete image button */}
              <div
                className="delete-image"
                onClick={handleDelete}
                hidden={showLarge}
              >
                {" "}
                Delete Image <AiFillDelete />
              </div>
            </div>
            {/* Increments your image location to the next one */}
            <div className="arrow r-arrow" onClick={nextImage}>
              <KeyboardDoubleArrowRightIcon />
            </div>
          </div>
        )}
        {/* This is the gray dots below the image that shows which image is selected */}
        {currImage && currImage.image !== null && (
          <div className="image-tracker-container">
            {images.map((element) => {
              return (
                <div
                  className={
                    "image-tracker" +
                    (currImage === element ? " tracker-image-selected" : "")
                  }
                  onClick={() => setCurrImage(element)}
                ></div>
              );
            })}
          </div>
        )}
      </div>
    </>
  );
};

export default ImageSlider;
