import { TextField } from "@mui/material";
import { useEffect, useState } from "react";
import { Col, Row } from "react-bootstrap";

import AddIcon from "@mui/icons-material/Add";
import RemoveIcon from "@mui/icons-material/Remove";
import ClaimLine from "./ClaimLine";
import {
  addClaimItem,
  removeClaimItem,
} from "../../../../store/slices/Claims/ClaimSlice";
import { useDispatch, useSelector } from "react-redux";

const NormalClaimLine = ({ item, disabled }) => {
  const { claim_items } = useSelector((state) => state.claims);

  const [totalRemaining, setTotalRemaining] = useState(item.original_quantity);

  //hooks
  useEffect(() => {
    const list = claim_items.filter(
      (sc) => sc.product_code === item.product_code
    );
    let total_qty = 0;
    for (let i = 0; i < list.length; i++) {
      total_qty += list[i].quantity;
    }
    setTotalRemaining(item.original_quantity - total_qty);
  }, [claim_items]);

  const dispatch = useDispatch();

  const removeLastItem = () => {
    dispatch(removeClaimItem(item.product_code));
  };

  const addNewItem = () => {
    if (
      claim_items.filter((search) => search.product_code === item.product_code)
        .length < totalRemaining
    ) {
      const new_item = { ...item, item_key: Math.random() };
      dispatch(addClaimItem(new_item));
    }
  };

  return (
    <>
      <Row>
        <Col className="mt-5">
          <div className="info-header">Product Code</div>
          <TextField disabled className="w-100" value={item.product_code} />
        </Col>
        <Col className="mt-5">
          <div className="info-header">Description</div>
          <TextField disabled className="w-100" value={item.description} />
        </Col>
        <Col className="mt-5">
          <div className="info-header">Original Qty</div>
          <TextField
            disabled
            className="w-100"
            value={item.original_quantity}
          />
        </Col>
        <Col className="mt-5">
          <div className="info-header">Unit Weight</div>
          <TextField disabled className="w-100" value={item.unit_weight} />
        </Col>
        <Col className="mt-5">
          <div className="info-header">Item Price</div>
          <TextField disabled className="w-100" value={item.customer_price} />
        </Col>
        {/* This is the counter that tracks how many credit lines per line item on the original order */}
        <Col className="counter-row">
          <div
            onClick={(e) => (!disabled ? removeLastItem() : undefined)}
            className={"counter-btn" + (disabled ? " disabled" : "")}
          >
            <RemoveIcon />
          </div>
          <div className={disabled ? "disabled" : ""}>
            {
              claim_items.filter(
                (search) => search.product_code === item.product_code
              ).length
            }
          </div>
          <div
            onClick={(e) => (!disabled ? addNewItem() : undefined)}
            className={"counter-btn" + (disabled ? " disabled" : "")}
          >
            <AddIcon />
          </div>
        </Col>
      </Row>

      {claim_items
        .filter((sub) => sub.product_code === item.product_code)
        .map((sub_item) => {
          return (
            <ClaimLine
              key={sub_item.item_key}
              item={sub_item}
              totalRemaining={totalRemaining}
              disabled={disabled}
            />
          );
        })}
    </>
  );
};

export default NormalClaimLine;
