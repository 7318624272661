import { useEffect, useRef } from "react";
import { useDispatch, useSelector } from "react-redux";
import Modal from "react-bootstrap/Modal";
import { TextField } from "@mui/material";
import { Row, Col, Button } from "react-bootstrap";
import { AiOutlineSave } from "react-icons/ai";
import { useForm, Controller } from "react-hook-form";
import { formStyle } from "../../../theming/formStyle";
import { setThirdPartyBilling } from "../../../store/slices/freight/freightSlice";

function ThirdPartyBillingPopup({
  showPopup,
  setShowPopup,
  handleAutoSaveTriggerTrue,
}) {
  // Refs
  const nameInputRef = useRef();
  const addr1InputRef = useRef();
  const addr2InputRef = useRef();
  const cityInputRef = useRef();
  const stateInputRef = useRef();
  const zipCodeInputRef = useRef();
  const accountInputRef = useRef();
  const createButtonInputRef = useRef();

  // Redux
  const dispatch = useDispatch();
  const { thirdPartyBilling } = useSelector((state) => state.freight);

  // React hook form
  const { control, handleSubmit, setValue } = useForm();

  const onSubmit = (data) => {
    setShowPopup(false);
    dispatch(setThirdPartyBilling(data));
    handleAutoSaveTriggerTrue();
  };

  useEffect(() => {
    if (thirdPartyBilling.name) {
      setValue("name", thirdPartyBilling.name);
      setValue("addr1", thirdPartyBilling.addr1);
      setValue("addr2", thirdPartyBilling.addr2);
      setValue("city", thirdPartyBilling.city);
      setValue("state", thirdPartyBilling.state);
      setValue("account", thirdPartyBilling.account);
      setValue("zipCode", thirdPartyBilling.zipCode);
    } else {
      setValue("name", "");
      setValue("addr1", "");
      setValue("addr2", "");
      setValue("city", "");
      setValue("state", "");
      setValue("account", "");
      setValue("zipCode", "");
    }
  }, [thirdPartyBilling.name]);

  useEffect(() => {
    if (showPopup) {
      nameInputRef.current.focus();
    }
  }, [showPopup]);

  return (
    <Modal
      show={showPopup}
      size="lg"
      onHide={() => setShowPopup(false)}
      centered
      aria-labelledby="contained-modal-title-vcenter"
    >
      <Modal.Header closeButton className="p-5">
        <Modal.Title>
          <h3 className="h5">3rd Party Freight</h3>
        </Modal.Title>
      </Modal.Header>
      <Modal.Body className="p-5">
        <Row>
          <Col md={12}>
            <Controller
              name="name"
              control={control}
              rules={{
                required: "This field is required",
                maxLength: {
                  value: 60,
                  message: "This field cannot have more than 60 characters",
                },
                validate: (value) =>
                  value.trim() !== "" || "This Field can not be empty",
              }}
              render={({
                field: { onChange, value },
                fieldState: { error },
              }) => (
                <TextField
                  type="text"
                  label="Name"
                  name="name"
                  style={formStyle.inputStyle}
                  InputLabelProps={formStyle.inputLabelProps}
                  helperText={error ? error.message : null}
                  error={!!error}
                  onChange={onChange}
                  value={value}
                  inputRef={nameInputRef}
                  onKeyDown={(event) => {
                    if (event.key === "Enter") {
                      addr1InputRef.current.focus();
                    }
                  }}
                />
              )}
            />
          </Col>
          <Col md={12} className="mt-5">
            <Controller
              name="addr1"
              control={control}
              rules={{
                required: "This field is required",
                maxLength: {
                  value: 40,
                  message: "This field cannot have more than 40 characters",
                },
                validate: (value) =>
                  value.trim() !== "" || "This Field can not be empty",
              }}
              render={({
                field: { onChange, value },
                fieldState: { error },
              }) => (
                <TextField
                  type="text"
                  label="Address 1"
                  name="addr1"
                  style={formStyle.inputStyle}
                  InputLabelProps={formStyle.inputLabelProps}
                  helperText={error ? error.message : null}
                  error={!!error}
                  onChange={onChange}
                  value={value}
                  inputRef={addr1InputRef}
                  onKeyDown={(event) => {
                    if (event.key === "Enter") {
                      addr2InputRef.current.focus();
                    }
                  }}
                />
              )}
            />
          </Col>
          <Col md={12} className="mt-5">
            <Controller
              name="addr2"
              control={control}
              rules={{
                maxLength: {
                  value: 40,
                  message: "This field cannot have more than 40 characters",
                },
              }}
              render={({
                field: { onChange, value },
                fieldState: { error },
              }) => (
                <TextField
                  type="text"
                  label="Address 2"
                  name="addr2"
                  style={formStyle.inputStyle}
                  InputLabelProps={formStyle.inputLabelProps}
                  helperText={error ? error.message : null}
                  error={!!error}
                  onChange={onChange}
                  value={value}
                  inputRef={addr2InputRef}
                  onKeyDown={(event) => {
                    if (event.key === "Enter") {
                      cityInputRef.current.focus();
                    }
                  }}
                />
              )}
            />
          </Col>
        </Row>
        <Row className="mt-5">
          <Col>
            <Controller
              name="city"
              control={control}
              rules={{
                required: "This field is required",
                maxLength: {
                  value: 20,
                  message: "This field cannot have more than 20 characters",
                },
                validate: (value) =>
                  value.trim() !== "" || "This Field can not be empty",
              }}
              render={({
                field: { onChange, value },
                fieldState: { error },
              }) => (
                <TextField
                  type="text"
                  label="City"
                  name="city"
                  style={formStyle.inputStyle}
                  InputLabelProps={formStyle.inputLabelProps}
                  helperText={error ? error.message : null}
                  error={!!error}
                  onChange={onChange}
                  value={value}
                  inputRef={cityInputRef}
                  onKeyDown={(event) => {
                    if (event.key === "Enter") {
                      stateInputRef.current.focus();
                    }
                  }}
                />
              )}
            />
          </Col>
          <Col>
            <Controller
              name="state"
              control={control}
              rules={{
                required: "This field is required",
                maxLength: {
                  value: 2,
                  message: "This field cannot have more than 2 characters",
                },
                validate: (value) =>
                  value.trim() !== "" || "This Field can not be empty",
              }}
              render={({
                field: { onChange, value },
                fieldState: { error },
              }) => (
                <TextField
                  type="text"
                  label="State"
                  name="state"
                  style={formStyle.inputStyle}
                  InputLabelProps={formStyle.inputLabelProps}
                  helperText={error ? error.message : null}
                  error={!!error}
                  onChange={onChange}
                  value={value}
                  inputRef={stateInputRef}
                  onKeyDown={(event) => {
                    if (event.key === "Enter") {
                      zipCodeInputRef.current.focus();
                    }
                  }}
                />
              )}
            />
          </Col>
          <Col>
            <Controller
              name="zipCode"
              control={control}
              rules={{
                required: "This field is required",
                maxLength: {
                  value: 10,
                  message: "This field cannot have more than 10 characters",
                },
                validate: (value) =>
                  value.trim() !== "" || "This Field can not be empty",
              }}
              render={({
                field: { onChange, value },
                fieldState: { error },
              }) => (
                <TextField
                  type="text"
                  label="Zip Code"
                  name="zipCode"
                  style={formStyle.inputStyle}
                  InputLabelProps={formStyle.inputLabelProps}
                  helperText={error ? error.message : null}
                  error={!!error}
                  onChange={onChange}
                  value={value}
                  inputRef={zipCodeInputRef}
                  onKeyDown={(event) => {
                    if (event.key === "Enter") {
                      accountInputRef.current.focus();
                    }
                  }}
                />
              )}
            />
          </Col>
        </Row>
        <Row className="mt-5">
          <Col md="12">
            <Controller
              name="account"
              control={control}
              rules={{
                maxLength: {
                  value: 20,
                  message: "This field cannot have more than 20 characters",
                },
              }}
              render={({
                field: { onChange, value },
                fieldState: { error },
              }) => (
                <TextField
                  type="text"
                  label="Account Number"
                  name="account"
                  style={formStyle.inputStyle}
                  InputLabelProps={formStyle.inputLabelProps}
                  helperText={error ? error.message : null}
                  error={!!error}
                  onChange={onChange}
                  value={value}
                  inputRef={accountInputRef}
                  onKeyDown={(event) => {
                    if (event.key === "Enter") {
                      createButtonInputRef.current.focus();
                    }
                  }}
                />
              )}
            />
          </Col>
        </Row>
        <div className="d-flex justify-content-end align-items-center mt-5">
          <Button
            variant="primary"
            className="ms-5"
            onClick={handleSubmit(onSubmit)}
            ref={createButtonInputRef}
          >
            <span className="me-2">Create</span>
            <AiOutlineSave />
          </Button>
        </div>
      </Modal.Body>
    </Modal>
  );
}

export default ThirdPartyBillingPopup;
