import { InputLabel, MenuItem, Select, TextField } from "@mui/material";
import { Button, Card, Col, Row } from "react-bootstrap";
import {
  APPRAISAL,
  CREDIT_APPROVAL_STATUS,
  CREDIT_HOLD_STATUS,
  CREDIT_LINE_TYPE,
  CREDIT_TYPE,
  KEY_CODES,
  PENDING,
  STANDARD_CREDIT,
} from "../../helpers/const";
import { useContext, useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import "./CreditEntry.scss";
import {
  checkDuplicateCredit,
  createCredit,
  deleteCredit,
  doConfirmationAction,
  getActiveWarehouses,
  getCredit,
  getImages,
  getInitialOrder,
  getProblemList,
  setCreditTypeString,
} from "../../store/slices/Credits/CreditSlice";
import LoaderSpinner from "../../components/atoms/LoaderSpinner";
import DuplicateCreditPopup from "../../components/atoms/CreditPopups/DuplicateCreditPopup";
import ConfirmStartCreditPopup from "../../components/atoms/CreditPopups/ConfirmStartCreditPopup";
import CreditInputCustomer from "./CreditInputModule.js/CreditInputCustomer";
import CreditInputToggle from "./CreditInputModule.js/CreditInputToggle";
import CreditInputHeader from "./CreditInputModule.js/CreditInputHeader";
import CreditInputLineItems from "./CreditInputModule.js/CreditInputLineItems";
import CreditInputFields from "./CreditInputModule.js/CreditInputFields";
import CreditInputFreight from "./CreditInputModule.js/CreditInputFreight";
import { AlertMessageContext } from "../../components/atoms/AlertMessage/AlertMessage";
import { useParams } from "react-router";
import { endpoints } from "../../api/endpoints";
import SendConfirmationPopup from "../../components/atoms/CreditPopups/SendConfirmationPopup";

/**
 * This is the container of the Credit Entry application, each component can be accessed from here
 *
 * All CSS for this Component or ANY of its children can be found in either CreditInput.scss or CreditEntry.scss
 *
 * @returns Container for all credit entry components
 */
const CreditEntry = () => {
  const {
    creditLoading,
    creditOrder,
    creditType: credit_type,
    credit,
  } = useSelector((state) => state.credits);

  const [creditType, setCreditType] = useState(credit_type ? credit_type : 0);
  const [invoiceNum, setInvoiceNum] = useState("");
  const [creditStarted, setCreditStarted] = useState(false);

  const [duplicateList, setDuplicateList] = useState([]);

  const [invoiceError, setInvoiceError] = useState(false);
  const [mount, setMount] = useState(true);

  // States for managing visibility toggles
  const [showCustomerInfo, setShowCustomerInfo] = useState(false);
  const [showLineItems, setShowLineItems] = useState(true);
  const [showOptionalFields, steShowOptionsField] = useState(true);
  const [showFreight, setShowFreight] = useState(true);
  const [showSendConfirmationPopup, setShowSendConfirmationPopup] =
    useState(false);
  const [showDuplicatePopup, setShowDuplicatePopup] = useState(false);
  const [showOrderSummaryPopup, setShowOrderSummaryPopup] = useState(false);
  const [showSaveCreditPopup, setShowSaveCreditPopup] = useState(false);

  // This is a state that is treated as defined or undefined
  // it is used to manage the confirmation popup so that the popup knows if we just saved an order for the first time
  // or if we are just sending a confirmation of an already saved order.
  const [orderSaved, setOrderSaved] = useState();

  // NOTE: These are objects, not numbers. The number associated is accessed with {status}.id
  const [status, setStatus] = useState(PENDING);
  const [holdStatus, setHoldStatus] = useState(APPRAISAL);

  //This is used for the confirmation alerts such as "Credit saved succesfully!"
  const alertNotification = useContext(AlertMessageContext);

  // This is provided in the url, in this case the id IS the credit_memo_num
  const { id } = useParams();
  const dispatch = useDispatch();

  //If we already have a credit, set the status states above using that credit
  useEffect(() => {
    if (credit && credit.credit_memo_num && mount) {
      setStatus(
        CREDIT_APPROVAL_STATUS.filter(
          (stat) => stat.id === parseInt(credit.status),
        )[0],
      );
      setHoldStatus(
        CREDIT_HOLD_STATUS.filter(
          (stat) => stat.id === parseInt(credit.hold_status),
        )[0],
      );
      setMount(false);
    }
  }, [credit]);

  //Whenever a order is saved, immediately fetch that order and display it
  useEffect(() => {
    dispatch(getActiveWarehouses());
    dispatch(getProblemList());
    if (id) {
      dispatch(getCredit({ id }))
        .then((response) => {
          let count = 0;
          const tempItems = response.payload.credit.line_items;
          for (let i = 0; i < tempItems.length; i++) {
            const item = tempItems[i].item;
            if (parseInt(item.item_type) !== CREDIT_LINE_TYPE.DESCRIPTION) {
              dispatch(getImages({ id, item, inc: item.sequence_num }));
              count += 1;
            }
          }
        })
        .then((response) => {
          if (!response?.error) {
            setCreditStarted(true);
          }
        });
    }
  }, [id]);

  //If we just saved the order, trigger to show the confirmation popup. The values are just there to keep it from failing
  useEffect(() => {
    if (orderSaved) {
      handleSendConfirmation(1, 1, 1);
    }
  }, [orderSaved]);

  // Dispatch and confirmation logic for saving a credit. This logic does not change regardless of the status of the credit
  const handleSave = (save = true) => {
    if (save) {
      setShowSaveCreditPopup(false);
      dispatch(createCredit({ status, holdStatus })).then((response) => {
        if (!response?.error) {
          alertNotification.handleOpen(
            "success-savign-as-sub-order",
            "success",
            "Credit Created Successfully!",
            2000,
          );

          if (!credit.credit_memo_num) {
            setOrderSaved(response.payload.credit_memo_num);
          } else {
            setTimeout(() => {
              window.location.href = `${endpoints.CREDIT_ENTRY}/credit/${
                id ? id : orderSaved
              }`;
            }, 1000);
          }
        }
      });
    } else {
      setShowSaveCreditPopup(false);
    }
  };

  const handleDelete = () => {
    dispatch(deleteCredit()).then((response) => {
      if (!response?.error) {
        setTimeout(() => {
          window.location.href = `${endpoints.CREDIT_ENTRY}`;
        }, 1000);
        alertNotification.handleOpen(
          "success-savign-as-sub-order",
          "success",
          "Credit Deleted succesfully.",
          2000,
        );
      }
    });
  };

  //A restart event is triggered to send the used back to the "Start credit" screen so they can enter a different invoice number
  const handleRestartEvent = () => {
    window.location.href = endpoints.CREDIT_ENTRY + "/";
  };

  //We always load the order so that we can compare it if need be, if we do not find the order, the program will not progress.
  const getOrderForCredit = () => {
    setShowDuplicatePopup(false);
    dispatch(getInitialOrder({ invoiceNum })).then((response) => {
      if (!response?.error) {
        setShowOrderSummaryPopup(true);
        dispatch(getProblemList());
        dispatch(getActiveWarehouses());
      }
    });
  };

  //switches the display to show entire credit entry components rather than just the invoice number entry
  const beginCredit = () => {
    setCreditStarted(true);
    setShowOrderSummaryPopup(false);
  };

  //the first logic when start credit is clicked, if this throws an error, it is likely because the invoice number is invalid (There is an message displayed that says this)
  const onStartClick = () => {
    if (invoiceNum.length > 3) {
      setInvoiceError(false);
      dispatch(checkDuplicateCredit({ invoiceNum })).then((response) => {
        if (!response?.error) {
          const duplicates = response.payload;
          const hasDuplicates = duplicates.length > 0;

          if (hasDuplicates) {
            setDuplicateList(duplicates);
            setShowDuplicatePopup(true);
          } else {
            getOrderForCredit();
          }
        }
      });
    } else {
      setInvoiceError(true);
    }
  };

  //This handle the logic to send a confirmation/rga. The use_rga is provided to allow for just a confirmation to be generated
  const handleSendConfirmation = (method, email, use_rga, pass = false) => {
    if (orderSaved && !pass) {
      setShowSendConfirmationPopup(true);
    } else {
      dispatch(
        doConfirmationAction({
          method,
          email,
          credit_memo_num: id ? id : orderSaved,
          use_rga,
        }),
      ).then((response) => {
        if (!response.error) {
          alertNotification.handleOpen(
            "success-savign-as-sub-order",
            "success",
            `Credit ${method}ed successfully!`,
            2000,
          );
          setShowSendConfirmationPopup(false);
        }
      });
    }
  };

  //If we just saved the order and the confirmation popup just closed, redirect to the saved order
  useEffect(() => {
    if (!showSendConfirmationPopup && orderSaved) {
      setOrderSaved(undefined);
      setTimeout(() => {
        window.location.href = `${endpoints.CREDIT_ENTRY}/credit/${
          id ? id : orderSaved
        }`;
      }, 1000);
    }
  }, [showSendConfirmationPopup]);

  //This updates redux with both the credit type and the label so that we dont have to keep filtering it everywhere else
  useEffect(() => {
    dispatch(setCreditTypeString(creditType ? creditType : 0));
  }, [creditType]);

  return (
    <>
      {/* This is the loading circle, I used a custom creditLoading redux variable but there is also one in the loader spinner redux that can be used */}
      <LoaderSpinner loading={creditLoading} />
      {!creditStarted && (
        <>
          <Card className="mt-5">
            <Card.Title className="bottom-border-seperator mb-5">
              Start Credit
            </Card.Title>
            <Card.Body className="pt-0">
              <Row className="entry-row-credit">
                <Col className="credit-row">
                  <div>
                    <InputLabel id="credit-field">Credit Type</InputLabel>
                    <Select
                      labelid="credit-field"
                      onChange={(e) => setCreditType(e.target.value)}
                      className="w-200"
                      size="small"
                      value={creditType}
                    >
                      {/* There are only three, but I used a map anyway so that the only places this has to be added is the const file */}
                      {CREDIT_TYPE.map((type) => {
                        return (
                          <MenuItem key={type.id} value={type.id}>
                            {type.label}
                          </MenuItem>
                        );
                      })}
                    </Select>
                  </div>
                  <div>
                    {/* 
                                    There is no validation done here, we only know if the invoice number is valid if the API responds without errors.
                                    We do limit to only numeric digits, but this could technically have a decimal or negative, if this is the case, the API will reject it.
                                    */}
                    <InputLabel id="inv-field">Invoice Number</InputLabel>
                    <TextField
                      labelid="inv-field"
                      error={invoiceError}
                      className="w-200"
                      size="small"
                      onKeyDown={(e) => {
                        if (e.keyCode === KEY_CODES.ENTER) {
                          onStartClick();
                        }
                      }}
                      onChange={(e) => setInvoiceNum(e.target.value)}
                      value={invoiceNum}
                      inputProps={{ maxLength: 8 }}
                      type="number"
                    />
                  </div>
                </Col>
                <Col>
                  <Button className="start-credit-btn" onClick={onStartClick}>
                    Start Credit
                  </Button>
                </Col>
              </Row>
            </Card.Body>
          </Card>
          {duplicateList.length > 0 && (
            // This will not show if there are no duplicates
            <DuplicateCreditPopup
              show={showDuplicatePopup}
              content={duplicateList}
              handleCancelEvent={() => setShowDuplicatePopup(false)}
              handleConfirmEvent={getOrderForCredit}
            />
          )}
          {creditOrder && (
            //This shows immediately if there are no duplicates
            <>
              <ConfirmStartCreditPopup
                show={showOrderSummaryPopup}
                content={creditOrder}
                handleCancelEvent={() => setShowOrderSummaryPopup(false)}
                handleConfirmEvent={beginCredit}
              />
            </>
          )}
        </>
      )}

      {/* This is where the bulk of the credit entry component logic is located. 
            This will only display once the API has confirmed the user has provided a valid invoice. */}
      {creditStarted && (
        <>
          {/* This is the popup to send a confirmation or RGA */}
          <SendConfirmationPopup
            show={showSendConfirmationPopup}
            status={status ? status.id : 0}
            holdStatus={holdStatus ? holdStatus.id : 0}
            handleCancelEvent={() =>
              setShowSendConfirmationPopup(!showSendConfirmationPopup)
            }
            handleConfirmEvent={handleSendConfirmation}
          />
          {/* This the input header, it contains the following fields located just below the nav bar at the top of the page:
                        - Credit Type
                        - Credit Memo number
                        - Credit Item(s) Total
                        - Memo Total
                        - Status
                        
                        - 'Tools' dropdown
                        - 'Save' button
                    */}
          <CreditInputHeader
            disabled={credit && credit.status > 2}
            resetFunction={() => handleRestartEvent(!creditStarted)}
            creditMemoNum={id}
            saveFunction={setShowSaveCreditPopup}
            status={status}
            holdStatus={holdStatus}
            setStatus={setStatus}
            setHoldStatus={setHoldStatus}
            handleSave={handleSave}
            showSaveCreditPopup={showSaveCreditPopup}
            deleteFunction={handleDelete}
            setShowSendConfirmationPopup={setShowSendConfirmationPopup}
            creditType={creditType}
          />

          {/* 
                    This starts the information in the category labeled "Customer Information"
                     */}

          {/* 
                     The credit input toggle below is reused several times. 
                     This toggle is responsible for hiding and showing the more detailed information located in each section.
                     The states updated by this toggle are created above, notated by "visibility toggles"

                     The 'toggled' variable is the variable that is used to show or hide the associated component
                     */}
          <CreditInputToggle
            toggled={showCustomerInfo}
            toggleFunction={() => setShowCustomerInfo(!showCustomerInfo)}
            label={"Customer Information - " + creditOrder.customer.name}
          />
          {/* This Component contains the following information:
                        - Contact Information
                        - Billing Address
                        - Shipping Address
                        - Original Order Information
                        - Drop Ship Address
                        - Return Information
                    */}
          <CreditInputCustomer
            show={showCustomerInfo}
            disabled={credit && credit.status > 2}
          />

          <CreditInputToggle
            toggled={showOptionalFields}
            toggleFunction={() => steShowOptionsField(!showOptionalFields)}
            label={"Credit Information"}
          />
          {/* 
                    This component displays misc credit input information, this is where input that does not fit in the other categories should go.

                    The following fields are located here:
                        - Credit Reason
                        - Warehouse Selection
                        - Freight Credit Amount
                        - Handling Charge
                        - Credit Card Charge
                        - Surcharge
                        - Tax
                    */}
          <CreditInputFields
            show={showOptionalFields}
            disabled={credit && credit.status > 2}
            creditType={credit_type}
          />

          <CreditInputToggle
            toggled={showLineItems}
            toggleFunction={() => setShowLineItems(!showLineItems)}
            label={"Credit Line Items "}
          />
          {/* 
                        This is the line items and by far the most complex component chain, this will initially populate all line items from the original order. 
                        Then, logic is in place to allow a user to credit each of these items while not exceeding fields on the initial item.
                    */}
          <CreditInputLineItems
            show={showLineItems}
            disabled={credit && credit.status > 2}
          />

          {/* 
                        This is a messy logic chain, but essentialy, it will only be true if the credit is standard, 
                        there are some other factors in the CreditInputFreight component that will hide the freight rater under certain conditions.
                        See CreditInputFreight below for more notes regarding this.
                    */}
          {((credit_type === STANDARD_CREDIT.id &&
            creditType === STANDARD_CREDIT.id) ||
            credit_type === STANDARD_CREDIT.id) && (
            <>
              <CreditInputToggle
                toggled={showFreight}
                toggleFunction={() => setShowFreight(!showFreight)}
                label={"Return Freight"}
              />
              <CreditInputFreight
                show={showFreight}
                disabled={credit && credit.status > 2}
              />
            </>
          )}
        </>
      )}
    </>
  );
};

export default CreditEntry;
