import { useState, useEffect } from "react";
import Navigation from "../Navigation/Navigation";
import SubHeading from "../SubHeading/SubHeading";
import { useLocation } from "react-router-dom";
import "./Header.scss";
import { endpoints } from "../../../api/endpoints";
import CreditNavigation from "../Navigation/creditNavigation/creditNav";

function Header(props) {
  const location = useLocation();
  const [showHeader, setShowHeader] = useState(false);
  const [showOrderNav, setShowOrderNav] = useState(false);
  const [showCreditNav, setShowCreditNav] = useState(false);
  const [showInvoiceNav, setShowInvoiceNav] = useState(false);

  useEffect(() => {
    if (
      location.pathname === endpoints.ORDER_ENTRY + "" ||
      location.pathname.includes(endpoints.ORDER_ENTRY + "/order/") ||
      location.pathname.includes(endpoints.ORDER_ENTRY + "/quote/") ||
      location.pathname.includes(endpoints.ORDER_ENTRY + "/new-order/") ||
      location.pathname.includes(endpoints.ORDER_ENTRY + "/customer/")
    ) {
      setShowHeader(true);
    } else {
      setShowHeader(false);
    }

    if (location.pathname.includes(endpoints.ORDER_ENTRY)) {
      setShowOrderNav(true);
    } else {
      setShowOrderNav(false);
    }
  }, [location.pathname]);

  useEffect(() => {
    if (window.location.href.includes(endpoints.CREDIT_ENTRY + "/?new")) {
      window.location.href = endpoints.CREDIT_ENTRY + "/";
      setShowCreditNav(true);
    } else if (
      window.location.href.includes(
        endpoints.CREDIT_ENTRY + "/warranty-claim/?new"
      )
    ) {
      window.location.href = endpoints.CREDIT_ENTRY + "/warranty-claim/";
      setShowCreditNav(true);
    } else if (window.location.href.includes(endpoints.CREDIT_ENTRY)) {
      setShowCreditNav(true);
    } else {
      setShowCreditNav(false);
    }
  }, [window.location.href]);

  useEffect(() => {
    if (window.location.href.includes(endpoints.ACCOUNTS_RECEIVABLE_ENTRY)) {
      setShowInvoiceNav(true);
    } else {
      setShowInvoiceNav(false);
    }
  }, [window.location.href]);

  return (
    <header>
      <div className="sticky-top bg-light-white">
        <Navigation
          orderRoute={endpoints.ORDER_ENTRY}
          showOrderNav={showOrderNav}
          groups={props.groups}
          orderGroup={props.orderGroup}
          creditGroup={props.creditGroup}
          accountsReceivableGroup={props.accountsReceivableGroup}
          showCreditNav={showCreditNav}
          showInvoiceNav={showInvoiceNav}
          creditRoute={endpoints.CREDIT_ENTRY}
        />
      </div>
      <div className="bg-light-white">
        {showHeader && props.groups.includes(props.orderGroup) && (
          <SubHeading />
        )}
      </div>
    </header>
  );
}

export default Header;
