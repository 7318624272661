import { Button, Card, Col, Row, Table } from "react-bootstrap";
import { useDispatch, useSelector } from "react-redux";
import { ApiLogTransactionTypes } from "../../../helpers/const";
import {
  Paper,
  TableBody,
  TableCell,
  TableContainer,
  TableFooter,
  TableHead,
  TablePagination,
  TableRow,
  TextField,
} from "@mui/material";
import { useEffect, useState } from "react";
import { saveApiLog } from "../../../store/slices/Claims/ClaimSlice";

const ClaimLogs = ({ show, disabled }) => {
  const { claim_logs, claim } = useSelector((state) => state.claims);

  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(5);
  const [rows, setRows] = useState([...claim_logs]);

  const [newNote, setNewNote] = useState("");

  const dispatch = useDispatch();

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(+event.target.value);
    setPage(0);
  };

  const saveLog = () => {
    if (newNote.trim().length > 0) {
      dispatch(saveApiLog({ newNote }));
    }
  };

  useEffect(() => {
    setRows([...claim_logs].reverse());
  }, [claim_logs]);

  return (
    <div hidden={!show}>
      <Card>
        <Card className="mb-5">
          <Row>
            <Col className="w-100">
              <div className="info-header">Update Notes</div>
              <TextField
                disabled={disabled}
                className="w-100"
                helperText={80 - newNote.length + " / 80 Characters Remaining"}
                onChange={(e) => {
                  setNewNote(e.target.value);
                }}
                inputProps={{
                  maxLength: 80,
                }}
              />
            </Col>
            <Col className=" w-25 mt-5 ">
              <Button
                className="f-right"
                onClick={saveLog}
                disabled={newNote.trim().length === 0 || claim.claim_num === 0}
              >
                Add Update
              </Button>
            </Col>
          </Row>
        </Card>
        <TableContainer component={Paper}>
          <Table size="small">
            <TableHead>
              <TableRow>
                <TableCell style={{ width: "15%", textAlign: "center" }}>
                  <b>Action Type</b>
                </TableCell>
                <TableCell style={{ width: "55%" }}>
                  <b>Notes</b>
                </TableCell>
                <TableCell style={{ width: "10%" }}>
                  <b>Entered By</b>
                </TableCell>
                <TableCell style={{ width: "10%" }}>
                  <b>Date</b>
                </TableCell>
                <TableCell style={{ width: "10%" }}>
                  <b>Time</b>
                </TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {rows
                .slice(0 + rowsPerPage * page, rowsPerPage + rowsPerPage * page)
                .map((log) => {
                  return (
                    <TableRow key={log.transaction_key}>
                      <TableCell style={{ width: "15%", textAlign: "center" }}>
                        {ApiLogTransactionTypes.filter(
                          (option) => option.id === log.transaction_type
                        )[0].label + " :"}
                      </TableCell>
                      <TableCell style={{ width: "55%" }}>
                        {log.transaction_notes}
                      </TableCell>
                      <TableCell style={{ width: "10%" }}>
                        {log.entered_by}
                      </TableCell>
                      <TableCell style={{ width: "10%" }}>
                        {log.transaction_date}
                      </TableCell>
                      <TableCell style={{ width: "10%" }}>
                        {log.transaction_time}
                      </TableCell>
                    </TableRow>
                  );
                })}
            </TableBody>
            <TableFooter>
              <TableRow>
                <TablePagination
                  count={claim_logs.length}
                  page={page}
                  rowsPerPageOptions={[5, 10]}
                  rowsPerPage={rowsPerPage}
                  onPageChange={handleChangePage}
                  onRowsPerPageChange={handleChangeRowsPerPage}
                />
              </TableRow>
            </TableFooter>
          </Table>
        </TableContainer>
      </Card>
    </div>
  );
};

export default ClaimLogs;
