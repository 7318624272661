import { memo, useEffect } from "react";
import { Button, Modal } from "react-bootstrap";

const ConfirmationPopup = ({
  show,
  title,
  body,
  handleCancelEvent,
  handleConfirmEvent,
}) => {
  return (
    <Modal show={show} centered aria-labelledby="contained-modal-title-vcenter">
      <Modal.Header>
        <div>
          <b>{title}</b>
        </div>
      </Modal.Header>
      <Modal.Body>{body}</Modal.Body>
      <Modal.Footer>
        <Button variant={"danger"} onClick={handleCancelEvent}>
          Cancel
        </Button>
        <Button variant={"primary"} onClick={handleConfirmEvent}>
          Continue
        </Button>
      </Modal.Footer>
    </Modal>
  );
};

export default memo(ConfirmationPopup);
