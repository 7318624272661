/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect } from "react";
import { useSelector, useDispatch } from "react-redux";
import Card from "react-bootstrap/Card";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import Accordion from "react-bootstrap/Accordion";
import ARBalanceMessage from "../../atoms/ARBalanceMessage";
import {
  resetARBalance,
  getCustomerAccountBalance,
} from "../../../store/slices/aRBalance/aRBalanceSlice";

import CreditLimit from "../../atoms/CreditLimit";

import "./ARBalance.scss";

const ARBalance = () => {
  // Redux
  const dispatch = useDispatch();
  const {
    customerData: { id: customerId, creditLimit },
  } = useSelector((state) => state.customer);
  const {
    subOrder: { subOrderId },
  } = useSelector((state) => state.subOrder);
  const {
    periodsState: { total, period0, period1, period2, period3, period4 },
  } = useSelector((state) => state.aRBalance);

  useEffect(() => {
    if (customerId > 0) {
      dispatch(getCustomerAccountBalance({ customerId, subOrderId }));
    } else {
      dispatch(resetARBalance());
    }
  }, [customerId, subOrderId]);

  return (
    <div className="ARBalance" id="ARBalance">
      <Card className="mb-5">
        <Card.Body>
          <Accordion defaultActiveKey="0">
            <Accordion.Item eventKey="0">
              <Accordion.Header>
                <Row style={{ width: "100%" }}>
                  <Col sm={5}>
                    <Card.Title>A/R Balance</Card.Title>
                  </Col>
                  <Col sm={6} className="my-auto">
                    <ARBalanceMessage />
                  </Col>
                </Row>
              </Accordion.Header>
              <Accordion.Body>
                <Row>
                  <Col>
                    <b>Total</b>{" "}
                    <span className="text-primary text-start info">
                      {total}
                    </span>
                  </Col>
                  <Col>
                    <b>0-30</b>{" "}
                    <span className="text-primary text-start info">
                      {period0}
                    </span>
                  </Col>
                </Row>
                <Row>
                  <Col>
                    <b>31-60</b>{" "}
                    <span className="text-primary text-start info">
                      {period1}
                    </span>
                  </Col>
                  <Col>
                    <b>67-90</b>{" "}
                    <span className="text-primary text-start info">
                      {period2}
                    </span>
                  </Col>
                </Row>
                <Row>
                  <Col>
                    <b>91-120</b>{" "}
                    <span className="text-primary text-start info">
                      {period3}
                    </span>
                  </Col>
                  <Col>
                    <b>&gt;120</b>{" "}
                    <span className="text-primary text-start info">
                      {period4}
                    </span>
                  </Col>
                </Row>
              </Accordion.Body>
            </Accordion.Item>
          </Accordion>

          <CreditLimit creditLimit={creditLimit} />
        </Card.Body>
      </Card>
    </div>
  );
};

export default ARBalance;
