import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import { setCustomerData } from "../customer/customerSlice";
import { getSubOrder, resetSubOrderId } from "../subOrderSlice";
import { formattAccountBalanceInfo } from "./aRBalance";
import { apiGet } from "../../../api/apiGet";
import { AR_BALANCE_NONE_STATUS, CREDIT_STATUS } from "../../../helpers/const";

export const getCustomerAccountBalance = createAsyncThunk(
  "aRBalance/customerAccountBalance",
  async ({ customerId, subOrderId }, thunkAPI) => {
    try {
      const storeStates = thunkAPI.getState();
      const {
        customer: {
          customerData: { allowCreditCheck, aRBalance, creditLimit, terms },
        },
      } = storeStates;

      const accountBalanceInfo =
        await apiGet.customerAccountBalance(customerId);

      const creditCheck = await apiGet.customerCreditCheck({
        customerId,
        subOrderId,
      });

      return {
        checkCustomerCredit: allowCreditCheck,
        customerARBalance: aRBalance,
        customerCreditLimit: creditLimit,
        customerTerms: terms,
        periodsState: formattAccountBalanceInfo(accountBalanceInfo),
        creditStatus: creditCheck.credit_status,
      };
    } catch (err) {
      return thunkAPI.rejectWithValue({
        axiosError: err,
        customMsg: "Error getting the AR information.",
      });
    }
  },
);

const initialState = {
  periodsState: {
    total: 0,
    period0: 0,
    period1: 0,
    period2: 0,
    period3: 0,
    period4: 0,
  },
  standingMessage: "",
  standingStatus: AR_BALANCE_NONE_STATUS,
  subOrderinfo: {
    subOrderId: 0,
    totalApproved: 0,
    total: 0,
    terms: "",
  },
  contextExecution: "customer",
};

export const aRBalanceSlice = createSlice({
  name: "ARBalanceSlice",
  initialState,
  reducers: {
    resetARBalance: () => {
      return initialState;
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(getSubOrder.fulfilled, (state, action) => {
        state.contextExecution = "subOrder";
        state.subOrderinfo.totalApproved =
          action.payload.subOrderData.total_approved;
        state.subOrderinfo.total = action.payload.subOrderData.total;
        state.subOrderinfo.terms = action.payload.subOrderData.terms;
      })
      .addCase(resetSubOrderId, (state) => {
        state = initialState;
      })
      .addCase(setCustomerData, (state) => {
        state.contextExecution = "customer";
      })
      .addCase(getCustomerAccountBalance.fulfilled, (state, action) => {
        state.periodsState = action.payload.periodsState;
        state.standingMessage =
          CREDIT_STATUS[action.payload.creditStatus]?.message;
        state.standingStatus =
          CREDIT_STATUS[action.payload.creditStatus]?.status;
      });
  },
});

export const { resetARBalance } = aRBalanceSlice.actions;
export default aRBalanceSlice.reducer;
