import { Button, Card } from "react-bootstrap";
import LineItem from "./LineItem/LineItem";
import { useDispatch, useSelector } from "react-redux";
import DescriptionCreditLine from "./LineItem/DescriptionLineItem";
import { Alert } from "@mui/material";
import {
  CREDIT_LINE_TYPE,
  ORDER_ITEM_ADDON_TYPE,
  ORDER_ITEM_DESCRIPTION_TYPE,
} from "../../../helpers/const";
import { useEffect } from "react";
import {
  addNonSale,
  setTotalWeight,
} from "../../../store/slices/Credits/CreditSlice";
import NonSale from "./LineItem/NonSale";

/**
 *
 * @param show boolean that is toggled in parent to display or hide this component {it is inversed below to make this component visible by default}
 * @param disabled boolean defined in parent that determines if the order is editable or not
 *
 * @returns
 */
const CreditInputLineItems = ({ show, disabled }) => {
  const { displayItems, creditItems } = useSelector((state) => state.credits);

  const dispatch = useDispatch();

  //I was having issues maintaining the weight in the children, even though it is stored in redux
  //This just recalculates the weight any time the amount of credit items change
  useEffect(() => {
    let totalWeight = 0;
    for (let item of creditItems) {
      if (item.key) {
        totalWeight += item.weight * item.return;
      }
    }
    dispatch(setTotalWeight(totalWeight));
  }, [creditItems]);

  return (
    <>
      <Card className="pl-5" hidden={!show}>
        <Card.Body>
          {displayItems.map((item, index) => {
            /**
             * This map currently has two conditionals that lead to two seperate components. I do not store the line_num in a description item.
             * Therefore, if line_num exists, we can assume the item is a acceptable normal item
             * otherwise, it should be a description item. We also have to screen out add on items because even though
             * credits never have add ons, this is actually displaying items from the original order, which could very well have addons
             */
            return (
              <section key={index}>
                {item.line_num &&
                  ![
                    ORDER_ITEM_ADDON_TYPE,
                    ORDER_ITEM_DESCRIPTION_TYPE,
                    CREDIT_LINE_TYPE.NON_SALE,
                  ].includes(parseInt(item.item_type)) && (
                    <div>
                      <LineItem
                        disabled={disabled}
                        lineNum={item.line_num}
                        productCode={
                          item.product && item.product.product_code
                            ? item.product.product_code
                            : item.product_code
                        }
                        description={item.description}
                        qtyShipped={item.qty_shipped}
                        itemPrice={parseFloat(item.item_price)}
                        price={item.price}
                        weight={item.weight / item.qty_shipped}
                        itemType={item.item_type}
                      />
                    </div>
                  )}
                {item.item_type === CREDIT_LINE_TYPE.NON_SALE && (
                  <NonSale item={item} disabled={disabled} />
                )}
                {item.type && !item.item_type && (
                  <div>
                    <DescriptionCreditLine
                      disabled={disabled}
                      description={item.description ? item.description : ""}
                      parentId={item.parentId}
                      sequenceNum={item.sequence_num}
                    />
                  </div>
                )}
              </section>
            );
          })}
        </Card.Body>
        <Card.Footer className="center">
          <Button onClick={() => dispatch(addNonSale())}>
            Add Non-Sale Line Item
          </Button>
        </Card.Footer>
      </Card>
    </>
  );
};

export default CreditInputLineItems;
