/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useState, useCallback } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Link } from "react-router-dom";
import { getCustomersList } from "../../store/slices/customer/customerSlice";
import Heading from "../../components/sections/Heading/Heading";
import DataGrid from "../../components/atoms/Table/DataGrid";
import LoaderSpinner from "../../components/atoms/LoaderSpinner";
import { endpoints } from "../../api/endpoints";

const headerAttributes = [
  {
    id: "customer_num",
    label: "Customer #",
    tooltip: "Customer ID",
    searchable: {
      label: "Search by Customer ID",
      type: "text",
      width: 2,
    },
    sortable: true,
    width: "5%",
  },
  {
    id: "name",
    label: "Customer Name",
    searchable: {
      label: "Search by Customer Name",
      type: "text",
      width: 2,
    },
    sortable: true,
    width: "20%",
  },
  {
    id: "city",
    label: "",
    searchable: {
      label: "Search by Shipping City",
      type: "text",
      width: 2,
    },
    hidden: true,
  },
  {
    id: "state",
    label: "",
    searchable: {
      label: "Search by Shipping State",
      type: "text",
      width: 2,
    },
    hidden: true,
  },
  {
    id: "shippingAdressCombo",
    label: "Shipping Address",
  },
  {
    id: "billingAdressCombo",
    label: "Billing Address",
  },

  {
    id: "network_name",
    label: "Aftermarket",
    searchable: {
      label: "Search by Aftermarket",
      type: "text",
      width: 2,
    },
    sortable: true,
  },
  {
    id: "itemXRefCode",
    label: "XCrossRef",
  },
  {
    id: "serviceComments",
    label: "Comments",
  },
  {
    id: "new",
    label: "New Order",
    width: "5%",
  },
];

const Customers = () => {
  const dispatch = useDispatch();

  const { total, data } = useSelector((state) => state.customer.customers);
  const { loading } = useSelector((state) => state.loaderSpinner);

  const [dataList, setDataList] = useState([]);

  useEffect(() => {
    setDataList(
      data.map((customer) => ({
        id: customer.id,
        customer_num: (
          <Link to={endpoints.ORDER_ENTRY + `/customers/${customer.id}`}>
            {customer.id}
          </Link>
        ),
        name: customer.name,
        shippingAddressCombo: customer.shippingAddressCombo,
        billingAdressCombo: customer.billingAdressCombo,
        network_name: customer.aftermarketId ? (
          <Link
            to={endpoints.ORDER_ENTRY + `/customers/${customer.aftermarketId}`}
          >
            {customer.aftermarketName}
          </Link>
        ) : (
          ""
        ),
        itemXRefCode: customer.itemXRefCode,
        serviceComments: customer.comments
          ? customer.comments.split("\n").map((str) => <p>{str}</p>)
          : "",
        new: (
          <Link
            to={endpoints.ORDER_ENTRY + `/customer/${customer.id}/new-order/`}
          >
            Create
          </Link>
        ),
      }))
    );
  }, [data]);

  const fetchCustomers = useCallback(async (params) => {
    dispatch(getCustomersList(params));
  }, []);

  const prepareFetchCustomers = useCallback(
    async ({ page = 0, orderBy, sortOrder, customParameters }) => {
      const filter = customParameters?.reduce(
        (prev, cur) => ({
          ...prev,
          [cur.id]: cur.value,
        }),
        {}
      );

      await fetchCustomers({
        page: page + 1,
        order_by: orderBy,
        sort_order: sortOrder === "desc" ? "dsc" : "asc",
        ...filter,
      });
    },
    [fetchCustomers]
  );

  return (
    <div>
      <Heading title="Customer List" />
      <div className="mt-5">
        <div className="mt-5">
          <DataGrid
            headerAttributes={headerAttributes}
            dataList={dataList}
            hideId={true}
            rowsPerPage={50}
            total={total}
            defaultOrder="desc"
            defaultOrderBy="customer_num"
            fetchData={prepareFetchCustomers}
          ></DataGrid>
        </div>
      </div>
      <LoaderSpinner loading={loading} />
    </div>
  );
};

export default Customers;
