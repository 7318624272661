import { createAsyncThunk, createSlice, current } from "@reduxjs/toolkit";
import { apiGet } from "../../../api/apiGet";
import { defaultClaim, defaultClaimItem } from "../../../api/models/ClaimModel";
import { ORDER_ITEM_INVENTORY_TYPE } from "../../../helpers/const";
import { apiPost } from "../../../api/apiPost";
import { apiPut } from "../../../api/apiPut";
import { apiDelete } from "../../../api/apiDelete";

import { unzip } from "unzipit";
import JSZip from "jszip";

export const checkDuplicateClaims = createAsyncThunk(
  "claims/checkDuplicateClaims",
  async ({ ref_invoice }, thunkAPI) => {
    try {
      const response = await apiGet.checkDuplicateClaims({ ref_invoice });
      return response.data;
    } catch (err) {
      return thunkAPI.rejectWithValue({
        axiosError: err,
        customMsg: "Error checking duplicates, the invoice number is invalid.",
      });
    }
  }
);

export const getInitialOrder = createAsyncThunk(
  "claims/getInitialOrder",
  async (ref_invoice, thunkAPI) => {
    try {
      const response = await apiGet.orderByInvoice(ref_invoice);
      return response.data;
    } catch (err) {
      return thunkAPI.rejectWithValue({
        axiosError: err,
        customMsg: "Error getting the initial order.",
      });
    }
  }
);

export const getClaimById = createAsyncThunk(
  "claims/getClaimById",
  async (claim_num, thunkAPI) => {
    try {
      const response = await apiGet.getClaimByClaimNumber(claim_num);
      const claim = response.data;
      const order = await apiGet.orderByInvoice(claim.ref_invoice);
      const problems = await apiGet.problemList();
      return {
        claim,
        order: order.data,
        problems: problems.data,
      };
    } catch (err) {
      return thunkAPI.rejectWithValue({
        axiosError: err,
        customMsg: "Error getting the initial order.",
      });
    }
  }
);

export const getProblemList = createAsyncThunk(
  "claims/problems",
  async (thunkAPI) => {
    try {
      const response = await apiGet.problemList();
      return response.data;
    } catch (err) {
      return thunkAPI.rejectWithValue({
        axiosError: err,
        customMsg: "Error checking duplicates.",
      });
    }
  }
);

export const saveClaim = createAsyncThunk(
  "claims/save",
  async (_, thunkAPI) => {
    try {
      const storeStates = thunkAPI.getState();
      const {
        claims: { claim, claim_items, claim_type },
      } = storeStates;

      let { original_quantity, customer, ...claim_temp } = claim;
      const new_claim = {
        ...claim_temp,
        claim_type: claim_type,
        customer_num: claim.customer.customer_num,
      };

      const response = await apiPost.saveClaim({
        claim: new_claim,
        claim_items,
      });

      const key_pairs = response.data.keys;

      return {
        claim: response.data.claim,
        key_pairs,
      };
    } catch (err) {
      return thunkAPI.rejectWithValue({
        axiosError: err,
        customMsg: "Error saving the claim.",
      });
    }
  }
);

export const updateClaim = createAsyncThunk(
  "claims/updateClaim",
  async (_, thunkAPI) => {
    try {
      const storeStates = thunkAPI.getState();
      const {
        claims: { claim, claim_items, claim_type, claim_images },
      } = storeStates;

      let { original_quantity, customer, ...claim_temp } = claim;
      const new_claim = {
        ...claim_temp,
        claim_type: claim_type,
        customer_num: claim.customer.customer_num,
      };

      const response = await apiPut.updateClaim({
        claim: new_claim,
        claim_items,
      });

      const key_pairs = response.data.keys;

      return {
        key_pairs,
      };
    } catch (err) {
      return thunkAPI.rejectWithValue({
        axiosError: err,
        customMsg: "Error saving the claim.",
      });
    }
  }
);

export const deleteClaim = createAsyncThunk(
  "claims/delete",
  async (_, thunkAPI) => {
    try {
      const storeStates = thunkAPI.getState();
      const {
        claims: { claim },
      } = storeStates;

      const response = await apiDelete.deleteClaim(claim.claim_num);
    } catch (err) {
      return thunkAPI.rejectWithValue({
        axiosError: err,
        customMsg: "Error deleting the claim.",
      });
    }
  }
);

export const undoDeleteClaim = createAsyncThunk(
  "claims/undoDelete",
  async (_, thunkAPI) => {
    try {
      const storeStates = thunkAPI.getState();
      const {
        claims: { claim },
      } = storeStates;

      const response = await apiPut.undoDelete(claim.claim_num);
    } catch (err) {
      return thunkAPI.rejectWithValue({
        axiosError: err,
        customMsg: "Error deleting the claim.",
      });
    }
  }
);

export const getClaimsBYFilter = createAsyncThunk(
  "credit/claimsByFilter",
  async (
    {
      credit_memo_num,
      claim_num,
      ref_invoice,
      to,
      status,
      from,
      selected,
      page,
      order_by,
      sort_order,
      show_deleted,
    },
    thunkAPI
  ) => {
    try {
      const response = await apiGet.getClaims({
        credit_memo_num,
        claim_num,
        ref_invoice,
        to,
        status,
        from,
        selected,
        page,
        order_by,
        sort_order,
        show_deleted,
      });
      return response.data;
    } catch (err) {
      return thunkAPI.rejectWithValue({
        axiosError: err,
        customMsg: "Error loading the claims.",
      });
    }
  }
);

export const saveApiLog = createAsyncThunk(
  "claims/saveApiLog",
  async ({ newNote }, thunkAPI) => {
    try {
      const storeStates = thunkAPI.getState();
      const {
        claims: {
          claim: { claim_num },
        },
      } = storeStates;
      const response = await apiPost.addApiLog({ newNote, claim_num });
      return response.data;
    } catch (err) {}
  }
);

export const saveClaimImages = createAsyncThunk(
  "claims/saveImages",
  async (_, thunkAPI) => {
    try {
      const storeStates = thunkAPI.getState();
      const {
        claims: {
          claim_images,
          claim: { claim_num },
          last_load_image_keys,
        },
      } = storeStates;
      const images_to_save = claim_images.filter(
        (image) => image.image !== null
      );

      for (let i = 0; i < images_to_save.length; i++) {
        const curr_image = images_to_save[i];
        const img = await fetch(curr_image.image);
        const blob = await img.blob();
        images_to_save[i] = {
          ...curr_image,
          image: new File([blob], claim_num + ".JPEG", {
            type: blob.type,
          }),
        };
      }

      if (images_to_save.length > 0) {
        const response = await apiPost.uploadClaimImages({
          last_load_image_keys,
          images: images_to_save,
          claim_num,
        });
      }
    } catch (err) {}
  }
);

export const getClaimImages = createAsyncThunk(
  "claims/getClaimImages",
  async (_, thunkAPI) => {
    try {
      const storeStates = thunkAPI.getState();
      const {
        claims: {
          claim_items,
          claim: { claim_num },
        },
      } = storeStates;

      let item_keys = [];
      for (let i = 0; i < claim_items.length; i++) {
        item_keys.push(claim_items[i].item_key);
      }

      const response = await apiGet.getClaimImages({ claim_num, item_keys });
      const zip = await unzip(response.data);
      let ret_images = [];
      for (const entry of Object.values(zip.entries)) {
        const blob = await zip.entries[entry.name].blob("image/jpg");
        const url = URL.createObjectURL(blob);
        const parent_key = parseInt(entry.name.split("_")[1]);
        const id = entry.name.split(".")[0];
        const image = {
          id,
          parent_key,
          image: url,
        };
        ret_images.push(image);
      }

      return ret_images;
    } catch (err) {}
  }
);

export const convertToCredit = createAsyncThunk(
  "claims/convertToCredit",
  async (_, thunkAPI) => {
    try {
      const storeStates = thunkAPI.getState();
      const {
        claims: { claim, claim_items },
      } = storeStates;

      const response = await apiPost.convertClaimToCredit({
        claim,
        claim_items,
      });

      return response.data.credit_memo_num;
    } catch (err) {
      return thunkAPI.rejectWithValue({
        axiosError: err,
        customMsg: "Error converting the claim.",
      });
    }
  }
);

export const sendConfirmation = createAsyncThunk(
  "claims/sendConfirmation",
  async ({ method, email }, thunkAPI) => {
    try {
      const storeStates = thunkAPI.getState();
      const {
        claims: {
          claim: { claim_num },
        },
      } = storeStates;

      const response = await apiGet.sendClaimSummary({
        claim_num,
        method,
        email,
      });

      return response.data.credit_memo_num;
    } catch (err) {
      return thunkAPI.rejectWithValue({
        axiosError: err,
        customMsg: "Error sending claim sumarry.",
      });
    }
  }
);

export const ClaimSlice = createSlice({
  name: "ClaimSlice",
  initialState: {
    claim_type: 0,
    initial_order: undefined,
    claim: undefined,
    claim_items: [],
    possible_items: [],
    problem_list: [],
    refInvoice: "",
    claim_logs: [],
    claim_images: [],
    last_load_image_keys: [],
  },
  reducers: {
    updateClaimImage: (state, action) => {
      const key = action.payload.key;
      const image = action.payload.image;

      const current_item = state.claim_images.filter(
        (image) => image.id === key
      )[0];
      const index = state.claim_images.indexOf(current_item);
      state.claim_images[index].image = image;
    },
    removeClaimImage: (state, action) => {
      state.claim_images = state.claim_images.filter(
        (image) => image.id !== action.payload
      );
    },
    setClaimImages: (state, action) => {
      state.claim_images = action.payload;
    },
    setRefInvoice: (state, action) => {
      state.refInvoice = action.payload;
    },
    restartClaim: (state, action) => {
      state.claim_type = 0;
      state.initial_order = undefined;
      state.claim = undefined;
    },
    setClaim: (state, action) => {
      state.claim = action.payload;
    },
    setClaimType: (state, action) => {
      state.claim_type = parseInt(action.payload);
    },
    addClaimItem: (state, action) => {
      state.claim_items.push(action.payload);
    },
    removeClaimItem: (state, action) => {
      const pc = action.payload;
      let current_items = state.claim_items.filter(
        (item) => item.product_code === pc
      );
      const last_item = current_items.pop();
      const index = state.claim_items.indexOf(last_item);
      state.claim_items.splice(index, 1);
    },
    updateClaimItem: (state, action) => {
      const key = action.payload.item_key;
      const current_item = state.claim_items
        .filter((item) => item.item_key === key)
        .pop();
      const index = state.claim_items.indexOf(current_item);
      state.claim_items[index] = action.payload;
    },
    updateOperationCondition: (state, action) => {
      const current_item = state.claim_items.filter(
        (item) => item.item_key === action.payload.claim_item
      )[0];

      const current_index = state.claim_items.indexOf(current_item);
      let operation_items = state.claim_items[current_index].operation_items;
      const oitem = operation_items.filter(
        (item) => item.sequence_num === action.payload.sequence_num
      )[0];
      const oindex = operation_items.indexOf(oitem);
      state.claim_items[current_index].operation_items[
        oindex
      ].operation_comments = action.payload.operation_comments;
      state.claim_items[current_index].operation_items[oindex].operation_type =
        action.payload.operation_type;
    },
    removeOperationCondition: (state, action) => {
      const current_item = state.claim_items.filter(
        (item) => item.item_key === action.payload.claim_item
      )[0];
      const current_index = state.claim_items.indexOf(current_item);
      let operation_items = state.claim_items[current_index].operation_items;
      const oitem = operation_items.filter(
        (item) => item.sequence_num === action.payload.sequence_num
      )[0];
      const oindex = operation_items.indexOf(oitem);
      operation_items.splice(oindex, 1);
      state.claim_items[current_index] = {
        ...current_item,
        operation_items: operation_items,
      };
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(getClaimImages.fulfilled, (state, action) => {
        if (action.payload && action.payload.length > 0) {
          state.claim_images = action.payload;
          const process_array = [...state.claim_images];
          let keys_array = [];
          for (let i = 0; i < process_array.length; i++) {
            keys_array.push(process_array[i].id);
          }
          state.last_load_image_keys = keys_array;
        }
      })
      .addCase(saveClaim.fulfilled, (state, action) => {
        const key_pairs = action.payload.key_pairs;
        let temp_images = [...state.claim_images];
        for (let i = 0; i < temp_images.length; i++) {
          temp_images[i] = {
            ...temp_images[i],
            parent_key: key_pairs.filter(
              (key) => key.old_key === temp_images[i].parent_key
            )[0].new_key,
          };
        }
        state.claim_images = [...temp_images];
      })
      .addCase(updateClaim.fulfilled, (state, action) => {
        const key_pairs = action.payload.key_pairs;
        let temp_images = [...state.claim_images];
        for (let i = 0; i < temp_images.length; i++) {
          temp_images[i] = {
            ...temp_images[i],
            parent_key: key_pairs.filter(
              (key) => key.old_key === temp_images[i].parent_key
            )[0].new_key,
          };
        }
        state.claim_images = [...temp_images];
      })
      .addCase(saveApiLog.fulfilled, (state, action) => {
        state.claim_logs.push(action.payload);
      })
      .addCase(getClaimById.fulfilled, (state, action) => {
        const { claim_items, claim_logs, ...claim } = action.payload.claim;
        state.initial_order = action.payload.order;
        state.problem_list = action.payload.problems;

        state.claim = claim;
        state.claim_items = claim_items;
        state.claim_logs = claim_logs;
        state.claim_type = claim.claim_type;

        const line_items = state.initial_order.line_items;
        const items = [];
        for (let i = 0; i < line_items.length; i++) {
          // For now, we have chosen to leave off all items if they are not a normal item
          const item = line_items[i].item;
          if (parseInt(item.item_type) === ORDER_ITEM_INVENTORY_TYPE) {
            let items_per = state.claim_items.filter(
              (ite) => ite.product_code === item.product.product_code
            );
            let new_item = {};
            if (items_per.length > 0) {
              const temp_item = items_per[0];
              new_item = {
                ...temp_item,
                original_quantity: parseInt(item.qty_shipped),
              };
            } else {
              new_item = {
                ...defaultClaimItem,
                item_key: Math.random(),
                sequence_num: i,
                product_code: item.product.product_code,
                description: item.product.description,
                original_quantity: parseInt(item.qty_shipped),
                unit_cost: parseFloat(item.cost),
                customer_price: parseFloat(item.item_price),
                unit_weight: (
                  parseFloat(item.weight) / parseFloat(item.qty_shipped)
                ).toFixed(2),
              };
            }

            items.push(new_item);
          }
        }

        state.possible_items = [...items];
      })
      .addCase(getProblemList.fulfilled, (state, action) => {
        state.problem_list = action.payload;
      })
      .addCase(getInitialOrder.fulfilled, (state, action) => {
        state.initial_order = action.payload;
        if (!state.claim) {
          state.claim = {
            ...defaultClaim,
            customer: action.payload.customer,
            ref_invoice: action.payload.customer_invoice_num,
            reported_by: action.payload.customer.name,
            reported_by_phone: action.payload.customer.contact.phone,
            reported_by_email: action.payload.customer.contact.emails[0],
          };
        }

        const line_items = action.payload.line_items;
        const items = [];
        for (let i = 0; i < line_items.length; i++) {
          // For now, we have chosen to leave off all items if they are not a normal item
          const item = line_items[i].item;
          if (parseInt(item.item_type) === ORDER_ITEM_INVENTORY_TYPE) {
            const new_item = {
              ...defaultClaimItem,
              item_key: Math.random(),
              sequence_num: i,
              product_code: item.product.product_code,
              description: item.product.description,
              original_quantity: parseInt(item.qty_shipped),
              unit_cost: parseFloat(item.cost),
              customer_price: parseFloat(item.item_price),
              unit_weight: (
                parseFloat(item.weight) / parseFloat(item.qty_shipped)
              ).toFixed(2),
            };

            items.push(new_item);
          }
        }

        state.possible_items = [...items];
      });
  },
});

export const {
  setClaimType,
  restartClaim,
  setClaim,
  addClaimItem,
  removeClaimItem,
  updateClaimItem,
  removeOperationCondition,
  updateOperationCondition,
  setRefInvoice,
  setClaimImages,
  removeClaimImage,
  updateClaimImage,
} = ClaimSlice.actions;

export default ClaimSlice.reducer;
