import { Button, Card, Col, Row } from "react-bootstrap";
import "./CreditInput.scss";
import KeyboardArrowRightIcon from "@mui/icons-material/KeyboardArrowRight";
import KeyboardArrowDownIcon from "@mui/icons-material/KeyboardArrowDown";

const CreditInputToggle = (props) => {
  return (
    <>
      <Card className="mt-5">
        <Row className="flex-between">
          <Col>
            <h3>{props.label}</h3>
          </Col>
          <Col>
            <Button onClick={props.toggleFunction} className="f-right bg-dull">
              {props.toggled ? "Hide " : "Show"}{" "}
              {props.toggled && <KeyboardArrowDownIcon />}
              {!props.toggled && <KeyboardArrowRightIcon />}
            </Button>
          </Col>
        </Row>
      </Card>
    </>
  );
};

export default CreditInputToggle;
