import { configureStore, isRejected } from "@reduxjs/toolkit";
import headerSlice from "./slices/headerSlice";
import quoteSlice from "./slices/quoteSlice";
import itemSlice from "./slices/item/itemSlice";
import refsSlice from "./slices/refsSlice";
import subOrderSlice from "./slices/subOrderSlice";
import customerSlice from "./slices/customer/customerSlice";
import validationsSlice from "./slices/validations/validationsSlice";
import aRBalanceSlice from "./slices/aRBalance/aRBalanceSlice";
import freightSlice from "./slices/freight/freightSlice";
import loaderSpinnerSlice from "./slices/loaderSpinnerSlice";
import purchaseSlice from "./slices/Purchasing/PurchaseSlice";
import apiErrorSlice, {
  setErrorApiMsg,
} from "./slices/apiErrors/apiErrorSlice";
import faxEmailSlice from "./slices/faxEmail/faxEmailSlice";
import eventHandlerSlice from "./slices/eventHandlerSlice";
import bolSlice from "./slices/bol/bolSlice";
import warehouseSlice from "./slices/warehouse/warehouseSlice";
import accountsReceivableSlice from "./slices/AccountsReceivable/InvoiceAccessSlice";
import creditSlice from "./slices/Credits/CreditSlice";
import FreightRaterSlice from "./slices/FreightRater/FreightRaterSlice";
import axios from "axios";
import ClaimSlice from "./slices/Claims/ClaimSlice";

/**
 * Catch all network request errors
 */
const queryErrorLogger = (api) => (next) => (action) => {
  if (isRejected(action)) {
    api.dispatch(
      setErrorApiMsg({ id: action.type, axiosData: action.payload })
    );
  }
  return next(action);
};

const apiClient = axios.create();

apiClient.interceptors.request.use((config) => {
  config.headers["source"] = "REACTAPP";

  return config;
});

export default configureStore({
  reducer: {
    header: headerSlice,
    quote: quoteSlice,
    items: itemSlice,
    purchasing: purchaseSlice,
    accountsReceivable: accountsReceivableSlice,
    credits: creditSlice,
    claims: ClaimSlice,
    refs: refsSlice,
    subOrder: subOrderSlice,
    customer: customerSlice,
    validations: validationsSlice,
    aRBalance: aRBalanceSlice,
    freight: freightSlice,
    loaderSpinner: loaderSpinnerSlice,
    apiError: apiErrorSlice,
    faxEmail: faxEmailSlice,
    eventHandler: eventHandlerSlice,
    bol: bolSlice,
    warehouse: warehouseSlice,
    freightRater: FreightRaterSlice,
  },
  middleware: (getDefaultMiddleware) =>
    getDefaultMiddleware().concat(queryErrorLogger),
});
