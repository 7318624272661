import { memo } from "react";
import { Modal, Button } from "react-bootstrap";

function UndoDeletepopup({ show, handleCancelEvent, handleConfirmEvent }) {
  return (
    <Modal show={show} centered aria-labelledby="contained-modal-title-vcenter">
      <Modal.Header>
        <div>
          <b>Undo Delete Claim</b>
        </div>
      </Modal.Header>
      <Modal.Body>
        This will undo deleting this claim.
        <br />
        <br /> Are you sure you want to continue?
      </Modal.Body>
      <Modal.Footer>
        <Button variant={"danger"} onClick={handleCancelEvent}>
          Cancel
        </Button>
        <Button variant={"primary"} onClick={handleConfirmEvent}>
          Continue
        </Button>
      </Modal.Footer>
    </Modal>
  );
}

export default memo(UndoDeletepopup);
