import { IconButton, TextField, Tooltip } from "@mui/material";
import { ButtonGroup, Row } from "react-bootstrap";
import { BsArrowReturnRight } from "react-icons/bs";
import "../CreditInput.scss";
import { AiFillDelete } from "react-icons/ai";
import { useEffect, useState } from "react";
import {
  removeDescriptionItem,
  updateDescriptionItem,
} from "../../../../store/slices/Credits/CreditSlice";
import { useDispatch } from "react-redux";

/**
 * All of these props are updated in redux with the exception of disabled
 *
 * @param sequenceNum this is the sequence num associated with each item, it is unique. When we first create an item this number is just a result of Math.random()
 * @param description this just contains the description text of the display item
 * @param parentId this is the sequence num of the parent
 * @param disabled this determines whether the order is editable or not as detemined by credit Entry
 *
 * @returns
 */
const DescriptionCreditLine = ({
  sequenceNum,
  description,
  parentId,
  disabled,
}) => {
  const dispatch = useDispatch();

  const remDescriptionItem = () => {
    dispatch(removeDescriptionItem({ key: sequenceNum }));
  };

  const [item, setItem] = useState({
    description: description,
    type: 4, //this will not change
    parentId: parentId, // this is equal to the sequence num of the parent
    sequence_num: sequenceNum,
  });

  const handleDescriptionChange = (value) => {
    setItem({
      ...item,
      description: value,
    });
  };

  useEffect(() => {
    if (item.description) {
      dispatch(
        updateDescriptionItem({
          key: item.sequence_num,
          description: item.description,
        }),
      );
    }
  }, [item]);

  return (
    <>
      <div className="description-row mt-5">
        <div className="description-icon">
          <BsArrowReturnRight />
        </div>
        <div className="description-field">
          <TextField
            disabled={disabled}
            inputProps={{ maxLength: 30 }}
            value={description}
            onChange={(e) => handleDescriptionChange(e.target.value)}
            helperText={
              "Characters Remaining: " +
              (30 - item.description.length) +
              "/" +
              30
            }
          ></TextField>
        </div>
        <div className="description-delete">
          <ButtonGroup aria-label="action-button-group" className="mt-4">
            <Tooltip title="Delete" arrow placement="top">
              <IconButton
                color="primary"
                disabled={disabled}
                onClick={(e) => remDescriptionItem(e.target.value)}
                size="small"
              >
                <AiFillDelete />
              </IconButton>
            </Tooltip>
          </ButtonGroup>
        </div>
      </div>
      <Row className="footnote">
        <i>Description Item</i>
      </Row>
    </>
  );
};

export default DescriptionCreditLine;
