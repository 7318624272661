import { Button, Card, Container, Spinner } from "react-bootstrap";
import { TbArrowRight } from "react-icons/tb";
import "./Home.scss";
import { Alert } from "@mui/material";
import { useContext } from "react";
import { LoginContext } from "../contexts/LoginContext";

function Home(props) {
  const { username, setAuthenticated } = useContext(LoginContext);

  return (
    <>
      {props.groups.length == 0 ? (
        <div
          className="d-flex justify-content-center align-items-center"
          style={{ width: "100%", minHeight: 200 }}
        >
          <Alert severity="error" variant="outlined" className="mt-5">
            If you are seeing this you have no active groups, please contact an
            administrator.
          </Alert>
        </div>
      ) : (
        <Container>
          {props.groups.length > 0 && (
            <Card className="app-nav-buttons mt-5">
              <h2>
                Welcome {username ? username.split("@")[0] : "User"}! Where
                would you like to go?
              </h2>
              {props.groups.includes(props.orderGroup) && (
                <Button onClick={props.handleShowOrders}>
                  <div>Customer Orders & Quotes</div>
                  <TbArrowRight />
                </Button>
              )}
              {props.groups.includes(props.creditGroup) && (
                <Button onClick={props.handleShowCredits}>
                  <div>Customer Credits & Claims</div>
                  <TbArrowRight />
                </Button>
              )}

              {props.groups.includes(props.purchaseGroup) && (
                <Button onClick={props.handleShowPurchasing}>
                  <div>Purchasing</div>
                  <TbArrowRight />
                </Button>
              )}
              {props.groups.includes(props.accountsReceivableGroup) && (
                <Button onClick={props.handleShowAccountsReceivable}>
                  <div>Accounts Receivable</div>
                  <TbArrowRight />
                </Button>
              )}
            </Card>
          )}
        </Container>
      )}
    </>
  );
}

export default Home;
