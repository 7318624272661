import { Alert, TextField } from "@mui/material";
import { Button, Col, Row, Spinner } from "react-bootstrap";
import { useDispatch, useSelector } from "react-redux";
import { useEffect, useState } from "react";
import LoaderSpinner from "../../../components/atoms/LoaderSpinner";
import { formStyle } from "../../../theming/formStyle";
import { RATER_TYPE } from "../../../helpers/const";
import "./FreightRater.scss";

const GenericFreightRaterHeader = (props) => {
  const { rating, selectedRate } = useSelector((state) => state.freightRater);

  const dispatch = useDispatch();

  const [warehouseId, setWarehouseId] = useState();
  const [warehouseName, setWarehouseName] = useState();
  const [warehouseZip, setWarehouseZip] = useState();
  const [shipFromState, setShipFromState] = useState();
  const [shipFromZip, setShipFromZip] = useState();

  useEffect(() => {
    if (props.warehouse) {
      setWarehouseId(props.warehouse.warehouse_id);
      setWarehouseName(props.warehouse.name);
      setWarehouseZip(props.warehouse.zip);
    }
  }, [props.warehouse]);

  useEffect(() => {
    if (props.order || props.dropShip) {
      setShipFromState(
        props.dropShip
          ? props.dropShip.address.state
          : props.order.customer.shipping_address.state,
      );
      setShipFromZip(
        props.dropShip
          ? props.dropShip.address.zip_code
          : props.order.customer.shipping_address.zip_code,
      );
    }
  }, [props.order, props.dropShip]);

  return (
    <>
      {props.totalWeight <= 0 && props.type === RATER_TYPE.CREDIT_RATER && (
        <Row>
          <Alert severity="error" variant="outlined" className="error-format">
            You can not rate this credit unless you have a weight.
          </Alert>
        </Row>
      )}
      {!selectedRate.quote && (
        <Row>
          <Alert severity="error" variant="outlined" className="error-format">
            You must have a freight amount to save the credit.
          </Alert>
        </Row>
      )}
      <Row style={{ marginTop: "20px" }} className="freightRaterPop">
        <Col>
          <TextField
            label="Warehouse#"
            style={formStyle.inputStyle}
            InputLabelProps={formStyle.inputLabelProps}
            value={warehouseId ? shipFromZip : 0}
            disabled
          />
        </Col>

        <Col>
          <TextField
            label="Warehouse State"
            style={formStyle.inputStyle}
            InputLabelProps={formStyle.inputLabelProps}
            fullWidth
            value={warehouseName ? warehouseName : ""}
            disabled
          />
        </Col>

        <Col>
          <TextField
            label="Warehouse Zip"
            style={formStyle.inputStyle}
            InputLabelProps={formStyle.inputLabelProps}
            value={warehouseZip ? warehouseZip : ""}
            placeholder={warehouseZip}
            disabled
          />
        </Col>
        <Col>
          <TextField
            label="Ship From Zip"
            style={formStyle.inputStyle}
            InputLabelProps={formStyle.inputLabelProps}
            value={shipFromZip ? shipFromZip : ""}
            placeholder={shipFromZip}
            onChange={(e) =>
              setShipFromZip(e.target.value.replace(/[^0-9]/g, ""))
            }
          />
        </Col>

        <Col>
          <TextField
            label="Ship From State"
            style={formStyle.inputStyle}
            InputLabelProps={formStyle.inputLabelProps}
            fullWidth
            value={shipFromState ? shipFromState : ""}
            onChange={(e) =>
              setShipFromState(e.target.value.replace(/[0-9]/g, ""))
            }
          />
        </Col>
        <Col>
          <TextField
            label="Weight"
            style={formStyle.inputStyle}
            InputLabelProps={formStyle.inputLabelProps}
            value={props.totalWeight}
            disabled
          />
        </Col>
      </Row>
      <Row>
        <Col className="d-flex justify-content-end">
          <Button
            variant="primary"
            className="mx-3 "
            disabled={props.totalWeight <= 0}
            style={{ maxHeight: 48, marginTop: "2%" }}
            onClick={props.rate}
          >
            Rate
          </Button>
        </Col>
      </Row>
    </>
  );
};

export default GenericFreightRaterHeader;
