import InvoiceAccess from "./InvoiceAccess/InvoiceAccess";

const AccountsReceivableSection = () => {
  return (
    <>
      <InvoiceAccess />
    </>
  );
};

export default AccountsReceivableSection;
