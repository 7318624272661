import { ButtonGroup, Col, Row } from "react-bootstrap";
import { ClaimOperatingConditions } from "../../../../helpers/const";
import { Autocomplete, IconButton, TextField, Tooltip } from "@mui/material";
import { AiFillDelete } from "react-icons/ai";
import { useDispatch } from "react-redux";
import {
  removeOperationCondition,
  updateOperationCondition,
} from "../../../../store/slices/Claims/ClaimSlice";

const OperationLine = ({ item, disabled, hidden }) => {
  const dispatch = useDispatch();
  const updateItem = (value, use_case) => {
    let temp_item = { ...item };
    switch (use_case) {
      case "condition":
        temp_item = {
          ...item,
          operation_type: ClaimOperatingConditions.filter(
            (option) => option.label === value
          )[0].id,
        };
        break;
      case "comments":
        temp_item = {
          ...item,
          operation_comments: value,
        };
        break;
    }
    dispatch(updateOperationCondition(temp_item));
  };

  const removeSelf = () => {
    dispatch(removeOperationCondition(item));
  };

  const conditionProps = {
    options: ClaimOperatingConditions,
    getOptionLabel: (option) => option.label,
  };
  return (
    <>
      <Row className="mt-5" hidden={hidden}>
        <Col>
          <div className="info-header">Condition</div>
          <Autocomplete
            disabled={disabled}
            disableClearable
            {...conditionProps}
            className="autocomplete-input-credit"
            onChange={(e) => updateItem(e.target.textContent, "condition")}
            value={
              ClaimOperatingConditions.filter(
                (prob) => parseInt(prob.id) === parseInt(item.operation_type)
              )[0]
            }
            isOptionEqualToValue={(option, value) =>
              option.id === value ? value.id : value
            }
            renderInput={(params) => (
              <TextField
                className="autocomplete-problems"
                {...params}
                variant="standard"
              />
            )}
          />
        </Col>
        <Col>
          <div className="info-header">Comments</div>
          <TextField
            disabled={disabled}
            className="w-100"
            value={item.operation_comments}
            helperText={
              "Remaining Characters: " +
              (80 - item.operation_comments.length) +
              " / 80"
            }
            onChange={(e) => {
              updateItem(e.target.value, "comments");
            }}
          />
        </Col>
        <Col>
          <div className=" mt-5 f-right">
            <ButtonGroup
              aria-label="action-button-group"
              className="mt-4 f-right"
            >
              <Tooltip title="Delete" arrow placement="top">
                <IconButton
                  color="primary"
                  disabled={disabled}
                  className="f-right"
                  onClick={(e) => removeSelf()}
                  size="small"
                >
                  <AiFillDelete />
                </IconButton>
              </Tooltip>
            </ButtonGroup>
          </div>
        </Col>
      </Row>
    </>
  );
};

export default OperationLine;
