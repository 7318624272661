import React, { useState, useCallback, useEffect } from "react";
import { useDispatch } from "react-redux";
import Heading from "../../components/sections/Heading/Heading";
import { Link } from "react-router-dom";
import dayjs from "dayjs";
import DataGrid from "../../components/atoms/Table/DataGrid";
import {
  ClaimStatus,
  ClaimType,
  CREDIT_APPROVAL_STATUS,
  CREDIT_HOLD_STATUS,
  CREDIT_REASON,
  CREDIT_TYPE,
} from "../../helpers/const";
import { endpoints } from "../../api/endpoints";
import { getCreditsByFilter } from "../../store/slices/Credits/CreditSlice";
import { getClaimsBYFilter } from "../../store/slices/Claims/ClaimSlice";
import { Col, FormGroup, Row } from "react-bootstrap";
import { Checkbox, FormControlLabel } from "@mui/material";

/**
 * These header attributes can be changed at any point if needed and it will automatically update below.
 * Be careful changing the ID as that is actually used to set values when filtering
 */
const newHeaderAttributes = [
  {
    id: "claim_num",
    label: "Claim #",
    tooltip: "Claim Number",
    sortable: true,
    searchable: {
      label: "Search by Claim Number",
      type: "text",
      width: 4,
    },
    width: "5%",
  },
  {
    id: "claim_type",
    label: "Claim Type",
    sortable: true,
  },
  {
    id: "Claim Reason",
    label: "Credit Reason",
    sortable: true,
  },
  {
    id: "ref_invoice",
    label: "Ref Invoice",
    hidden: true,
    searchable: {
      label: "Search by Reference Invoice Number",
      type: "text",
      width: 4,
    },
  },
  {
    id: "date_entered",
    label: "Date",
    tooltip: "Date of Order",
    width: "10%",
  },

  {
    id: "agent",
    label: "Agent",
  },
  {
    id: "claim_amount",
    label: "Total",
    sortable: true,
  },
  {
    id: "claim_status",
    label: "Status",
    sortable: true,
  },
  {
    id: "credit_memo_num",
    label: "Credit #",
    sortable: true,
  },
  {
    id: "",
    label: "",
    sortable: false,
  },
];

/**
 * Component to fetch and render the list of credits based on parameters
 *
 * @returns CreditSearch
 */
const ClaimSearch = () => {
  const [dataList, setDataList] = useState([]);
  //The total count of credits that fit into this filter, even if not all are displayed since we paginate at 50
  const [totalClaims, setTotalClaims] = useState(0);
  const [showDeleted, setShowDeleted] = useState(false);

  // Redux
  const dispatch = useDispatch();

  const fetchCredits = useCallback(
    async ({
      credit_memo_num,
      claim_num,
      ref_invoice,
      to,
      status,
      from,
      selected,
      page,
      order_by,
      sort_order,
      show_deleted,
    }) => {
      /**
       * This can look intimidating but all this does is get the objects by passing filter params to the API
       *  then
       * manually sets each field in header attributes if it exists
       */
      console.log(showDeleted);
      dispatch(
        getClaimsBYFilter({
          credit_memo_num,
          claim_num,
          ref_invoice,
          to,
          status,
          from,
          selected,
          page,
          order_by,
          sort_order,
          show_deleted,
        })
      ).then((response) => {
        if (!response?.error) {
          setTotalClaims(response.payload.count);
          setDataList(
            response?.payload.results.map((value) => ({
              id: value.claim_num,
              claim_num: (
                <Link
                  to={
                    endpoints.CREDIT_ENTRY +
                    `/warranty-claim/${value.claim_num}`
                  }
                >
                  {value.claim_num}
                </Link>
              ),
              claim_type: ClaimType.filter(
                (type) => type.id === value.claim_type
              )[0].label,
              claim_reason: CREDIT_REASON.filter(
                (option) => option.id === value.claim_reason
              )[0].label,
              date_entered: value.date_entered,
              agent: value.entered_by,
              claim_amount: value.claim_amount,
              claim_status: ClaimStatus.filter(
                (option) => option.id === value.claim_status
              )[0].label,
              credit_memo_num:
                value.credit_memo_num > 0 ? (
                  <Link
                    to={
                      endpoints.CREDIT_ENTRY +
                      "/credit/" +
                      value.credit_memo_num
                    }
                  >
                    {value.credit_memo_num} (Ref Invoice: {value.ref_invoice})
                  </Link>
                ) : (
                  "-"
                ),
              actions: (
                <Link
                  to={
                    endpoints.CREDIT_ENTRY +
                    `/warranty-claim/${value.claim_num}`
                  }
                >
                  View Claim
                </Link>
              ),
            }))
          );
        }
      });
    },
    []
  );

  /**
   * This function is probably not necessary but I do it to allow the data to be tracked easier,
   * you could just explode customParameters but doing like this,
   * once you get to creditSearch you can assume all of your data is either valid or undefined
   */
  const prepareFetchCredits = useCallback(
    async ({
      page = 0,
      orderBy,
      sortOrder,
      customParameters,
      stat,
      show_deleted,
    }) => {
      let claim_num;
      let ref_invoice;
      let from = null;
      let to = null;
      let order_by = orderBy;
      if (orderBy === "status") order_by = "claim_status";

      if (customParameters) {
        customParameters.forEach((c) => {
          switch (c?.id) {
            case "claim_num":
              claim_num = c.value;
              break;
            case "ref_invoice":
              ref_invoice = c.value;
              break;
            case "dates":
              from = dayjs(c.value[0]).format("YYYY-MM-DD");
              to = dayjs(c.value[1]).format("YYYY-MM-DD");
              break;
          }
        });
      }

      await fetchCredits({
        page: page + 1,
        order_by,
        sort_order: sortOrder === "desc" ? "dsc" : "asc",
        claim_num,
        ref_invoice,
        status: stat,
        to,
        from,
        show_deleted,
      });
    },
    [fetchCredits]
  );

  return (
    <div>
      <Row>
        <Col>
          <Heading title="Claims" />
        </Col>
        <Col>
          <FormGroup className="mt-3 f-right">
            <FormControlLabel
              id="show_deleted"
              label="Include Deleted"
              control={
                <Checkbox
                  checked={showDeleted}
                  onClick={(e) => setShowDeleted(!showDeleted)}
                />
              }
            />
          </FormGroup>
        </Col>
      </Row>

      <div className="mt-5">
        <div className="mt-5">
          {/* This is a custom component not a MUI component */}
          <DataGrid
            headerAttributes={newHeaderAttributes}
            dataList={dataList}
            hideId={true}
            rowsPerPage={50}
            total={totalClaims}
            defaultOrder="asc"
            defaultOrderBy="status"
            fetchData={prepareFetchCredits}
            showDeletedClaims={showDeleted}
            dateFilter={{
              show: true,
              inputs: [
                {
                  label: "From",
                  name: "from",
                },
                {
                  label: "To",
                  name: "to",
                },
              ],
            }}
          />
        </div>
      </div>
    </div>
  );
};

export default ClaimSearch;
