import { Autocomplete, IconButton, TextField, Tooltip } from "@mui/material";
import { Button, ButtonGroup, Col, Row } from "react-bootstrap";
import { AiFillDelete } from "react-icons/ai";
import { useDispatch, useSelector } from "react-redux";
import {
  addDescriptionItem,
  removeNonSale,
  updateNonSale,
} from "../../../../store/slices/Credits/CreditSlice";
import { BsReverseBackspaceReverse } from "react-icons/bs";

const UPDATE_CASE = {
  DESCRIPTION: 0,
  RETURN_QTY: 1,
  PERCENT_ALLOWED: 2,
  ITEM_PRICE: 3,
  PROBLEM: 4,
  COMMENT: 5,
};

const NonSale = ({ item, disabled }) => {
  const dispatch = useDispatch();

  const { problemList } = useSelector((state) => state.credits);

  const remNonSale = () => {
    dispatch(removeNonSale({ key: item.key }));
  };

  const appendDescriptionItem = () => {
    dispatch(addDescriptionItem({ line_num: item.line_num }));
  };

  const updateItem = (params) => {
    const value = params.value;
    let toUpdate = { ...item };
    switch (params.case) {
      case UPDATE_CASE.DESCRIPTION:
        toUpdate = {
          ...toUpdate,
          description: value,
        };
        break;
      case UPDATE_CASE.RETURN_QTY:
        let ret = parseFloat(value ? value.slice(0, 8) : 0);
        if (ret < 0) {
          ret = 0;
        }
        toUpdate = {
          ...toUpdate,
          return: ret,
          price: ret * (item.percent_allowed / 100) * item.item_price,
        };
        break;
      case UPDATE_CASE.PERCENT_ALLOWED:
        let perc = parseFloat(value ? value.slice(0, 8) : 0);
        if (perc < 0) {
          perc = 0;
        } else if (perc > 100) {
          perc = 100;
        }
        toUpdate = {
          ...toUpdate,
          percent_allowed: perc,
          price: item.return * (perc / 100) * item.item_price,
        };
        break;
      case UPDATE_CASE.ITEM_PRICE:
        let price = parseFloat(value ? value.slice(0, 8) : 0);
        if (price < 0) {
          price = 0;
        }
        toUpdate = {
          ...toUpdate,
          item_price: price,
          price: item.return * (item.percent_allowed / 100) * price,
        };
        break;
      case UPDATE_CASE.COMMENT:
        toUpdate = {
          ...toUpdate,
          comments: value,
        };
        break;
      case UPDATE_CASE.PROBLEM:
        toUpdate = {
          ...toUpdate,
          problem_num: parseInt(value),
        };
        break;
    }
    dispatch(updateNonSale({ key: item.key, item: toUpdate }));
  };

  const problemProps = {
    options: problemList,
    getOptionLabel: (option) => option.problem_num + " - " + option.description,
  };

  return (
    <div className="mb-5 bottom-border-seperator">
      <Row className="mb-5">
        <Col>
          <div>
            <b>Total Credit: </b> {item.price}
          </div>
        </Col>
      </Row>
      <Row>
        <Col>
          <div className="info-header">Product Code</div>
          <TextField value={item.product_code} disabled />
        </Col>
        <Col className="grow">
          <div className="info-header">Description</div>
          <TextField
            value={item.description}
            className="w-100"
            inputProps={{ maxLength: 30 }}
            placeholder="Enter a description..."
            helperText={
              "Characters Remaining: " +
              (30 - item.description.length) +
              "/" +
              30
            }
            onChange={(e) =>
              updateItem({
                case: UPDATE_CASE.DESCRIPTION,
                value: e.target.value,
              })
            }
          />
        </Col>
        <Col>
          <div className="info-header">Credit Qty</div>
          <TextField
            value={item.return.toString()}
            type="number"
            onChange={(e) =>
              updateItem({
                case: UPDATE_CASE.RETURN_QTY,
                value: e.target.value,
              })
            }
          />
        </Col>
        <Col>
          <div className="info-header">Percent Allowed</div>
          <TextField
            value={item.percent_allowed.toString()}
            type="number"
            onChange={(e) =>
              updateItem({
                case: UPDATE_CASE.PERCENT_ALLOWED,
                value: e.target.value,
              })
            }
          />
        </Col>
        <Col>
          <div className="info-header">Item Price</div>
          <TextField
            value={item.item_price.toString()}
            type="number"
            onChange={(e) =>
              updateItem({
                case: UPDATE_CASE.ITEM_PRICE,
                value: e.target.value,
              })
            }
          />
        </Col>
        <Col className="g-row">
          <Button
            onClick={appendDescriptionItem}
            className="bg-dull mt-4"
            disabled={disabled}
          >
            Add Description
          </Button>
        </Col>
        <Col className="center">
          <ButtonGroup aria-label="action-button-group" className="mt-4">
            <Tooltip title="Delete" arrow placement="top">
              <IconButton
                color="primary"
                disabled={disabled}
                onClick={remNonSale}
                size="small"
              >
                <AiFillDelete />
              </IconButton>
            </Tooltip>
          </ButtonGroup>
        </Col>
      </Row>
      <Row>
        <Col className="credit-comments">
          <div className="info-header">Comments</div>
          <TextField
            disabled={disabled}
            inputProps={{ maxLength: 80 }}
            value={item.comments}
            className="problems"
            onChange={(e) =>
              updateItem({ case: UPDATE_CASE.COMMENT, value: e.target.value })
            }
            helperText={
              "Characters Remaining: " + (80 - item.comments.length) + "/" + 80
            }
          ></TextField>
        </Col>
        <Col>
          <div className="info-header">Problems</div>
          <Autocomplete
            disabled={disabled}
            disableClearable
            {...problemProps}
            className="autocomplete-input-credit"
            onChange={(e) =>
              updateItem({
                case: UPDATE_CASE.PROBLEM,
                value: e.target.textContent.split(" ")[0],
              })
            }
            value={
              problemList.filter(
                (prob) => prob.problem_num === item.problem_num,
              )[0]
            }
            isOptionEqualToValue={(option, value) =>
              option.problem_num === value.problem_num
            }
            renderInput={(params) => (
              <TextField
                className="autocomplete-problems"
                {...params}
                variant="standard"
              />
            )}
          />
        </Col>
      </Row>
      <Row className="footnote">
        <i>Non-Sale Item</i>
      </Row>
    </div>
  );
};

export default NonSale;
