import { memo, useState } from "react";
import { Modal, Button, Row, Col } from "react-bootstrap";
import "./DuplicateCreditPopup.scss";
import { TbArrowBackUp, TbArrowForwardUp } from "react-icons/tb";
import { Grid } from "@mui/material";

function WarningRestartPopup({ show, handleCancelEvent, handleConfirmEvent }) {
  return (
    <Modal show={show} centered aria-labelledby="contained-modal-title-vcenter">
      <Modal.Header>
        <div>
          <b>Restart Credit</b>
        </div>
      </Modal.Header>
      <Modal.Body>
        This will reset all of your progress for this credit.
        <br />
        Any changes made that have not been saved will be lost.
        <br />
        <br /> Would you still like to restart?
      </Modal.Body>
      <Modal.Footer>
        <Button variant={"danger"} onClick={handleCancelEvent}>
          Cancel
        </Button>
        <Button variant={"primary"} onClick={handleConfirmEvent}>
          Continue
        </Button>
      </Modal.Footer>
    </Modal>
  );
}

export default memo(WarningRestartPopup);
