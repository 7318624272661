import {
  Button,
  Col,
  Dropdown,
  FormGroup,
  NavLink,
  Row,
} from "react-bootstrap";
import { useDispatch, useSelector } from "react-redux";
import {
  CLAIM_CLOSED,
  CLAIM_DAMAGE,
  ClaimStatus,
  ClaimType,
  DENIED,
  PENDING_CLAIM,
} from "../../../helpers/const";
import { AiOutlineSave } from "react-icons/ai";
import { useContext, useEffect, useState } from "react";
import WarningRestartPopup from "../../../components/atoms/CreditPopups/WarningRestartPopup";
import {
  convertToCredit,
  deleteClaim,
  restartClaim,
  saveClaim,
  saveClaimImages,
  sendConfirmation,
  setClaim,
  undoDeleteClaim,
  updateClaim,
} from "../../../store/slices/Claims/ClaimSlice";
import ConfirmSaveClaimPopup from "../../../components/atoms/ClaimPopups/ConfirmSaveClaimPopup";
import { AlertMessageContext } from "../../../components/atoms/AlertMessage/AlertMessage";
import WarningDeletePopup from "../../../components/atoms/CreditPopups/WarningDeletePopup";
import ClaimDeletePopup from "../../../components/atoms/ClaimPopups/ClaimDeletePopup";
import { endpoints } from "../../../api/endpoints";
import { Checkbox, FormControlLabel, TextField } from "@mui/material";
import ConfirmationPopup from "../../../components/atoms/ClaimPopups/ConfirmationPopup";
import { Link } from "react-router-dom";
import UndoDeletePopup from "../../../components/atoms/ClaimPopups/UndoDeletePopup";
import SendClaimSummary from "../../../components/atoms/ClaimPopups/SendClaimSummary";

const ClaimHeader = ({ disabled, setClaimStarted }) => {
  const { claim_type, initial_order, claim } = useSelector(
    (state) => state.claims
  );
  const [showSavePopup, setShowSavePopup] = useState(false);
  const [showRestartPopup, setShowRestartPopup] = useState(false);
  const [showDeletePopup, setShowDeletePopup] = useState(false);
  const [showConfirmConvertPopup, setShowConfirmConvertPopup] = useState(false);
  const [showUndoDelete, setShowUndoDelete] = useState(false);
  const [showSendClaim, setShowSendClaim] = useState(false);

  const dispatch = useDispatch();

  //This is used for the confirmation alerts such as "Claim saved succesfully!"
  const alertNotification = useContext(AlertMessageContext);

  const sendConfirmationDispatch = (method, email) => {
    dispatch(sendConfirmation({ method, email })).then((response) => {
      if (!response?.error) {
        alertNotification.handleOpen(
          "success-savign-as-sub-order",
          "success",
          "Claim sent successfully!",
          2000
        );
        setShowSendClaim(false);
      }
    });
  };

  const updateReceivedCredit = () => {
    dispatch(
      setClaim({
        ...claim,
        received_credit: claim.received_credit === "Y" ? "N" : "Y",
        claim_amount_received:
          claim.received_credit === "Y" ? 0 : claim.claim_amount_received,
      })
    );
  };

  const updateActualClaimAmount = (value) => {
    dispatch(
      setClaim({
        ...claim,
        claim_amount_received: value,
      })
    );
  };

  const convertCredit = () => {
    // setShowConfirmConvertPopup(false);
    dispatch(convertToCredit()).then((response) => {
      if (!response?.error) {
        setShowConfirmConvertPopup(false);
        alertNotification.handleOpen(
          "success-savign-as-sub-order",
          "success",
          "Claim Converted Successfully!",
          2000
        );
        const url = `${window.location.origin + endpoints.CREDIT_ENTRY}/credit/${response.payload}`;
        window.open(url, "_blank").focus();
        window.location.reload();
      }
    });
  };

  const resetFunction = () => {
    dispatch(restartClaim());
    setClaimStarted(false);
    window.location.href = `${endpoints.CREDIT_ENTRY}/warranty-claim`;
  };

  const saveFunction = () => {
    setShowSavePopup(true);
  };

  const saveClaimDis = () => {
    dispatch(saveClaim()).then((response) => {
      if (!response?.error) {
        setShowSavePopup(false);
        alertNotification.handleOpen(
          "success-savign-as-sub-order",
          "success",
          "Claim Created Successfully!",
          2000
        );
        setTimeout(() => {
          window.location.href = `${endpoints.CREDIT_ENTRY}/warranty-claim/${response.payload.claim.claim_num}`;
        }, 1000);
      }
    });
  };

  const updateClaimDis = () => {
    dispatch(updateClaim()).then((response) => {
      dispatch(saveClaimImages()).then((res) => {
        if (!response?.error) {
          setShowSavePopup(false);

          alertNotification.handleOpen(
            "success-savign-as-sub-order",
            "success",
            "Claim updated Successfully!",
            2000
          );

          setTimeout(() => {
            window.location.reload();
          }, 1000);
        }
      });
    });
  };

  const deleteDispatch = () => {
    dispatch(deleteClaim()).then((response) => {
      if (!response?.error) {
        setShowDeletePopup(false);
        alertNotification.handleOpen(
          "success-savign-as-sub-order",
          "success",
          "Claim deleted Successfully.",
          2000
        );
        setTimeout(() => {
          window.location.href = `${endpoints.CREDIT_ENTRY}/warranty-claim`;
        }, 1000);
      }
    });
  };

  const undoDeleteDispatch = () => {
    dispatch(undoDeleteClaim()).then((response) => {
      if (!response?.error) {
        setShowDeletePopup(false);
        alertNotification.handleOpen(
          "success-savign-as-sub-order",
          "success",
          "Claim Revived Successfully.",
          2000
        );
        setTimeout(() => {
          window.location.reload();
        }, 1000);
      }
    });
  };

  return (
    <>
      <div className="invis-bg mt-5">
        <Row>
          <Col>
            <h4>
              {ClaimType.filter((type) => type.id === claim_type)[0].label}
            </h4>
            {claim.claim_num > 0 && (
              <>
                <h6>
                  <b>Claim Number: </b> {claim.claim_num}{" "}
                  {claim && claim.deleted === "Y" ? "(DELETED)" : ""}{" "}
                  {claim && claim.claim_status === CLAIM_CLOSED.id
                    ? "(CLOSED)"
                    : ""}
                </h6>
                <h6>
                  <b>Claim Amount: </b> {claim.claim_amount}
                </h6>
                <h6>
                  <b>Claim Status: </b>{" "}
                  {
                    ClaimStatus.filter(
                      (status) => status.id === claim.claim_status
                    )[0].label
                  }
                </h6>
                <h6>
                  <b>Credit: </b>(
                  {claim.credit_memo_num > 0 ? (
                    <Link
                      to={
                        endpoints.CREDIT_ENTRY +
                        `/credit/${claim.credit_memo_num}`
                      }
                    >
                      {claim.credit_memo_num}
                    </Link>
                  ) : (
                    "Not Credited"
                  )}
                  )
                </h6>
                <Row>
                  <Col>
                    <h6>
                      <FormGroup className="mt-3">
                        <FormControlLabel
                          id="credit_received"
                          label="Credit from Vendor/Freight Line received"
                          sx={{
                            fontWeight: "bold",
                          }}
                          disabled={disabled}
                          control={
                            <Checkbox
                              checked={claim.received_credit === "Y"}
                              onClick={updateReceivedCredit}
                            />
                          }
                        />
                      </FormGroup>
                    </h6>
                  </Col>
                  {claim && claim.received_credit === "Y" && (
                    <Col>
                      <TextField
                        label="Claim Amount Received"
                        value={claim.claim_amount_received}
                        type="number"
                        onChange={(e) =>
                          updateActualClaimAmount(e.target.value.slice(0, 8))
                        }
                      />
                    </Col>
                  )}
                </Row>
              </>
            )}
          </Col>
          <Col className="space-around mobile-vert">
            <div>
              <Dropdown>
                <Dropdown.Toggle className="drop-status-button">
                  Tools
                </Dropdown.Toggle>
                <Dropdown.Menu>
                  <Dropdown.Item onClick={() => setShowRestartPopup(true)}>
                    Restart
                  </Dropdown.Item>
                  {claim.claim_num !== 0 && (
                    <Dropdown.Item
                      disabled={
                        claim.credit_memo_num !== 0 ||
                        [PENDING_CLAIM.id, DENIED.id].includes(claim.status)
                      }
                      onClick={() => setShowConfirmConvertPopup(true)}
                    >
                      Convert to Credit
                    </Dropdown.Item>
                  )}
                  {claim.claim_num !== 0 && (
                    <Dropdown.Item onClick={() => setShowSendClaim(true)}>
                      Send/Print Claim
                    </Dropdown.Item>
                  )}
                  {claim.claim_num !== 0 && claim.deleted === "Y" && (
                    <Dropdown.Item
                      className="del-tool"
                      onClick={() => setShowUndoDelete(true)}
                    >
                      Undo Delete
                    </Dropdown.Item>
                  )}

                  {claim.claim_num !== 0 && claim.deleted === "N" && (
                    <Dropdown.Item
                      className="del-tool"
                      disabled={disabled}
                      onClick={() => setShowDeletePopup(true)}
                    >
                      Delete
                    </Dropdown.Item>
                  )}
                </Dropdown.Menu>
              </Dropdown>
            </div>
            <div>
              {/* 
                This button is disabled when the disabled bool (defined in CreditEntry) is true 
                or if the user has not rated the credit
                or if the user has no items
            */}
              <Button
                disabled={disabled}
                onClick={() => saveFunction()}
                className="f-right"
              >
                {claim.claim_num === 0 ? "Save" : "Update"} <AiOutlineSave />
              </Button>
            </div>
          </Col>
        </Row>
      </div>
      {/* Popup that warns the user they are about to lose all progress and restart */}
      <ConfirmationPopup
        show={showConfirmConvertPopup}
        title="Convert Claim"
        handleCancelEvent={() => setShowConfirmConvertPopup(false)}
        handleConfirmEvent={convertCredit}
        body={
          <>
            <b>
              This will convert this claim to a credit, you can only do this
              once.
            </b>
            <br />
            <br /> Are you sure you want to continue?
          </>
        }
      />
      <SendClaimSummary
        show={showSendClaim}
        handleCancelEvent={() => setShowSendClaim(false)}
        handleConfirmEvent={sendConfirmationDispatch}
      />

      <UndoDeletePopup
        handleConfirmEvent={undoDeleteDispatch}
        handleCancelEvent={() => setShowUndoDelete(false)}
        show={showUndoDelete}
      />

      <WarningRestartPopup
        handleConfirmEvent={resetFunction}
        handleCancelEvent={() => setShowRestartPopup(false)}
        show={showRestartPopup}
      />
      <ClaimDeletePopup
        show={showDeletePopup}
        handleCancelEvent={() => setShowDeletePopup(false)}
        handleConfirmEvent={deleteDispatch}
      />
      {initial_order && (
        <ConfirmSaveClaimPopup
          show={showSavePopup}
          content={initial_order}
          status={claim.claim_status}
          handleCancelEvent={() => setShowSavePopup(false)}
          handleConfirmEvent={
            claim.claim_num === 0 ? saveClaimDis : updateClaimDis
          }
        />
      )}
    </>
  );
};

export default ClaimHeader;
