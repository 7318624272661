import {
  BrowserRouter as Router,
  Routes,
  Route,
  Navigate,
} from "react-router-dom";
import Container from "react-bootstrap/Container";
import { endpoints } from "../api/endpoints";
import SubOrder from "../views/SubOrder/SubOrder";
import Orders from "../views/Orders/Orders";
import Customers from "../views/Customers/Customers";
import Customer from "../views/Customer/Customer";
import Bol from "../views/Bol/Bol";
import MyAccount from "../views/MyAccount/MyAccount";
import NotFound from "../views/NotFound";
import Quotes from "../views/Quotes/Quotes";
import AutoSaveWrapper from "../wrapper/AutoSaveWrapper";
import { Alert } from "@mui/material";

function CustomerOrders(props) {
  const groups = props.groups;
  return (
    <>
      {groups.includes(props.orderGroup) ? (
        <Container className="">
          <Routes>
            <Route path={""} element={<SubOrder />} />

            <Route
              path={"/customer/:customerId/new-order/"}
              element={<SubOrder />}
            />
            <Route path={"/quote/:id"} element={<SubOrder savedAsQuote />} />
            <Route path={"/order/new"} element={<SubOrder />} />
            <Route
              path={"/order/:id"}
              element={
                <AutoSaveWrapper>
                  <SubOrder savedAsOrder />
                </AutoSaveWrapper>
              }
            />
            <Route path={"/order-search/"} element={<Orders />} />
            <Route path={"/customers"} element={<Customers />} />
            <Route path={"/customers/:id"} element={<Customer />} />
            <Route path={"/bol"} element={<Bol />} />

            <Route path={"/quotes-search/"} element={<Quotes />} />
          </Routes>
        </Container>
      ) : (
        <Container>
          {props.groups.length > 0 && (
            <span>
              <Alert severity="error" variant="outlined" className="mt-5">
                You are not allowed to view this page. If you believe you are
                seeing this in error, please contact a system administrator
              </Alert>
              <a href="/">Back to home</a>
            </span>
          )}
        </Container>
      )}
      <div style={{ float: "right", marginRight: "1%" }}>
        <b>v1.5.2</b>
      </div>
    </>
  );
}

export default CustomerOrders;
